import * as yup from 'yup';
import {
  TextInputNumeric,
  TextInput,
} from '../../../../transaction/components/heylight';
import { FieldNames, InstoreFormType } from './enums';
import { InstoreProductCategoryForm } from './InstoreProductCategoryForm';
import { InstoreProductForm } from './InstoreProductForm';

interface IGetFormSettingsOptions {
  isIdentityFieldEnabled: boolean;
  isNameFieldEnabled: boolean;
  isQuantityFieldEnabled: boolean;
}

const getCategoryForm = ({
  isIdentityFieldEnabled,
  isNameFieldEnabled,
  isQuantityFieldEnabled,
}: IGetFormSettingsOptions) => ({
  instoreProductForm: InstoreProductCategoryForm,
  defaultValues: {
    products: [
      {
        [FieldNames.AdditionalDetails]: '',
        [FieldNames.Price]: { amount: '', currency: '' },
        [FieldNames.ProductSku]: '',
        [FieldNames.ResidualValue]: '',
        ...(isNameFieldEnabled
          ? {
              [FieldNames.ProductName]: '',
            }
          : {}),
        ...(isIdentityFieldEnabled
          ? {
              [FieldNames.ProductIdentity]: '',
            }
          : {}),
        ...(isQuantityFieldEnabled
          ? {
              [FieldNames.Quantity]: '',
            }
          : {}),
      },
    ],
  },
  validationSchema: {
    [FieldNames.Price]: TextInputNumeric.validation(FieldNames.Price, {
      isMonetaryObject: true,
    }),
    [FieldNames.ProductSku]: yup
      .string()
      .required('fields.categorySelect.required'),
    ...(isNameFieldEnabled
      ? {
          [FieldNames.ProductName]: yup.string().nullable(),
        }
      : {}),
    ...(isIdentityFieldEnabled
      ? {
          [FieldNames.ProductIdentity]: yup.string().nullable(),
        }
      : {}),
    ...(isQuantityFieldEnabled
      ? {
          [FieldNames.Quantity]: TextInputNumeric.validation(
            FieldNames.Quantity,
            {},
          ),
        }
      : {}),
  },
});

const getDefaultForm = ({
  isIdentityFieldEnabled,
  isNameFieldEnabled,
  isQuantityFieldEnabled,
}: IGetFormSettingsOptions) => ({
  instoreProductForm: InstoreProductForm,
  defaultValues: {
    products: [
      {
        [FieldNames.Discount]: '',
        [FieldNames.Price]: '',
        [FieldNames.ProductSku]: '',
        [FieldNames.AdditionalDetails]: '',
        ...(isNameFieldEnabled
          ? {
              [FieldNames.ProductName]: '',
            }
          : {}),
        ...(isIdentityFieldEnabled
          ? {
              [FieldNames.ProductIdentity]: '',
            }
          : {}),
        ...(isQuantityFieldEnabled
          ? {
              [FieldNames.Quantity]: '',
            }
          : {}),
      },
    ],
  },
  validationSchema: {
    [FieldNames.Discount]: TextInputNumeric.validation(FieldNames.Discount, {
      optional: true,
    }),
    [FieldNames.Price]: TextInputNumeric.validation(FieldNames.Price, {}),
    ...(isIdentityFieldEnabled
      ? {
          [FieldNames.ProductName]: yup.string().nullable(),
        }
      : {}),
    [FieldNames.ProductSku]: TextInput.validation(FieldNames.ProductSku, {}),
    ...(isIdentityFieldEnabled
      ? {
          [FieldNames.ProductIdentity]: yup.string().nullable(),
        }
      : {}),
    ...(isQuantityFieldEnabled
      ? {
          [FieldNames.Quantity]: TextInputNumeric.validation(
            FieldNames.Quantity,
            {},
          ),
        }
      : {}),
  },
});

export const getFormSettings = (
  formType: InstoreFormType,
  formOptions: IGetFormSettingsOptions = {
    isIdentityFieldEnabled: false,
    isNameFieldEnabled: true,
    isQuantityFieldEnabled: false,
  },
) => {
  if (formType === InstoreFormType.Category) {
    return getCategoryForm(formOptions);
  }

  return getDefaultForm(formOptions);
};
