export const allowdKeys = [
  'code',
  'error',
  'errorCode',
  'errorDescription',
  'state',
];

export const sanitiseActionParameters = parameters =>
  allowdKeys.reduce((previous, current) => {
    const value = parameters[current];
    if (value) {
      return { ...previous, [current]: value };
    }
    return previous;
  }, {});
