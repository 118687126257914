import {
  connectRouter,
  routerMiddleware,
  RouterState,
} from 'connected-react-router';
import { createBrowserHistory } from 'history';
import loggerMiddleware from 'redux-logger';
import {
  AnyAction,
  configureStore,
  Middleware,
  Reducer,
} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import instoreReducer, { IInstoreState } from '@instore/redux';
import addressReducer from './address/redux';
import paymentReducer from './payment/redux';
import { IPaymentState } from './payment/redux/types';
import { appReducer } from './redux';
import { IAppState } from './redux/types';
import { transactionReducer } from './transaction/redux';
import { ITransactionState } from './transaction/redux/initialState';
import verificationReducer from './verification/redux';

export interface IRootState {
  address: unknown;
  app: IAppState;
  instore: IInstoreState;
  payment: IPaymentState;
  router: RouterState;
  transaction: ITransactionState;
  verification: unknown;
}

export const history = createBrowserHistory();

const enableConsoleLogger = false;

const middleware = (getDefaultMiddleware: () => Middleware[]) => [
  ...getDefaultMiddleware(),
  routerMiddleware(history),
  ...(process.env.NODE_ENV === 'development' && enableConsoleLogger
    ? [loggerMiddleware]
    : []),
];

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const createStore = () =>
  configureStore({
    reducer: {
      router: connectRouter(history),
      app: appReducer as Reducer<unknown, AnyAction>,
      transaction: transactionReducer as Reducer<unknown, AnyAction>,
      verification: verificationReducer as Reducer<unknown, AnyAction>,
      address: addressReducer as Reducer<unknown, AnyAction>,
      payment: paymentReducer,
      instore: instoreReducer as Reducer<unknown, AnyAction>,
    },
    middleware,
    enhancers: [sentryReduxEnhancer],
  });

const store = createStore();

export function getDispatch() {
  return store?.dispatch;
}

export type AppDispatch = typeof store.dispatch;

export default store;
