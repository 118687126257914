import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import { useTranslation } from '@hooks';
import { ProgressBar } from '../ProgressBar';
import parseTranslationToArray from './parseTranslationToArray';
import TypingTextContainer from './TypingTextContainer';
import useWaitingBot from './useWaitingBot';

const BotContainer = styled('div')`
  position: relative;
  display: block;
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
  border: 1px solid #b1c0ff;
  border-radius: 60px;
  background-color: #f2f9ff;

  > :nth-of-type(1) {
    left: 75px;
  }
`;

const Eye = styled('div')`
    position: absolute;
    bottom: 60px;
    left: 22px;
    height: 25px;
    width: 20px;
    background-color: #0179a2;
    border-radius: 10px;
    animation: blink 1.5s infinite;
    -webkit-animation: eye-blink 4.5s infinite;
    font-weight: 700;
  }

  @keyframes eye-blink {
    10% {
      height: 5px;
      transition: height 0.15s ease-out;
    }
    20% {
      height: 25px;
    }
  }
  @-webkit-keyframes eye-blink {
    10% {
      height: 5px;
      transition: height 0.15s ease-out;
    }
    20% {
      height: 25px;
    }
  }
`;

const MerchantLogo = styled('img')`
  max-width: 100%;
  max-height: 70px;
  margin-bottom: 20px;
  object-position: center center;
  @media (max-width: 600px) {
    maxheight: '45px';
  }
`;

const WaitingBot = ({ displayPagolightLogo }) => {
  const { t } = useTranslation();
  const introPhrases = parseTranslationToArray(t('waitingBot.intro'));
  const repeatingPhrases = parseTranslationToArray(t('waitingBot.loop'));

  const { displayPhrase, progressBarCountdown } = useWaitingBot(
    introPhrases,
    repeatingPhrases,
  );

  return (
    <>
      {displayPagolightLogo ? (
        <MerchantLogo alt="pagolight logo" src="/images/pagolight.svg" />
      ) : (
        <BotContainer>
          <Eye />
          <Eye />
        </BotContainer>
      )}
      <TypingTextContainer phrase={displayPhrase} />
      <ProgressBar
        ariaLabel="onfido progress bar"
        countdown={progressBarCountdown}
      />
    </>
  );
};

WaitingBot.propTypes = {
  displayPagolightLogo: PropTypes.bool,
};

WaitingBot.defaultProps = {
  displayPagolightLogo: false,
};

export default WaitingBot;
