import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setLoadingState } from '../../redux/actions';

export default function Spinner({ submit }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoadingState(true));
  });

  useEffect(() => {
    const t = setTimeout(() => {
      submit();
    }, 5000);
    return () => clearTimeout(t);
  }, [submit]);

  return <></>;
}

Spinner.propTypes = {
  submit: PropTypes.func.isRequired,
};
