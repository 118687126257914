import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { func, shape, string } from 'prop-types';
import { Box, styled } from '@mui/material';
import * as Sentry from '@sentry/react';
import {
  FeatureFlag,
  useRegisterScriptFromUri,
  useFeatureFlags,
  useStatusPolling,
} from '@hooks';
import { getIsLoading } from '@redux';
import { checkIntrumIdentityStatus } from '../../../services/IntrumStatusService';
import { SentrySeverity } from '../../../utils';
import { ExternalSignatureNotice } from './components/ExternalSignatureNotice';

const intrumSdkUri = 'https://embedding-ubiid.ubitec.io/ubiid.min.js';
const MAX_HEIGHT = 800;

export const IntrumStatus = Object.freeze({
  indentification_failure: 'IDENTIFICATION_FAILED',
  indentification_success: 'IDENTIFICATION_SUCCESSFUL',
  indentification_timeout: 'TIMEOUT',
  signing_success: 'SIGNING_SUCCESSFUL',
});

const failureStatuses = [
  IntrumStatus.indentification_failure,
  IntrumStatus.indentification_timeout,
];

const successStatuses = [
  IntrumStatus.indentification_success,
  IntrumStatus.signing_success,
];

const IframeContainer = styled(Box)`
  & > iframe {
    min-height: 650px;
    height: '100%';
  }
`;

export const IntrumIframeContainer = ({ options, submit, submitAttempted }) => {
  const isSubmitting = useSelector(getIsLoading);

  const { caseUri, isIdentity = false } = options;

  const [isCompleted, setIsCompleted] = useState(false);

  const { isLoaded } = useRegisterScriptFromUri(intrumSdkUri);

  const { flagEnabled } = useFeatureFlags();

  const iframeContainerElementRef = useRef(null);

  const containerElementRef = useRef(null);

  const shouldDisplayExternalSigningNotice =
    !isIdentity && flagEnabled(FeatureFlag.IsExternalSigningEnabled);

  const logOnLoad = useCallback(
    () =>
      Sentry.captureMessage(
        'Successfully loaded intrum ifame.',
        SentrySeverity.info,
      ),
    [],
  );

  const logOnError = useCallback(
    () =>
      Sentry.captureMessage(
        'Failed to load intum iframe.',
        SentrySeverity.error,
      ),
    [],
  );

  const registerIframeEvents = useCallback(() => {
    const iframeElement =
      iframeContainerElementRef.current?.getElementsByTagName('iframe')?.[0];
    iframeElement?.addEventListener('load', logOnLoad);
    iframeElement?.addEventListener('error', logOnError);
  }, [iframeContainerElementRef, logOnError, logOnLoad]);

  const unregisterIframeEvents = useCallback(() => {
    const iframeElement =
      iframeContainerElementRef.current?.getElementsByTagName('iframe')?.[0];
    iframeElement?.removeEventListener('load', logOnLoad);
    iframeElement?.removeEventListener('error', logOnError);
  }, [iframeContainerElementRef, logOnError, logOnLoad]);

  const iframeHeight = useMemo(() => {
    const viewportHeight = window.innerHeight;
    return Math.min(viewportHeight, MAX_HEIGHT);
  }, []);

  const { cancelPolling, isComplete: isStatusPollingCompleted } =
    useStatusPolling({
      isSubmitting,
      onComplete: submit,
      pollingFunction: checkIntrumIdentityStatus,
      retryIntervalMs: 5000,
      initialDelayMs: 120000,
      enablePolling: isIdentity,
      submitAttempted,
    });

  const handleIntrumEvent = useCallback(
    ({ date, result }) => {
      if (successStatuses.includes(result)) {
        setIsCompleted(true);

        cancelPolling();

        if (!isStatusPollingCompleted) {
          submit();
        }
      } else if (failureStatuses.includes(result)) {
        setIsCompleted(true);

        Sentry.captureMessage(
          `Intrum flow failed with result ${result} and date ${date}`,
          SentrySeverity.warning,
        );
      } else {
        Sentry.captureMessage(
          `Intrum flow received a non success or failure result ${result} and date ${date}`,
          SentrySeverity.info,
        );
      }
    },
    [cancelPolling, isStatusPollingCompleted, submit],
  );

  const handleIframeMessage = useCallback(
    ubiIdEvent => {
      const { date: scanData, scanResult } = ubiIdEvent;
      const { date: signingDate, signingResult } = ubiIdEvent;

      if (scanResult) {
        handleIntrumEvent({
          result: scanResult,
          date: scanData,
        });
      } else if (signingResult) {
        handleIntrumEvent({
          result: signingResult,
          date: signingDate,
        });
      } else {
        Sentry.captureMessage(
          // eslint-disable-next-line max-len
          `Intrum iframe event didn't match the expected format: ${ubiIdEvent}. Expected "scanResult" or "signingResult" props.`,
          SentrySeverity.warning,
        );
      }
    },
    [handleIntrumEvent],
  );

  useEffect(() => {
    if (isLoaded) {
      const timeout = setTimeout(() => {
        containerElementRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
      }, 500);

      return () => clearTimeout(timeout);
    }

    return null;
  }, [isLoaded]);

  useEffect(() => {
    if (!isCompleted && isLoaded && window.ubiID) {
      window.ubiID.start({
        enclosingDomElement: iframeContainerElementRef.current,
        onMessage: handleIframeMessage,
        style: {
          height: `${iframeHeight}px`,
          width: '100%',
        },
        ubiIdEndpoint: caseUri,
      });

      const timeout = setTimeout(() => {
        registerIframeEvents();
      }, 500);

      return () => {
        clearTimeout(timeout);
        unregisterIframeEvents();
      };
    }
    return null;
  }, [
    caseUri,
    isCompleted,
    isLoaded,
    handleIframeMessage,
    iframeHeight,
    registerIframeEvents,
    unregisterIframeEvents,
  ]);

  return (
    <Box ref={containerElementRef} width="100%">
      {shouldDisplayExternalSigningNotice ? (
        <ExternalSignatureNotice caseUri={caseUri} ref={containerElementRef} />
      ) : null}
      <IframeContainer
        id="ubiid-enclosing-container"
        ref={iframeContainerElementRef}
        width="100%"
      />
    </Box>
  );
};

IntrumIframeContainer.propTypes = {
  options: shape({
    caseUri: string,
  }).isRequired,
  submit: func.isRequired,
};
