import React, { ElementType, useCallback, useState } from 'react';
import { Country } from 'react-phone-number-input';
import { InputFieldAdornment } from '@heidi-pay/heidi-component-library/components/InputFieldAdornment';
import {
  InputBaseComponentProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { useTranslation } from '@hooks';

import { PhoneInputAdapter } from './PhoneInputAdapter';

interface IPhoneNumberInputProps {
  complete?: boolean;
  country?: Country | '';
  error: string;
  inputRef?: React.Ref<HTMLInputElement>;
  name: string;
  onBlur: () => void;
  onChange: (event: string) => void;
  value?: string;
  withLabel?: boolean;
}

export const PhoneNumberInput = ({
  complete,
  country,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  value = '',
}: IPhoneNumberInputProps) => {
  const { t } = useTranslation();
  const label = t(`fields.mobileNumberInput.${name}.placeholder`);
  const [isSelected, setIsSelected] = useState(false);

  const handleClick = useCallback(() => setIsSelected(true), [setIsSelected]);

  const handleBlur = useCallback(() => {
    setIsSelected(false);
    onBlur();
  }, [onBlur]);

  return (
    <TextField
      error={!!error}
      id={name}
      InputLabelProps={{ shrink: !!value || isSelected }}
      InputProps={{
        inputComponent:
          PhoneInputAdapter as unknown as ElementType<InputBaseComponentProps>,
        inputProps: { country, label },
        endAdornment: (
          <InputFieldAdornment isComplete={complete} isError={!!error} />
        ),
      }}
      inputRef={inputRef}
      label={label}
      name={name}
      onBlur={handleBlur}
      onChange={onChange as unknown as TextFieldProps['onChange']}
      onClick={handleClick}
      size="small"
      sx={{ flexGrow: 1 }}
      type="tel"
      value={value}
      variant="outlined"
    />
  );
};
