import { useState } from 'react';
import { Link } from '@heidi-pay/heidi-component-library/components';
import { useTranslation, useExecuteAsync } from '@hooks';
import { SoftErrorKeys } from '@utils';
import { getPagolightProSecciUri } from '../../../../services/PagolightProSecciUriService';

interface IDocumentLinkMap {
  [key: number]: string;
}

interface ISecciLinkOptions {
  numberOfInstallments?: number;
}

interface ISecciLink {
  numberOfInstallments?: number;
  options?: ISecciLinkOptions;
}

export const SecciLink = ({ numberOfInstallments, options }: ISecciLink) => {
  const { t } = useTranslation();

  const numberOfInstallmentsUnified =
    options?.numberOfInstallments ?? numberOfInstallments;

  const [documentsMap, setDocumentsMap] = useState<IDocumentLinkMap>({});

  const openDocumentInNewTab = async () => {
    if (!numberOfInstallmentsUnified) {
      throw Error(
        'Number of installments not provided to SecciLink component.',
      );
    }

    const documentUri = documentsMap[numberOfInstallmentsUnified];

    if (documentUri) {
      window.open(documentUri, '_blank');
    } else {
      const { secciPdfUrl } = await getPagolightProSecciUri(
        numberOfInstallmentsUnified,
      );
      setDocumentsMap(previous => ({
        ...previous,
        [numberOfInstallmentsUnified]: secciPdfUrl,
      }));
      window.open(secciPdfUrl, '_blank');
    }
  };

  const handleOnClick = useExecuteAsync(openDocumentInNewTab, {
    softErrorCode: SoftErrorKeys.retry,
  });

  return (
    <Link
      display={'flow'}
      onClick={handleOnClick}
      variant="body1"
      withIcon={true}
    >
      {t('fields.additionalDocumentsContainer.documents.secci_italian_credit')}
    </Link>
  );
};
