import {
  heidipayOriginators,
  pagolightOriginators,
  pagolightProOriginators,
} from '@utils/constants';

interface IGetI18nKeyProps {
  isIntroScreen?: boolean;
  originatorUuid: string;
}

export const getI18nKey = ({
  isIntroScreen = false,
  originatorUuid,
}: IGetI18nKeyProps): string => {
  const baseKey = 'fields.merchantRedirectSection';
  const colorFormatting = isIntroScreen ? 'white.' : '';

  if (pagolightOriginators.includes(originatorUuid)) {
    return `${baseKey}.${colorFormatting}pagolight`;
  }
  if (pagolightProOriginators.includes(originatorUuid)) {
    return `${baseKey}.${colorFormatting}pagolightPro`;
  }
  if (heidipayOriginators.includes(originatorUuid)) {
    return `${baseKey}.${colorFormatting}default`;
  }

  return `${baseKey}.default`;
};
