export const minimumAllowedDate = new Date(1920, 0, 1);

export const maximumAllowedDate = new Date(2099, 11, 31);

export const yesterday = () => {
  const d = new Date();
  d.setDate(d.getDate() - 1);
  return new Date(d.toDateString());
};

export const getTomorrowDate = () => {
  const d = new Date();
  d.setDate(d.getDate() + 1);
  return new Date(d.toDateString());
};
