import { IStringMap } from '@appTypes';

const CityNameDictionary: IStringMap = {
  biel: 'Biel/Bienne',
};

// Google will return the name Biel for the city of Biel/Bienne
// Our backend validation expects Biel/Bienne as the city name
export const getParsedCityName = (cityName = '') =>
  CityNameDictionary[cityName?.trim().toLowerCase()] ?? cityName;
