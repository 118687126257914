const regex = /data:image\/([a-zA-Z0-9]+);base64/;

export const getFileExtension = (input: string) => {
  const match = input.match(regex);
  if (!match) {
    return undefined;
  }

  const lowercaseExtension = match[1].toLowerCase();

  return lowercaseExtension === 'jpeg' ? 'jpg' : lowercaseExtension;
};
