import { Box, CircularProgress } from '@mui/material';

export const LoadingContainer = () => (
  <Box
    display="flex"
    flexDirection="column"
    height="200px"
    justifyContent="center"
    textAlign="center"
  >
    <CircularProgress size="100px" sx={{ height: '100px', width: '100px' }} />
  </Box>
);
