import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, IconButton, styled } from '@mui/material';
import { useTranslation } from '@hooks';
import { resetErrorState } from '@redux';

const BackDropContainer = styled('div')({
  zIndex: 100,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  WebkitTapHighlightColor: 'transparent',
});

export default function ErrorBackdrop() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const close = () => {
    dispatch(resetErrorState());
  };

  return (
    <BackDropContainer aria-hidden={true}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={close}
            size="small"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        severity="error"
        variant="filled"
      >
        <AlertTitle>{t('error.title')}</AlertTitle>
        {t('error.description')}
      </Alert>
    </BackDropContainer>
  );
}
