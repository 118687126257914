export const Brands = Object.freeze({
  heidipay: 'heidipay',
  pagolight: 'mediobanca',
  pagolightPro: 'pagolight_pro',
});

export const CheckoutType = Object.freeze({
  consumer: 'CONSUMER',
  instore: 'IN_STORE',
  instoreLegacy: 'IN_STORE_LEGACY',
  paymentLink: 'PAYMENT_LINK',
  cardChange: 'CARD_CHANGE',
});

export const ComponentTypes = Object.freeze({
  ButtonGroupPicker: 'ButtonGroupPicker',
  TextBlock: 'TextBlock',
  NumberPicker: 'NumberPicker',
  SelectPicker: 'SelectPicker',
  TextInput: 'TextInput',
  TextInputNumeric: 'TextInputNumeric',
});

export const FormatterType = Object.freeze({
  AMOUNT: 'amount',
  AMOUNT_WITH_CURRENCY: 'amountWithCurrency',
  PERCENTAGE: 'percentage',
});

export const OriginationChannels = Object.freeze({
  ecommerce: 'ecommerce',
  instore: 'in_store',
});

export const SoftErrorKeys = Object.freeze({
  addressInvalid: '20130',
  googleAddressInvalid: '20131',
  addressCannotConfirmRoad: '20132',
  addressCannotConfirmZipCode: '20133',
  addressCannotConfirmCity: '20134',
  addressHouseNumberRequired: '20120',
  addressNoMultipleHouseNumbers: '20140',
  default: 'default',
  invalidIBAN: '20160',
  instoreConsumerChangedCoreDetails: '20200',
  invalidOTC: '40404',
  invalidOTP: '20150',
  invalidPhone: '20170',
  invalidDOB: '20180',
  mobileNumberInvalid: '21614',
  mobileNumberNotReachable: '21612',
  mobileNumberRegionDisabled: '21408',
  mediamarktScNumberAlreadyUsed: '21620',
  mediamarktScNumberInvalid: '21670',
  mediamarktScNumberIncorrectDetails: '21680',
  mediamarktScNumberTooManySuccessfulApplications: '21690',
  retry: 'retry',
});

export const IconMapping = Object.freeze({
  card: {
    src: '/images/card.svg',
    altText: 'card',
  },
  swiss_qr: {
    src: '/images/qr-code.svg',
    altText: 'swiss_qr',
  },
});
