import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HeyLightColour } from '@heidi-pay/heidi-component-library/enums';
import { useTheme } from '@mui/material';
import { useRegisterScriptFromUri, useTranslation } from '@hooks';
import { getExternalContractUuid, getMerchantDetails } from '@redux';
import { addStyles } from '@utils';
import { getTranslationForCurrentAction } from '../../../transaction/redux';
import { useGetSalesforceConfig } from './hooks';
import { initSalesforceWidget } from './utils';

// we need to hide the label for mobile view as this is covering the CTA
// and also to force show the icon still otherwise using !important
// as this is hidden with an inline style by Salesforce normally on click of the button
const brandingCss = `
body {
  --heyLightAccentDark: ${HeyLightColour.AccentDark};
  --heyLightAccent: ${HeyLightColour.Accent};

  /* Darker Colors */
  --lwc-colorBorderBrandSecondary: var(--heyLightAccentDark);
  --lwc-colorBrandSecondaryDarken20: var(--heyLightAccentDark);
  --lwc-colorBrandSecondaryDarken40: var(--heyLightAccentDark);
  --lwc-colorBrandSecondaryDarken60: var(--heyLightAccentDark);

  /* Normal Colors */
  --lwc-colorBrandSecondary: var(--heyLightAccent);
  --lwc-colorBrandSecondaryLighten20: var(--heyLightAccent);
  --lwc-colorBrandSecondaryLuminance0: var(--heyLightAccent);
  --lwc-colorBrandSecondaryLuminance1: var(--heyLightAccent);
  --lwc-colorBrandSecondaryLuminance2: var(--heyLightAccent);
  --lwc-colorBrandSecondaryLuminance3: var(--heyLightAccent);
  --lwc-colorBrandSecondaryLuminance4: var(--heyLightAccent);
  --lwc-colorBrandSecondaryLuminance5: var(--heyLightAccent);
  --lwc-colorBrandSecondaryLuminance6: var(--heyLightAccent);
  --lwc-colorBrandSecondaryLighten60: var(--heyLightAccent);
  --lwc-colorBrandSecondaryLighten40: var(--heyLightAccent);
}

embeddedservice-chat-header.chatHeaderBranding,
body .embeddedServiceHelpButton .helpButton .uiButton {
  background-color: var(--heyLightAccent);
}

body .embeddedServiceSidebarMinimizedDefaultUI.minimizedContainer:focus {
    background-color: var(--heyLightAccent);
    border: none;
}

body .embeddedServiceHelpButton .uiButton .helpButtonLabel .message {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
}

@media (max-width: 600px) {
  body .embeddedServiceHelpButton .helpButton .uiButton {
    min-width: 46px;
    justify-content: center;
  }
  body .embeddedServiceHelpButton .uiButton .helpButtonLabel {
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
  }
  body .embeddedServiceHelpButton .embeddedServiceIcon {
    margin-right: 0;
    display: block!important;
  }
}`;

export interface ISalesforceLiveChat {
  shouldHideWidget: boolean;
}

export const SalesforceLiveChat = memo(
  ({ shouldHideWidget }: ISalesforceLiveChat) => {
    const [hasInitialised, setHasInitialised] = useState(false);
    const { t } = useTranslation();
    const { images } = useTheme();
    const { avatar } = images ?? {};
    const config = useGetSalesforceConfig();
    const externalContractUuid = useSelector(getExternalContractUuid);
    const merchantDetails = useSelector(getMerchantDetails) ?? {};
    const { isLoaded } = useRegisterScriptFromUri(config?.chatWidgetLibraryUrl);
    const translatedAction = useSelector(getTranslationForCurrentAction);

    useEffect(() => {
      addStyles(brandingCss);
    }, []);

    useEffect(() => {
      if (
        isLoaded &&
        externalContractUuid &&
        merchantDetails.name &&
        merchantDetails.uuid &&
        config &&
        translatedAction &&
        !shouldHideWidget &&
        !hasInitialised
      ) {
        initSalesforceWidget({
          avatarUrl: avatar?.src,
          config,
          externalContractUuid,
          merchantId: merchantDetails.uuid,
          merchantName: merchantDetails.name,
          setHasInitialised,
          translate: t,
          translatedAction,
        });
      }
    }, [
      avatar?.src,
      config,
      externalContractUuid,
      hasInitialised,
      isLoaded,
      merchantDetails.name,
      merchantDetails.uuid,
      shouldHideWidget,
      t,
      translatedAction,
    ]);

    return null;
  },
);

SalesforceLiveChat.displayName = 'SalesforceLiveChat';
