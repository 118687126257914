/* eslint-disable max-len */
import { IIconProps } from '../type';

export const PagolightShoppingBagIcon = ({ options }: IIconProps) => {
  const { height, width } = options ?? {};
  return (
    <svg
      aria-label="shopping bag"
      height={height ?? '48'}
      role="img"
      style={{ background: '#DADADA' }}
      viewBox="0 0 48 48"
      width={width ?? '48'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Shopping Bag</title>

      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path d="M0 0H48V48H0z" fill="#FFF"></path>
        <path
          d="M29.5 0c5.523 0 10 4.477 10 9.999l1.321.001c.049 0 .097.004.144.01V10h5.031a1 1 0 011 .973l.976 36V47a1 1 0 01-1 1H14.028a1 1 0 01-1-1v-.027l.107-3.957-9.355-3.405a3 3 0 01-1.794-3.844l.994-2.732-1.291-.746a3 3 0 01-1.099-4.096l10.195-17.66a2.998 2.998 0 012.693-1.496l6.052.194C19.922 4.067 24.236 0 29.5 0zm15.523 12h-3.23l-.921 34h5.073l-.922-34zm-5.23 0l-15.125-.001 3.387 5.454c.583.94.603 2.123.05 3.08l-.411.713c.293.689.323 1.475.06 2.2l-6.975 19.162a3 3 0 01-3.844 1.791l-1.82-.662-.06 2.263h23.816l.922-34zm-15.53 15.186l-6.354 11.007a3 3 0 01-4.097 1.096L4.738 34.05l-.878 2.414a1 1 0 00.598 1.282l13.155 4.788a1 1 0 001.282-.597l5.368-14.751zm-10.85-16.149a1 1 0 00-.898.5l-10.2 17.668a1 1 0 00.365 1.366l12.124 7a1 1 0 001.366-.366l10.201-17.668a1 1 0 00-.016-1.028l-2.079-3.35c-.02.038-.04.076-.062.114l-2.5 4.33a1 1 0 01-1.366.366l-5.196-3a1 1 0 01-.366-1.366l2.5-4.33.067-.111-3.94-.125zm6.336 1.967a2 2 0 101.733 1l-.366.635a1 1 0 01-1.732-1l.365-.635zM29.5 2a8 8 0 00-7.974 7.35c.1.018.197.042.294.068l1.064-1.841a1 1 0 011.732 1l-.822 1.422H37.5l-.004-.248A8 8 0 0029.5 2z"
          fill="#E30613"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};
