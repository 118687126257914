import { useCallback } from 'react';
import { HeyLightColour } from '@heidi-pay/heidi-component-library/enums';
import { baseTheme } from '@heidi-pay/heidi-component-library/themes';
import { Box, styled } from '@mui/material';
import { useTranslation } from '@hooks';
import { CopyBlock, QRCode } from '../../../components';
import {
  IBaseComponent,
  IComponentBaseOptions,
} from '../../../transaction/components/types';

export interface IInstoreMerchantRedirectQROptions
  extends IComponentBaseOptions {
  observationUrl: string;
}

const QRBoxStyled = styled(Box)({
  backgroundColor: 'white',
  border: `1px solid ${HeyLightColour.BrandAccentsHeyLightRed}`,
  borderRadius: baseTheme.borderRadius?.xl,
  boxShadow: '0 4px 30px 0 #0000001A',
  display: 'inline-block',
  padding: '18px 18px 15px 18px',
  marginBottom: '40px !important',
  marginTop: '60px',
});

export const InstoreMerchantRedirectQR = ({
  options,
  value: redirectUrl,
}: IBaseComponent<IInstoreMerchantRedirectQROptions, string>) => {
  if (!redirectUrl) {
    throw new Error('The checkout redirect url is required.');
  }

  if (!options) {
    throw new Error('The options prop is required.');
  }

  const { observationUrl } = options;
  const { t } = useTranslation();
  const altText = t('common.checkoutRedirectUrl');

  const handleGoToObservation = useCallback(
    () => window.location.assign(observationUrl),
    [observationUrl],
  );

  return (
    <>
      <CopyBlock
        i18nKey={'fields.textBlock.instoreQRCodeRedirect.content'}
        props={{ onClick: handleGoToObservation }}
        value={{ observationUrl }}
      />
      <QRBoxStyled>
        <QRCode altText={altText} size={189} text={redirectUrl} />
      </QRBoxStyled>
    </>
  );
};
