import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import delay from 'delay';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormControl,
  FormLabel,
  Box,
  Grid,
  FormControlLabel,
  FormHelperText,
  styled,
} from '@mui/material';
import { useTranslation } from '@hooks';
import { setCountriesFromInstore } from '@instore/redux';
import {
  getSelectedShipppingCountry,
  getSelectedBillingCountry,
} from '@instore/redux/selectors';
import { Checkbox } from '../../../../components/Checkbox';
import AddressField from './components/AddressField';
import { getAddressSubfields } from './utils/getAddressSubfields';

const FormLabelStyled = styled(FormLabel)({
  fontSize: '14px',
});

const FormControlLabelStyled = styled(FormControlLabel)({
  width: '100%',
  justifyContent: 'space-between',
  marginLeft: '0',
  position: 'relative',
  left: '-0.25rem',
});

// TODO eslint migration - move to separate file
function buildValidator({ maxLength, minLength, name, optional }) {
  let validator = yup.string();

  if (!optional) {
    validator = validator.required(
      `fields.addressContainer.subFields.${name}.required`,
    );
  }

  if (maxLength) {
    validator = validator.max(
      maxLength,
      `fields.addressContainer.subFields.${name}.required`,
    );
  }

  if (minLength) {
    validator = validator.min(
      minLength,
      `fields.addressContainer.subFields.${name}.required`,
    );
  }

  validator = validator.nullable();
  return validator;
}

function buildSchema(fields) {
  return Object.fromEntries(
    fields.map(field => [field.name, buildValidator(field)]),
  );
}

export const AddressContainer = ({
  name,
  onChange,
  options,
  submitAttempted,
  value,
}) => {
  const { t } = useTranslation();
  const { isExpandable, subFields } = options;
  const [address] = useState(value.address);
  const [isExpanded, setIsExpanded] = useState(value.isExpanded);
  const { hiddenFields = [] } = options;
  const shouldShowField = fieldName => !hiddenFields.includes(fieldName);

  // The shippingAddress container and billingAddress container have their
  // own copies of subfields they're changing.
  const checkSelectedShippingCountry = useSelector(getSelectedShipppingCountry);
  const selectedShippingCountry =
    checkSelectedShippingCountry || address.countryAlpha2;
  const useInstoreOrCheckoutsubFields = getAddressSubfields(
    subFields,
    selectedShippingCountry,
  );

  const checkSelectedBillingCountry = useSelector(getSelectedBillingCountry);
  const selectedBillingCountry =
    checkSelectedBillingCountry || address.countryAlpha2;
  const billingAddressSubFields = getAddressSubfields(
    subFields,
    selectedBillingCountry,
  );

  // determine which subfields addressContainer can be changed and rendered
  const shippingOrBillingSubfields =
    name === 'billingAddress'
      ? billingAddressSubFields
      : useInstoreOrCheckoutsubFields;

  const schema = yup.object().shape(buildSchema(shippingOrBillingSubfields));

  const { control, errors, formState, getValues, reset, trigger } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: address,
  });

  const formTouched = Object.keys(formState.touched).length !== 0;
  const firstError =
    ((formTouched || submitAttempted) && Object.values(errors)?.[0]?.message) ||
    '';

  const dispatch = useDispatch();
  useEffect(() => {
    // Return the value as form functions - for use in the validator
    onChange({
      getValues,
      trigger,
      hasErrors: () => Object.values(errors).length !== 0,
      isExpanded,
    });

    const inStoreCountriesFromOP =
      options.subFields.countries ?? Object.keys(options.subFields);

    if (inStoreCountriesFromOP) {
      dispatch(setCountriesFromInstore(inStoreCountriesFromOP));
    }
  }, [
    dispatch,
    errors,
    getValues,
    isExpanded,
    onChange,
    options.subFields,
    trigger,
  ]);
  return (
    <>
      {isExpandable ? (
        <FormControlLabelStyled
          control={
            <Checkbox
              checked={!isExpanded}
              fullWidth={true}
              name={`${name}-expand`}
              onChange={() => setIsExpanded(!isExpanded)}
            />
          }
          fullWidth={true}
          label={t(`fields.addressContainer.${name}.expandCheckbox`)}
          labelPlacement="start"
        />
      ) : null}

      {!isExpandable || (isExpandable && isExpanded) ? (
        <FormControl component="fieldset" fullWidth={true}>
          {name === 'billingAddress' ? (
            <FormLabelStyled component="legend">
              {t(`fields.addressContainer.${name}.label`)}
            </FormLabelStyled>
          ) : null}
          <Box mt={name === 'billingAddress' ? 4 : 0}>
            <Grid container={true} spacing={2}>
              {shippingOrBillingSubfields.map(subField => (
                <AddressField
                  {...subField} // eslint-disable-line  react/jsx-props-no-spreading
                  addressContainerType={name}
                  autoComplete={subField.autoComplete}
                  control={control}
                  errors={errors}
                  fullAddress={address}
                  hidden={!shouldShowField(subField.name)}
                  key={subField.name}
                  reset={reset}
                  submitAttempted={submitAttempted}
                />
              ))}
            </Grid>
          </Box>
          <FormHelperText error={true}>{t(firstError)}</FormHelperText>
        </FormControl>
      ) : null}
    </>
  );
};

AddressContainer.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({
    isExpanded: PropTypes.bool,
    address: PropTypes.shape(),
  }).isRequired,
  options: PropTypes.shape({
    isExpandable: PropTypes.bool,
    hiddenFields: PropTypes.arrayOf(PropTypes.string),
    subFields: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  submitAttempted: PropTypes.bool.isRequired,
};

AddressContainer.validation = () =>
  yup
    .object()
    .transform(form => {
      form.trigger();
      return {
        ...form,
        address: form.getValues(), // Resolve form values during validation
      };
    })
    .test('addressContainer', 'ERROR', async form => {
      await delay(100); // Allow above validation trigger to run
      return !form?.hasErrors();
    })
    .nullable();
