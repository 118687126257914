import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { useTranslation } from '@hooks';

export default function TextInput({
  autoComplete,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  value,
}) {
  const { t } = useTranslation();

  return (
    <TextField
      autoComplete={autoComplete}
      error={!!error}
      fullWidth={true}
      id={name}
      inputRef={inputRef}
      label={t(`fields.addressContainer.subFields.${name}.label`)}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      select={true}
      SelectProps={{
        native: true,
        shrink: value !== '',
      }}
      size="small"
      value={value}
      variant="outlined"
    >
      <option aria-label="None" value="" />
      {options.map(option => (
        <option key={option} value={option}>
          {t(`fields.addressContainer.subFields.${name}.options.${option}`)}
        </option>
      ))}
    </TextField>
  );
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.string,
  inputRef: PropTypes.shape(),
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  autoComplete: PropTypes.string,
};

TextInput.defaultProps = {
  value: '',
  error: '',
  inputRef: undefined,
  autoComplete: undefined,
};
