import React from 'react';
import { Grid, styled, useMediaQuery, Theme } from '@mui/material';
import { useTranslation } from '@hooks';

interface ICircleProps {
  color?: string;
}

const Circle = styled('div', {
  shouldForwardProp: (prop: string) => !['color'].includes(prop),
})<ICircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  border-radius: 50%;
  border: 2px solid ${props => props.color || '#000'};
  color: ${props => props.color || '#000'};
  font-weight: 500;
`;

const Step = styled(Grid)`
  font-weight: 500;
  font-size: 1rem;
`;

interface IStepItemProps {
  index: number;
  text: string;
}

const StepItem = ({ index, text }: IStepItemProps): JSX.Element => {
  const isMobile = useMediaQuery<Theme>('(max-width:600px)');

  return (
    <Grid
      alignItems="center"
      container={true}
      justifyContent="center"
      pb={4}
      textAlign={isMobile ? 'center' : 'start'}
    >
      <Grid
        item={true}
        sm={1}
        style={{ display: 'flex', justifyContent: 'center' }}
        xs={12}
      >
        <Circle>{index + 1}</Circle>
      </Grid>
      <Step item={true} pt={0.6} sm={7} xs={12}>
        {text}
      </Step>
    </Grid>
  );
};

export const OnfidoFailureContainer = (): JSX.Element => {
  const { t } = useTranslation();
  // TODO: fix the typing for this
  const steps = t('fields.onfidoFailureSteps') as unknown as string[];

  return (
    <>
      {steps.map((step, index) => (
        <StepItem index={index} key={step} text={step} />
      ))}
    </>
  );
};
