import axios from 'axios';
import { XMLParser } from 'fast-xml-parser';
import { ICallOptions, IParsedHttpResponse } from './types';

export const parseXmlResponse = <TResponse = unknown>(
  xml: string,
): TResponse => {
  const parser = new XMLParser();
  const jsObject = parser.parse(xml);
  return jsObject.response as TResponse;
};

export const xmlFetch = async <TResponse = unknown>(
  url: string,
  options: ICallOptions,
): Promise<IParsedHttpResponse<TResponse>> => {
  const response = await axios.get(url, {
    headers: {
      Accept: 'application/xml',
    },
    timeout: options.timeout ?? 1000 * 100, // The timeout matches cloudflare's default connection timeout
  });

  const body = await parseXmlResponse(response.data);

  return {
    originalResponse: response,
    parsedData: body as TResponse,
  };
};
