import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControl, Grid } from '@mui/material';
import { registerSubFormValidation } from '../../../../utils';
import {
  getCities,
  getProvinces,
} from '../../../services/ItalianPostAddressService';
import { VisibilityContainer } from '../../VisibilityContainer';
import {
  ControlledChainedAutocomplete,
  ControlledTextField,
} from './components';
import { AddressField } from './utils/constants';
import { getValidationFields } from './utils/getValidationFields';
import { mapOcrFormValuesToAddressFields } from './utils/mapFormValuesToAddressFields';

export const DRIVING_LICENSE_CODE = 'driving_licence';

export const AddressContainerItalianPostOcr = ({
  onChange,
  options,
  submitAttempted,
  value,
  watch: watchParentField,
}) => {
  const {
    disabledFields,
    documentNumberFieldName,
    documentTypeFieldName,
    hideCityField,
    hideCityFieldForDrivingLicense,
  } = options;

  const [isRomaLicense, setIsRomaLicense] = useState(false);

  const Field = isRomaLicense
    ? ControlledTextField
    : ControlledChainedAutocomplete;

  const shouldDisableField = fieldName => disabledFields?.includes(fieldName);

  const documentNumberValue = documentNumberFieldName
    ? watchParentField(documentNumberFieldName)
    : '';
  const documentTypeValue = documentTypeFieldName
    ? watchParentField(documentTypeFieldName)
    : '';

  const isDrivingLicense =
    documentTypeValue?.toLowerCase() === DRIVING_LICENSE_CODE;
  const shouldHideCityField =
    hideCityFieldForDrivingLicense && isDrivingLicense;

  const fieldsToValidate = getValidationFields(!shouldHideCityField);
  const schema = yup.object().shape(buildSchema(fieldsToValidate));

  const { control, errors, getValues, setValue, trigger, watch } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: value,
  });

  useEffect(() => {
    // Return the value as form functions - for use in the validator
    onChange({
      getValues: () => mapOcrFormValuesToAddressFields(getValues()),
      hasErrors: () => Object.values(errors).length !== 0,
      isSubmitAttempted: () => submitAttempted,
      trigger,
    });
  }, [errors, getValues, onChange, submitAttempted, trigger]);

  useEffect(() => {
    if (documentNumberFieldName) {
      const isRomaDrivingLicense = documentNumberValue
        ?.toLowerCase()
        ?.startsWith('u1');

      if (isDrivingLicense && isRomaDrivingLicense) {
        setIsRomaLicense(true);

        setTimeout(() => {
          setValue(
            AddressField.province,
            { displayValue: 'ROMA', value: 'RM' },
            { shouldValidate: true, shouldDirty: true },
          );
          setValue(
            AddressField.city,
            { displayValue: 'ROMA', value: 'ROMA' },
            { shouldValidate: true, shouldDirty: true },
          );
        }, 50);
      } else {
        setIsRomaLicense(false);
      }
    }
  }, [
    documentNumberFieldName,
    documentNumberValue,
    documentTypeValue,
    isDrivingLicense,
    onChange,
    setValue,
    shouldHideCityField,
  ]);

  return (
    <FormControl fullWidth={true}>
      <Box component="form" pt="0.725rem">
        <Grid container={true}>
          <Grid item={true} xs={12}>
            <Field
              control={control}
              disabled={shouldDisableField(AddressField.province)}
              errorKey={`fields.addressContainerItalianPostOcr.${AddressField.province}.required`}
              errors={errors}
              fetchItems={getProvinces}
              fetchOnInitialLoad={true}
              fetchOnUpstreamFilterChange={true}
              fullWidth={true}
              id={AddressField.province}
              labelKey={`fields.addressContainerItalianPostOcr.${AddressField.province}.label`}
              matchFromStart={true}
              name={AddressField.province}
              submitAttempted={submitAttempted}
            />
          </Grid>
          <Grid item={true} mt={2} xs={12}>
            <VisibilityContainer
              isHidden={shouldHideCityField}
              isRendered={!hideCityField}
            >
              <Field
                control={control}
                disabled={shouldDisableField(AddressField.city)}
                errorKey={`fields.addressContainerItalianPostOcr.${AddressField.city}.required`}
                errors={errors}
                fetchItems={getCities}
                fullWidth={true}
                id={AddressField.city}
                labelKey={`fields.addressContainerItalianPostOcr.${AddressField.city}.label`}
                name={AddressField.city}
                submitAttempted={submitAttempted}
                upstreamFilter={watch(AddressField.province)}
              />
            </VisibilityContainer>
          </Grid>
        </Grid>
      </Box>
    </FormControl>
  );
};

AddressContainerItalianPostOcr.propTypes = {
  onChange: PropTypes.func.isRequired,
  submitAttempted: PropTypes.bool.isRequired,
  value: PropTypes.shape().isRequired,
  options: PropTypes.shape({
    disabledFields: PropTypes.arrayOf(PropTypes.string),
    hideCityField: PropTypes.bool,
  }),
};

AddressContainerItalianPostOcr.defaultProps = {
  options: {
    disabledFields: [],
    hideCityField: false,
  },
};

export const validation = registerSubFormValidation('addressContainer');

AddressContainerItalianPostOcr.validation = validation;

function buildValidator({ name, optional }) {
  if (optional) {
    return yup.object().nullable();
  }

  return yup.object({
    value: yup
      .string()
      .required(`fields.addressContainerItalianPostOcr.${name}.required`),
  });
}

function buildSchema(fields) {
  return Object.fromEntries(
    fields.map(field => [field.name, buildValidator(field)]),
  );
}
