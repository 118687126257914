import PropTypes from 'prop-types';
import { useTranslation } from '@hooks';
import { useFormatters } from '../../../../utils';
import { PaymentRow } from './PaymentRow';

export const ScheduleTable = ({ currency, hideTitle, payNow, schedule }) => {
  const { t } = useTranslation();
  const { formatAmountWithCurrency, formatDate } = useFormatters();

  const title = t('fields.paymentPlan.schedule');

  return (
    <>
      {hideTitle ? null : <PaymentRow key={title} label={title} />}
      <PaymentRow
        key={payNow.amount}
        label={t('fields.paymentPlan.rows.today')}
        value={formatAmountWithCurrency(payNow.amount, currency)}
      />
      {schedule.map(({ amount, date }) => (
        <PaymentRow
          key={date}
          label={formatDate({ date })}
          value={formatAmountWithCurrency(amount, currency)}
        />
      ))}
    </>
  );
};

ScheduleTable.propTypes = {
  currency: PropTypes.string.isRequired,
  hideTitle: PropTypes.bool,
  payNow: PropTypes.shape().isRequired,
  schedule: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

ScheduleTable.defaultProps = {
  hideTitle: false,
};
