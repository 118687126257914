// All enums to be extended once we know more about the new branding
export enum ColorCodes {
  ErrorContrastText = '#fff',
  ErrorDark = '#d32f2f',
  ErrorLight = '#e57373',
  ErrorMain = '#f44336',
  ExternalSpidContrastText = '#fff',
  ExternalSpidDark = '#02398a',
  ExternalSpidMain = '#0066cc',
  Inherit = 'inherit',
  LegacyGreyLight = '#d3d3d3',
  LegacyGreyMain = '#f7f7f7',
  PagolightPrimaryDark = '#b12820',
  SuccessContrastText = 'rgba(0, 0, 0, 0.87)',
  SuccessDark = '#388e3c',
  SuccessLight = '#81c784',
  SuccessMain = '#4caf50',
}

export enum BorderRadius {
  Small = '4px',
}

export enum Themes {
  HeyLight = 'heylight',
  HeyLightSwiss = 'heylight_swiss',
}
