import { string, func, bool } from 'prop-types';
import { FormControl, FormHelperText, Grid } from '@mui/material';
import { useTranslation } from '@hooks';
import { ZoomScrollableContainer } from '../../ZoomScrollableContainer';
import { Signature } from './Signature';

export const SignatureBlock = ({
  error,
  label,
  onChange,
  textContent,
  value,
}) => {
  const { t } = useTranslation();

  const handleOnChange = event => {
    event.preventDefault();
    onChange(event.target.checked);
  };

  return (
    <Grid item={true} mt={4} textAlign="left" xs={12}>
      <ZoomScrollableContainer>{textContent}</ZoomScrollableContainer>
      <FormControl error={!!error}>
        <Signature label={label} onChange={handleOnChange} value={value} />
        <FormHelperText>{t(error)}</FormHelperText>
      </FormControl>
    </Grid>
  );
};

SignatureBlock.propTypes = {
  textContent: string.isRequired,
  label: string.isRequired,
  value: bool.isRequired,
  onChange: func.isRequired,
  error: string,
};

SignatureBlock.defaultProps = {
  error: null,
};
