import retry from 'async-retry';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { OriginatedOnBackendTagKey, SentrySeverity } from '../sentry';
import { EnhancedError } from './EnhancedError';
import { enhanceErrorMessage } from './enhanceErrorMessage';
import { ContentType, HttpMethod, LockedSessionStatusCode } from './enums';
import { getSentrySeverity } from './getSentrySeverity';
import { jsonFetch } from './jsonFetch';
import { retryHandler } from './retryHandler';
import { ICallOptions, ISoftError } from './types';
import { xmlFetch } from './xmlFetch';

export const SoftErrorStatus = 'SOFT_ERROR';

export const getEnhancedError = (
  error: AxiosError,
  method: string,
  url: string,
): EnhancedError => {
  const responseData = error.response?.data;
  const enhancedErrorMessage = enhanceErrorMessage(error?.message, method, url);
  const statusCode = error?.response?.status;

  const isSoftError = (responseData as ISoftError)?.status === SoftErrorStatus;
  const softErrorCode = isSoftError
    ? (responseData as ISoftError).code
    : undefined;
  const severity = getSentrySeverity(statusCode, error.code);
  const isLockedSession = statusCode === LockedSessionStatusCode;

  return new EnhancedError(enhancedErrorMessage, {
    isLoggedUpstream: true,
    isLockedSession,
    originalError: error,
    severity,
    softErrorCode,
    status: statusCode,
  });
};

const executeHttpRequest = async <TResponse = unknown>(
  url: string,
  type = ContentType.Json,
  options: ICallOptions = { disableRetry: false },
) => {
  const requestHandlers = {
    [ContentType.Json]: jsonFetch,
    [ContentType.Xml]: xmlFetch,
  };

  const { parsedData } = await requestHandlers[type]<TResponse>(url, options);
  return parsedData;
};

export default async function call<TResponse = unknown>(
  url: string,
  options: ICallOptions = { disableRetry: false },
  type: ContentType = ContentType.Json,
): Promise<TResponse> {
  const { disableRetry, method = HttpMethod.Get.toString() } = options;
  const shouldRetry =
    ![HttpMethod.Post.toString(), HttpMethod.Delete.toString()].includes(
      method,
    ) && !disableRetry;
  const retries = shouldRetry ? 3 : 0;
  try {
    return await retry(
      async (cancelRetry: () => unknown) =>
        retryHandler(
          async () => executeHttpRequest<TResponse>(url, type, options),
          cancelRetry,
        ),
      {
        retries,
      },
    );
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.isAxiosError) {
      const enhancedError = getEnhancedError(axiosError, method, url);

      Sentry.setTag(OriginatedOnBackendTagKey, true);
      Sentry.setExtra('error', error);
      Sentry.captureMessage(enhancedError?.message, {
        level: enhancedError?.severity ?? SentrySeverity.warning,
      });

      return Promise.reject(enhancedError);
    }
    return Promise.reject(error);
  }
}

export const getFromXmlEndpoint = async (
  url: string,
  options: ICallOptions = { disableRetry: false },
) => call(url, options, ContentType.Xml);
