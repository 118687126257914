export enum FieldType {
  CodiceFiscale = 'codiceFiscale',
  Email = 'email',
  IbanNumber = 'ibanNumber',
}

export enum InputType {
  Email = 'email',
  Name = 'name',
  Text = 'text',
}
