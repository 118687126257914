import { ReactNode, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { Link } from '@heidi-pay/heidi-component-library/components/Links';
import {
  H5,
  H6,
} from '@heidi-pay/heidi-component-library/components/Typography';
import { BaseColour } from '@heidi-pay/heidi-component-library/enums';
import { styled, Typography } from '@mui/material';
import { Br, AccentSpan } from '../../../../../components/CopyBlock/components';

interface IDialogLinkProps {
  children?: ReactNode;
  dialogId?: string;
  onClick?: (dialogId: string) => void;
  withIcon?: boolean;
}

const DialogLink = ({
  children,
  dialogId,
  onClick,
  withIcon = false,
}: IDialogLinkProps) => {
  const openDialog = useCallback(
    () => onClick?.(dialogId ?? ''),
    [dialogId, onClick],
  );

  return (
    <Link onClick={openDialog} withIcon={withIcon}>
      {children}
    </Link>
  );
};

export const Paragraph = <Typography component="p" variant="body1" />;

const SubtitleLink = styled(Link)`
  line-height: 25px;
  font-size: 15px;
`;

export interface IComplianceTransProps {
  i18nKey: string;
  openDialog?: (dialogId: string) => void;
  value?: {
    [key: string]: unknown;
  };
}

export const ComplianceTrans = ({
  i18nKey,
  openDialog,
  value,
}: IComplianceTransProps) => (
  <Trans
    components={{
      accentSpan: AccentSpan,
      bold: <b />,
      br: Br,
      dialogLink: <DialogLink onClick={openDialog} />,
      dialogLinkInfo: <DialogLink onClick={openDialog} withIcon={true} />,
      hlink: <Link withIcon={true} />,
      h5: <H5 />,
      h6: <H6 pb="10px" />,
      paragraph: Paragraph,
      subtitle: <Typography pb="20px" variant="subtitle1" />,
      subtitleLink: <SubtitleLink withIcon={true} />,
      list: (
        <Typography
          color="inherit"
          component="ul"
          p="0 0 1rem 1.25rem"
          variant="body1"
        />
      ),
      listItem: <Typography component="li" pb="20px" variant="body1" />,
      listItemNoPadding: <Typography component="li" variant="body1" />,
      smallComplianceText: (
        <Typography
          color={BaseColour.Grey1}
          component="span"
          display="inline"
          variant="caption"
        />
      ),
    }}
    i18nKey={i18nKey}
    ns="compliance"
    values={value}
  />
);
