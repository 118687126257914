import { SvgIcon } from '@mui/material';

export function Tick(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="-3 -3 20 17">
      <rect
        height="12.3693"
        rx="1.34883"
        transform="matrix(0.695135 0.718879 -0.688053 0.72566 12.1248 0)"
        width="2.69766"
      />
      <rect
        height="7.74789"
        rx="1.34933"
        transform="matrix(-0.688949 0.72481 -0.694246 -0.719738 7.23828 9.04398)"
        width="2.69866"
      />
    </SvgIcon>
  );
}

export function Cross(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} viewBox="-3 -3 20 20">
      <rect
        height="16.2509"
        rx="1.5"
        transform="matrix(0.711102 0.703089 -0.709107 0.705101 11.5237 6.86646e-05)"
        width="3.48242"
      />
      <rect
        height="16.2495"
        rx="1.5"
        transform="matrix(0.718412 -0.695618 0.701697 0.712476 0 2.42267)"
        width="3.48273"
      />
    </SvgIcon>
  );
}
