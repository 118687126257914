/* eslint-disable max-len */
import { IIconProps } from '../type';

export const PagolightEmailIcon = ({ options }: IIconProps) => {
  const { height, width } = options ?? {};
  return (
    <svg
      aria-label="email"
      height={height ?? '48'}
      role="img"
      style={{ background: '#DADADA' }}
      viewBox="0 0 48 48"
      width={width ?? '48'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Email</title>

      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path d="M0 0H48V48H0z" fill="#FFF"></path>
        <path
          d="M25.234.698L33.257 7H41a3 3 0 013 3l.001 5.416c1.279 1 2.225 1.738 2.836 2.212A2.995 2.995 0 0148 19.995V45a3 3 0 01-3 3H3a3 3 0 01-3-3V20c0-.921.426-1.792 1.153-2.361.682-.531 1.63-1.272 2.846-2.223L4 10a3 3 0 013-3h7.724l.042-.032L22.77.697a1.996 1.996 0 012.464.001zM24 29.272L2.688 45.95A1 1 0 003 46h42a1 1 0 00.312-.05L24 29.272zM2.001 21.357L2 43.947 16.679 32.46l-4.474-3.384-.357-.27L2 21.356zm34.41 7.251l-.314.238-.36.272-4.418 3.342L46 43.948V21.356l-9.588 7.252zM41 9H7a1 1 0 00-1 1v11.874l6.696 5.065.358.27.358.27.36.273 4.537 3.432 4.458-3.487a2 2 0 012.466 0l4.458 3.487 4.478-3.387.362-.274.36-.272.54-.408 6.57-4.97L42 10a1 1 0 00-1-1zM2.563 19.076l-.128.101 1.566 1.185L4 17.954l-1.436 1.122zm41.438-1.122l-.002 2.407 1.569-1.186.045.034c-.398-.308-.935-.727-1.612-1.255zM23.999 2.271l-.06.05L17.969 7h12.05l-.4-.314-.265-.209-5.355-4.206z"
          fill="#E30613"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};
