import { captureMessage } from '@sentry/react';
import { SentrySeverity } from '../../../utils';
import { IInitialisePaymentsIframe } from './types';

export const getOrigin = (url: string) => new URL(url).origin;

export const initialisePaymentsIframe = ({
  continueButtonText,
  iframeRef,
  theme,
  url,
}: IInitialisePaymentsIframe) => {
  const iframeWindow = iframeRef?.current?.contentWindow;
  if (iframeWindow) {
    const primaryColor = theme.palette.primary.main;
    iframeWindow.postMessage(
      { message: 'init', formSettings: { continueButtonText, primaryColor } },
      getOrigin(url),
    );
  } else {
    captureMessage(
      'Error retrieving window content from initialise payments iframe',
      {
        level: SentrySeverity.warning,
      },
    );
  }
};
