import { Fragment } from 'react';
import { useFieldArray } from 'react-hook-form';
import { BaseButton } from '@heidi-pay/heidi-component-library/components/Buttons/BaseButton';
import { Divider, Grid, styled } from '@mui/material';
import { useTranslation } from '@hooks';

const StyledDivider = styled(Divider, {
  shouldForwardProp: prop => !['withMargin'].includes(prop),
})`
  opacity: 0.5;
  margin: 1rem 40px 1rem 0;
`;

export const MultiProductForm = ({
  control,
  currency,
  defaultValues,
  errors,
  getValues,
  InstoreProductForm,
  instoreProductFormProps,
  isAddProductButtonDisabled = false,
  setValue,
  watch,
}) => {
  const { t } = useTranslation();

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'products',
  });

  const handleAddForm = () => append(defaultValues);

  return (
    <Grid container={true}>
      <Grid item={true} xs={12}>
        {fields.map((field, index) => (
          <Fragment key={field.id}>
            <InstoreProductForm
              control={control}
              currency={currency}
              enableRemove={index > 0}
              errors={errors}
              getValues={getValues}
              index={index}
              remove={remove}
              setValue={setValue}
              watch={watch}
              {...instoreProductFormProps}
            />
          </Fragment>
        ))}
      </Grid>
      {isAddProductButtonDisabled ? null : (
        <Grid item={true} textAlign="center" xs={12}>
          <StyledDivider />
          <BaseButton onClick={handleAddForm} variant="outlined">
            {t('fields.instoreMultiProductFormContainer.multiProductForm.add')}
          </BaseButton>
        </Grid>
      )}
    </Grid>
  );
};
