import { useCallback } from 'react';
import { Dialog } from '@heidi-pay/heidi-component-library/components/Dialog';
import { CopyBlock } from '../../../../../components/CopyBlock';

interface IPoliticallyExposedPersonDialog {
  isOpen: boolean;
  setDialogIsOpen: (open: boolean) => void;
}

export const PoliticallyExposedPersonDialog = ({
  isOpen,
  setDialogIsOpen,
}: IPoliticallyExposedPersonDialog) => {
  const handleClose = useCallback(() => {
    setDialogIsOpen(false);
  }, [setDialogIsOpen]);

  const sections = ['section1', 'section2', 'section3', 'section4'];
  return (
    <Dialog isOpen={isOpen} onCloseClick={handleClose}>
      <CopyBlock
        i18nKey={
          'fields.buttonGroupPicker.politicallyExposedIndividual.dialog.title'
        }
      />
      {sections.map((section: string) => (
        <CopyBlock
          i18nKey={`fields.buttonGroupPicker.politicallyExposedIndividual.dialog.${section}`}
          key={section}
        />
      ))}
    </Dialog>
  );
};
