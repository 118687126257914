import {
  FormControl,
  styled,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import { useTranslation } from '@hooks';
import { useFormatters } from '../../../utils';
import { PaymentRow } from './components/PaymentRow';

const StyledFormControl = styled(FormControl)({
  marginTop: '0.5rem',
  marginBottom: '1rem',
  fontSize: '1rem',
  '& address': {
    fontStyle: 'normal',
  },
});

const Container = styled(TableContainer)({
  marginRight: '0.5rem',
  marginTop: '0',
  marginBottom: '0.7rem',
});

const StyledTable = styled(Table)({
  '& td, & th': {
    fontSize: '1rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    paddingTop: '0.1rem',
    paddingBottom: '0.1rem',
    border: 'none',
    '&:first-child': {
      width: '100%',
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
  },
  '& th': {
    fontWeight: 'bold',
  },
});

interface IOrderSummaryValue {
  options: { showTotal: boolean };
  payment: {
    currency: string;
    payNow: string;
    total: string;
  };
}

interface IOrderSummary {
  value: IOrderSummaryValue;
}

export const OrderSummary = ({ value }: IOrderSummary) => {
  const { t } = useTranslation();
  const { formatAmountWithCurrency } = useFormatters();

  const {
    options: { showTotal },
    payment: { currency, payNow, total },
  } = value;

  const shouldDisplayPayNow = Number(payNow) > 0;

  return (
    <StyledFormControl fullWidth={true} size="small">
      <Container>
        <StyledTable size="small">
          <TableBody>
            {showTotal ? (
              <PaymentRow
                amount={formatAmountWithCurrency(total, currency)}
                title={t('fields.orderSummary.rows.total')}
              />
            ) : null}
            {shouldDisplayPayNow ? (
              <PaymentRow
                amount={formatAmountWithCurrency(payNow, currency)}
                header={true}
                title={t('fields.orderSummary.rows.payNow')}
              />
            ) : null}
          </TableBody>
        </StyledTable>
      </Container>
    </StyledFormControl>
  );
};
