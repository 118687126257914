export const getProductTotals = products =>
  products
    .filter(product => product.productSku && product.price?.amount)
    .reduce(
      (aggregate, product) => {
        const currentProductQuantity = Number(product?.quantity || 1);
        const currentProductAmount = parseFloat(
          product.price?.amount || 0,
        ).toFixed(2);
        const currentProductAmmountForQuantity =
          currentProductAmount * currentProductQuantity;

        const currentProductResidualValue = parseFloat(
          product.residualValue?.amount || 0,
        ).toFixed(2);
        const currentProductResidualValueForQuantity =
          currentProductResidualValue * currentProductQuantity;

        const parsedProduct = {
          discount: product.discount || '0',
          price: currentProductAmount,
          quantity: currentProductQuantity,
          sku: product.productSku,
        };

        const currency = aggregate?.currency || product.price?.currency;

        const amount =
          aggregate.totalAmount.amount + currentProductAmmountForQuantity;
        const totalAmount = { amount, currency };

        const residualValue =
          aggregate.totalResidualValue.amount +
          currentProductResidualValueForQuantity;
        const totalResidualValue = { amount: residualValue, currency };

        const aggregatedProducts = [...aggregate.products, parsedProduct];

        return {
          products: aggregatedProducts,
          totalAmount,
          totalResidualValue,
        };
      },
      {
        products: [],
        totalAmount: { amount: 0, currency: null },
        totalResidualValue: { amount: 0, currency: null },
      },
    );
