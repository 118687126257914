import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import { useReloadIframeForFailure } from '../utils/useReloadIframeForFailure';

const StyledIframe = styled('iframe')`
  background-color: ${props => props.theme.palette.legacyGrey.main};
  border: 0;
`;

export const Iframe = ({ height, src, title, width }) => {
  const { iframeRef, onError, onIframeLoaded } = useReloadIframeForFailure(src);

  return (
    <StyledIframe
      action="embedview"
      height={height}
      onError={onError}
      onLoad={onIframeLoaded}
      ref={iframeRef}
      src={src}
      title={title}
      width={width}
    />
  );
};

Iframe.propTypes = {
  height: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
};

Iframe.defaultProps = {
  width: '100%',
};
