import { useTranslation } from '../../../hooks';
import { Emoji as EmojiCodes } from './enums';

interface IEmojiProps {
  ariaLabelTranslationKey: string;
  code: string;
  role?: string;
}

const Emoji = ({
  ariaLabelTranslationKey,
  code,
  role = 'img',
}: IEmojiProps) => {
  const { t } = useTranslation();

  return (
    <span aria-label={t(`emoji.${ariaLabelTranslationKey}`)} role={role}>
      {code}
    </span>
  );
};

export const HourGlass = () => (
  <Emoji ariaLabelTranslationKey="hourglass" code={EmojiCodes.Hourglass} />
);

export const MagnifyingGlass = () => (
  <Emoji
    ariaLabelTranslationKey="magnifyingGlass"
    code={EmojiCodes.MagnifyingGlass}
  />
);

export const Muscle = () => (
  <Emoji ariaLabelTranslationKey="muscle" code={EmojiCodes.Muscle} />
);

export const WavingHand = () => (
  <Emoji ariaLabelTranslationKey="wavingHand" code={EmojiCodes.Waving} />
);

export const SlightFrowningFace = () => (
  <Emoji
    ariaLabelTranslationKey="slightFrowningFace"
    code={EmojiCodes.SlightFrowningFace}
  />
);

export const emojiComponents = {
  hourglass: <HourGlass />,
  magnifyingGlass: <MagnifyingGlass />,
  waving: <WavingHand />,
  muscle: <Muscle />,
  slightFrowningFace: <SlightFrowningFace />,
};
