import { Box } from '@mui/material';

export const Centre = <div style={{ textAlign: 'center' }} />;

export const Container = <Box />;

export const layoutComponents = {
  centre: Centre,
  container: Container,
};
