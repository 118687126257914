import { number } from 'prop-types';
import { Box, styled } from '@mui/material';

export const HourGlass = styled('div', {
  shouldForwardProp: prop => !['height'].includes(prop),
})`
  animation: loader 5s cubic-bezier(0.8, 0, 0.2, 1) infinite;
  height: ${props => props.height}px;
  left: calc(50% - ${props => props.height / 2}px);
  position: absolute;
  top: calc(50% - ${props => props.height / 2} px);
  width: ${props => props.height}px;

  @keyframes loader {
    90% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }

  > .top {
    animation: top 5s linear infinite;
    border-left: ${props => props.height / 2}px solid transparent;
    border-right: ${props => props.height / 2}px solid transparent;
    border-top: ${props => props.height / 2}px solid #000;
    height: 0;
    transform-origin: 50% 100%;
    width: 1px;
  }
  @keyframes top {
    90% {
      transform: scale(0);
    }
    100% {
      transform: scale(0);
    }
  }

  > .bottom {
    animation: bottom 5s linear infinite;
    border-bottom: ${props => props.height / 2}px solid #000;
    border-left: ${props => props.height / 2}px solid transparent;
    border-right: ${props => props.height / 2}px solid transparent;
    height: 0;
    transform: scale(0);
    transform-origin: 50% 100%;
    width: 1px;
  }
  @keyframes bottom {
    10% {
      transform: scale(0);
    }
    90% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }

  > .sand {
    animation: sand 5s linear infinite;
    border-left: 1px dotted #000;
    height: 0;
    left: ${props => props.height / 2}px;
    position: absolute;
    top: ${props => props.height / 2}px;
    width: 0;
  }
  @keyframes sand {
    10% {
      height: ${props => props.height / 2 - 1}px;
    }
    100% {
      height: ${props => props.height / 2 - 1}px;
    }
  }
`;

export const HourGlassLoader = ({ heightInPx = 40 }) => (
  <Box color="primary" height={heightInPx} position="relative">
    <HourGlass aria-label="loading" height={heightInPx}>
      <div className="top" />
      <div className="bottom" />
      <div className="sand" />
    </HourGlass>
  </Box>
);

HourGlassLoader.propTypes = {
  heightInPx: number,
};

HourGlassLoader.defaultProps = {
  heightInPx: 40,
};
