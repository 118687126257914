// splits the phrase into two phrases so we can display overflow
// on a new line therefore maintaining the typewriter effect
const getDisplayPhrases = (phrase, maximumPhraseLength) => {
  const phraseWords = phrase?.split(' ') ?? [];

  return phraseWords.reduce(
    (prev, current) => {
      const newPhraseOne = `${prev.phraseOne} ${current}`.trim();

      if (!prev.phraseTwo && newPhraseOne.length <= maximumPhraseLength) {
        return { ...prev, phraseOne: newPhraseOne };
      }

      return { ...prev, phraseTwo: `${prev.phraseTwo} ${current}`.trim() };
    },
    { phraseOne: '', phraseTwo: '' },
  );
};

// returns the animation length in seconds based on the phrase length
// to maintain the smooth typewriter effect for the phrase chunk which
// had to go on the second line
const getAnimationLenghtForPhrase = (
  phrase,
  maximumAnimationLength,
  maximumPhraseLength,
) => {
  const animationLengthPerCharacter =
    maximumAnimationLength / maximumPhraseLength;
  const animationLengthForPhrase = animationLengthPerCharacter * phrase.length;

  return Math.round(animationLengthForPhrase * 100) / 100;
};

export { getAnimationLenghtForPhrase, getDisplayPhrases };
