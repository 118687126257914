import { IPollingResponse } from 'src/transaction/services/types';
import { call } from '../utils/http';

export async function checkIntrumIdentityStatus() {
  // http request retry disabled because the method is called on an interval so will be retried
  return call<IPollingResponse>(
    '/api/checkout-client/intrum-identity/status/',
    {
      method: 'GET',
      disableRetry: true,
    },
  );
}

export async function checkIntrumSigningStatus() {
  // http request retry disabled because the method is called on an interval so will be retried
  return call<IPollingResponse>('/api/checkout-client/intrum-signing/status/', {
    method: 'GET',
    disableRetry: true,
  });
}
