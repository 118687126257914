import { BaseButton } from '@heidi-pay/heidi-component-library/components/Buttons/BaseButton';
import { Box, Grid, styled, Typography } from '@mui/material';
import { useTranslation } from '@hooks';
import { MitekErrorCode } from '../enums';
import { IState } from '../types';

const errorCodesMap: { [key in MitekErrorCode]: string } = {
  [MitekErrorCode.MitekErrorFocus]:
    'fields.mitekDocumentOcrContainer.errors.errorFocus',
};

const ImageStyled = styled('img')`
  width: 100%;
  height: auto;
`;

interface IScanningResultContainerProps {
  onRetryProcessClick: () => void;
  onSubmitClick: () => void;
  state: IState;
}

export const ScanningResultContainer = ({
  onRetryProcessClick,
  onSubmitClick,
  state,
}: IScanningResultContainerProps) => {
  const { t } = useTranslation();

  const errorTranslationKey = state.errorCode
    ? errorCodesMap[state.errorCode]
    : undefined;

  const canSubmit = !errorTranslationKey && state.imageSrc;

  return (
    <>
      <Box>
        <ImageStyled
          alt={t(
            'fields.mitekDocumentOcrContainer.scanningResultContainer.imageAltText',
          )}
          src={state.imageSrc}
        />
      </Box>

      <Box mb="30px" mt="15px">
        <Typography variant="body2">
          {errorTranslationKey
            ? t(errorTranslationKey)
            : t(
                'fields.mitekDocumentOcrContainer.scanningResultContainer.info',
              )}
        </Typography>
      </Box>

      <Grid container={true} spacing={2}>
        <Grid item={true} xs={canSubmit ? 6 : 12}>
          <BaseButton
            fullWidth={true}
            onClick={onRetryProcessClick}
            variant="outlined"
          >
            {t('fields.mitekDocumentOcrContainer.retryButton.label')}
          </BaseButton>
        </Grid>
        {canSubmit ? (
          <Grid item={true} xs={6}>
            <BaseButton
              fullWidth={true}
              onClick={onSubmitClick}
              variant="contained"
            >
              {t('fields.mitekDocumentOcrContainer.submitButton.label')}
            </BaseButton>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};
