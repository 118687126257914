import { func, string, arrayOf } from 'prop-types';
import { Button, ButtonGroup as MUIButtonGroup, styled } from '@mui/material';
import { useTranslation } from '@hooks';

const StyledButton = styled(Button)`
  &:disabled {
    background-color: ${props => props.theme.palette.primary.main};
    border-color: ${props => props.theme.palette.primary.main};
    color: #fff;
  }
  &:disabled:hover {
    background-color: ${props => props.theme.palette.primary.main};
  }
`;

export const ButtonGroup = ({ name, onChange, options, value }) => {
  const { t } = useTranslation();

  return (
    <MUIButtonGroup
      size="small"
      sx={{ marginTop: '0.425rem' }}
      variant="outlined"
    >
      {options.map(item => (
        <StyledButton
          disabled={value === item}
          key={item}
          onClick={() => onChange(item)}
          size="small"
        >
          {t(`fields.buttonGroupPicker.${name}.options.${item}`)}
        </StyledButton>
      ))}
    </MUIButtonGroup>
  );
};

ButtonGroup.propTypes = {
  name: string.isRequired,
  value: string.isRequired,
  options: arrayOf(string).isRequired,
  onChange: func.isRequired,
};
