import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAbandonVisible, getCancelUrl } from '@redux';
import { getActionSettings } from '@utils';

export const useGetHeaderOptions = () => {
  const cancelUrl = useSelector(getCancelUrl);

  const { isAbandonDisabled } = getActionSettings();
  const abandonVisible = useSelector(getAbandonVisible) && !isAbandonDisabled;

  const [isRedirectToMerchantDisabled, setIsRedirectToMerchantDisabled] =
    useState(false);

  const backToMerchant = async () => {
    // Some users manage to double click the button before redirecting
    setIsRedirectToMerchantDisabled(true);
    window.location.assign(cancelUrl);
  };

  return {
    abandonVisible,
    backToMerchant,
    cancelUrl,
    isRedirectToMerchantDisabled,
  };
};
