export const overrideEventValue = (event, value) =>
  value ? { ...event, target: { value } } : null;

export const toKeyValuePairOrNull = value =>
  value ? { key: null, value } : null;

export const extractNumbersFromString = string => string.replace(/\D/g, '');

export default {
  overrideEventValue,
  toKeyValuePairOrNull,
};
