import {
  Declined,
  ThumbsUp,
} from '@heidi-pay/heidi-component-library/components';
import { HelpTooltip } from '@heidi-pay/heidi-component-library/components/HelpTooltip';
import {
  heyLightTypographyComponents,
  Subtitle,
} from '@heidi-pay/heidi-component-library/components/Typography';
import {
  Body,
  Notes,
  TypographyBaseProps,
} from '@heidi-pay/heidi-component-library/components/Typography/heyLightTypography';
import {
  BaseColour,
  HeyLightColour,
} from '@heidi-pay/heidi-component-library/enums';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Link, styled, Typography } from '@mui/material';
import { Size } from './enums';
import { FormatAmount, FormatDateDDMM } from './Formatters';

const InfoOutlinedStyled = styled(InfoOutlinedIcon)({
  fontSize: Size.Lg,
  marginLeft: '4px',
  marginBottom: '-5px',
});

export const AccentSpan = (
  <Typography
    color={HeyLightColour.Accent}
    component="span"
    fontSize="inherit"
  />
);

const SubtitleInline = (props: TypographyBaseProps<'h2'>) => (
  <Subtitle display="inline" {...props} />
);

interface IHelpTooltipInline {
  content?: string;
}

const HelpTooltipInline = ({ content }: IHelpTooltipInline) => (
  <HelpTooltip content={content} sx={{ ml: '3px', mr: '1px' }} />
);

export const typographyComponentsHeyLight = {
  ...heyLightTypographyComponents,
  accentSpan: AccentSpan,
  body2: <Typography component="span" variant="body2" />,
  formatAmount: <FormatAmount />,
  formatDateDDMM: <FormatDateDDMM />,
  greyNotes: <Notes color={BaseColour.Grey1} />,
  greyBody: <Body color={BaseColour.Grey1} display={'inline'} />,
  greySmallText: (
    <Typography color={BaseColour.Grey1} sx={{ fontSize: '0.75rem' }} />
  ),
  hyperlinkV2: (
    <Link fontWeight="bold" sx={{ cursor: 'pointer' }} underline="always" />
  ),
  info: <InfoOutlinedStyled />,
  paragraphNoStylingV2: <Typography component="p" variant="body1" />,
  rejectedIcon: <Declined />,
  sectionTitle: <Typography component="h2" mt="16px" variant="h5" />,
  spanBody: <Typography component="span" variant="body1" />,
  subtitleInline: <SubtitleInline />,
  successIcon: <ThumbsUp />,
  helpTooltip: <HelpTooltipInline />,
};
