import { Box, styled } from '@mui/material';

const StyledImg = styled('img')`
  max-width: 40%;
  height: auto;
  padding: 0;
  margin: 0;
`;

interface IImageContainerProps {
  alt: string;
  src: string;
}

export const ImageContainer = ({ alt, src }: IImageContainerProps) => (
  <Box pb="16px" pt="24px" textAlign="center">
    <StyledImg alt={alt} src={src} />
  </Box>
);
