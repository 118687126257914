import * as yup from 'yup';
import { Nullable } from '@appTypes';
import { addressValidationFields } from '../constants';
import { AddressFieldValue, IValidationField } from '../types';

const buildSchema = (
  fields: IValidationField[],
  erroringField?: Nullable<AddressFieldValue>,
) =>
  Object.fromEntries(
    fields.map(field => [
      field.name,
      yup
        .string()
        .required(`fields.addressContainer.subFields.${field.name}.required`)
        .test(
          'choice-validation',
          `fields.addressContainerSwissPost.subFields.${field.name}.invalid`,
          () => erroringField !== field.name,
        ),
    ]),
  );

export const getValidationSchema = (
  erroringField: Nullable<AddressFieldValue>,
) => yup.object().shape(buildSchema(addressValidationFields, erroringField));
