import { useState } from 'react';
import PropTypes from 'prop-types';
import CropFreeIcon from '@mui/icons-material/CropFree';
import { Box, IconButton, styled, useMediaQuery } from '@mui/material';
import { useTranslation } from '@hooks';
import { Dialog } from '../Dialog';
import { Iframe } from './components/Iframe';
import { getGoogleDriveViewerUri } from './utils/getGoogleDriveViewerUri';

const IFrameContainer = styled('div')`
  background-color: #fff;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  border: 1px solid #f7f7f7;
  margin-bottom: 0;
  padding: 0.5rem 0.75rem;
  svg {
    color: legacyGrey;
    height: 2rem;
    width: 2rem;
  }
  width: 100%;
`;

// This component is now deprecated and PDFDocumentViewer should be used instead
// Will be removed as soon as the backend starts using the new component
export const ExternalDocumentViewer = ({ name, options }) => {
  const {
    heightPx = 500,
    heightPxMobile = 300,
    uri: secciDocumentUri,
  } = options;

  const googleDriveSecciUri = getGoogleDriveViewerUri(secciDocumentUri);

  const { t } = useTranslation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width:600px)');

  const handleOpenDialog = () => setIsDialogOpen(true);

  const handleOnDialogClose = () => setIsDialogOpen(false);

  return (
    <IFrameContainer width="100%">
      <Box display="flex" justifyContent="end" pb="0.5rem">
        <IconButton
          aria-label={t('fields.externalDocumentViewer.fullScreenButton')}
          onClick={handleOpenDialog}
          sx={{ padding: 0 }}
        >
          <CropFreeIcon />
        </IconButton>
      </Box>
      {googleDriveSecciUri ? (
        <Iframe
          height={isMobile ? `${heightPxMobile}px` : `${heightPx}px`}
          src={googleDriveSecciUri}
          title="secci"
        />
      ) : null}
      <Dialog
        height="100%"
        isOpen={isDialogOpen}
        onClose={handleOnDialogClose}
        title={t(`fields.externalDocumentViewer.dialog.${name}`)}
      >
        {googleDriveSecciUri ? (
          <Iframe height="100%" src={googleDriveSecciUri} title="secci" />
        ) : null}
      </Dialog>
    </IFrameContainer>
  );
};

ExternalDocumentViewer.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.shape({
    heightPx: PropTypes.number,
    heightPxMobile: PropTypes.number,
    uri: PropTypes.string.isRequired,
  }).isRequired,
};
