import { Alert, FormControl, Box, Typography } from '@mui/material';
import { useTranslation } from '@hooks';

interface IPaymentMethodBlockOptions {
  labelKey: string;
}

interface IPaymentMethodBlockProps {
  options?: IPaymentMethodBlockOptions;
  value: string;
}

export const PaymentMethodBlock = ({
  options,
  value,
}: IPaymentMethodBlockProps) => {
  const { t } = useTranslation();
  return (
    <FormControl component="fieldset" fullWidth={true}>
      <Typography component="legend" fontWeight="bold" variant="subtitle1">
        {options?.labelKey
          ? t(`fields.paymentMethodBlock.${options.labelKey}`)
          : t('fields.paymentMethodBlock.label')}
      </Typography>
      <Box mt={2} pr={1}>
        <Alert severity="info" variant="outlined">
          <Box alignItems="center" display="flex">
            {t(`fields.paymentMethodBlock.${value}`)}
          </Box>
        </Alert>
      </Box>
    </FormControl>
  );
};
