import * as yup from 'yup';
import { DocumentValidationCountry } from './enums';
import { DocumentType } from './types';
import { documentTypeValidationMap } from './validators';

export interface ITextInputDocumentNumberOptions {
  documentNumberFieldName?: string;
  documentValidationCountry: string;
  labelOverride?: string;
}

export const validationSchema = (
  name: string,
  {
    documentNumberFieldName = 'documentType',
    documentValidationCountry,
    labelOverride,
  }: ITextInputDocumentNumberOptions,
) => {
  const schema =
    documentValidationCountry === DocumentValidationCountry.ITALY
      ? yup
          .string()
          .when(
            documentNumberFieldName,
            (documentType: DocumentType, currentSchema: yup.StringSchema) => {
              const validationFunction =
                documentTypeValidationMap[documentType];
              return validationFunction
                ? validationFunction(currentSchema, labelOverride)
                : currentSchema;
            },
          )
      : yup.string();

  return schema.required(
    `fields.textInputDocumentNumber.${
      labelOverride ? `${labelOverride}.` : ''
    }${name}.required`,
  );
};
