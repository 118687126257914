export const sortAndTranslateItemsBackwardCompatible = (
  options,
  name,
  translate,
  overrideKey,
) => {
  // ensuring the component can still render with the legacy options array
  // the options parameter will be changed to an object
  const { items, sorted = false } = options;
  const optionsItems = Array.isArray(options) ? options : items;
  const selectItems = optionsItems.map(option => ({
    value: option,
    text: translate(
      `fields.selectPicker.${
        overrideKey ? `${overrideKey}.` : ''
      }${name}.options.${option}`,
    ),
  }));

  if (sorted) {
    return selectItems.sort((a, b) => (a.text > b.text ? 1 : -1));
  }

  return selectItems;
};
