import { call } from '../../utils/http';
import {
  IAmount,
  ILoadPaymentSummary,
  IOrderProfile,
  IProductObject,
} from './types';

export async function loadPaymentSummary(
  amount: IAmount,
  maxNumberOfInstallments: number,
  orderProfileUuid: string,
  pricingStructureCode: string | null,
  products: IProductObject[],
  orderDiscountAmount: IAmount | null,
  depositOverrideAmount: IAmount | null,
) {
  const requestParameters = {
    ...amount,
    numberOfInstallments: JSON.stringify(maxNumberOfInstallments),
    ...(orderDiscountAmount?.amount
      ? { orderDiscountAmount: orderDiscountAmount.amount }
      : {}),
    ...(depositOverrideAmount?.amount
      ? { depositOverrideAmount: depositOverrideAmount.amount }
      : {}),
    orderProfileUuid,
    products: JSON.stringify(products),
    ...(pricingStructureCode ? { pricingStructureCode } : {}),
  };

  const requestQuery = new URLSearchParams(requestParameters).toString();
  return call<ILoadPaymentSummary>(
    `/api/checkout-client/instore/summary/?${requestQuery}`,
    { disableRetry: true },
  );
}

export async function loadAvailableOrderProfiles(
  amount: IAmount,
  products: IProductObject[],
  isManualAgreementSigning: boolean | null,
  orderDiscountAmount: IAmount | null,
  pricingStructureCode: string | null,
) {
  const requestParameters = {
    amount: amount.amount,
    currency: amount.currency,
    ...(isManualAgreementSigning != null
      ? { isManualAgreementSigning: isManualAgreementSigning?.toString() }
      : {}),
    ...(orderDiscountAmount?.amount
      ? { orderDiscountAmount: orderDiscountAmount.amount }
      : {}),
    products: JSON.stringify(products),
    ...(pricingStructureCode ? { pricingStructureCode } : {}),
  };

  const requestQuery = new URLSearchParams(requestParameters).toString();
  return call<IOrderProfile[]>(
    `/api/checkout-client/instore/order-profile-filter/?${requestQuery}`,
    { disableRetry: true },
  );
}

export async function getMerchantProduct(sku: string) {
  return call(`/api/checkout-client/merchant-product/?sku=${sku}`);
}

export async function getMerchantProducts() {
  return call('/api/checkout-client/merchant-products-list/');
}

export async function getMerchantUserOrders() {
  return call('/api/checkout-client/merchant-user-orders');
}

export async function getMerchantUserOrderDetails(uuid: string) {
  return call(
    `/api/checkout-client/merchant-user-order-details?application_uuid=${uuid}`,
  );
}
