export const mapFormValuesToAddressFields = data =>
  Object.keys(data).reduce((previous, current) => {
    const currentValue = data[current];
    return { ...previous, [current]: currentValue?.value ?? currentValue };
  }, {});

export const mapOcrFormValuesToAddressFields = data =>
  Object.keys(data).reduce((previous, current) => {
    const currentValue = data[current];
    return {
      ...previous,
      [current]: {
        value: currentValue?.value ?? currentValue,
        name: currentValue?.displayValue,
      },
    };
  }, {});

export default {
  mapFormValuesToAddressFields,
  mapOcrFormValuesToAddressFields,
};
