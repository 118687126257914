import React from 'react';
import { ProgressBar } from '@heidi-pay/heidi-component-library/components';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

interface IHoldingPage {
  children: React.ReactNode;
}

export const HoldingPage = ({ children }: IHoldingPage) => {
  return (
    <FormControl
      className="notranslate"
      component="fieldset"
      fullWidth={true}
      size="small"
    >
      <Box alignItems="center" display="flex" flexDirection="column">
        {children}
      </Box>
      <ProgressBar
        isRounded={true}
        sx={{ mt: '20px' }}
        variant="indeterminate"
      />
    </FormControl>
  );
};
