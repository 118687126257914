import { BaseColour } from '@heidi-pay/heidi-component-library/enums';
import { Typography, styled } from '@mui/material';

const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflowY: 'auto',
  marginBottom: '30px',
});

interface ISignatureListContentProps {
  currentSignature?: {
    textContent?: string;
  };
}

export const SignatureListContent = ({
  currentSignature,
}: ISignatureListContentProps) => (
  <ContentContainer>
    <Typography color={BaseColour.Grey1} variant="caption">
      {currentSignature?.textContent}
    </Typography>
  </ContentContainer>
);
