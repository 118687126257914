import { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { TableContainer, Table, TableBody } from '@mui/material';
import { PaymentRow, ScheduleTable } from './components';
import { RowType } from './utils';

export const PaymentPlanTable = ({ payNow, rows, schedule }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isSchedule = payNow && schedule && isExpanded;

  const handleToggle = useCallback(
    () => setIsExpanded(prev => !prev),
    [setIsExpanded],
  );

  return (
    <TableContainer>
      <Table size="small">
        <TableBody>
          {rows.map(row => (
            <Fragment key={row.label}>
              <PaymentRow
                currency={row.currency}
                displayLabelBold={row.displayLabelBold}
                displayValueBold={row.displayValueBold}
                isExpanded={isExpanded}
                isLabelOnly={row.isLabelOnly}
                isSmallFont={row.isSmallFont}
                key={row.label}
                label={row.label}
                rowType={row.rowType}
                toggleExpanded={handleToggle}
                tooltip={row.tooltip}
                value={row.value}
              />
              {row.rowType === RowType.scheduleToggle && isSchedule ? (
                <ScheduleTable
                  currency={row.currency}
                  hideTitle={row.hideScheduleTitle}
                  payNow={payNow}
                  schedule={schedule}
                />
              ) : null}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PaymentPlanTable.propTypes = {
  payNow: PropTypes.shape(),
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  schedule: PropTypes.arrayOf(PropTypes.shape()),
};

PaymentPlanTable.defaultProps = {
  payNow: null,
  schedule: null,
};
