import { FormLabel, styled } from '@mui/material';

declare module '@mui/material/FormLabel' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface FormLabelPropsColorOverrides {
    inherit: true;
  }
}

interface IFormLabelStyled {
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'inherit';
  fontSize?: string;
}

export const FormLabelStyled = styled(FormLabel)<IFormLabelStyled>(
  ({ color, fontSize }: IFormLabelStyled) => ({
    fontSize: fontSize ?? '0.875rem',
    color: color ?? '',
    '& button, a': {
      fontWeight: '400',
      lineHeight: '1.25',
      minHeight: 0,
      textDecoration: 'underline',
      whiteSpace: 'nowrap',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  }),
);

interface IFlexContainer {
  alignItems?: 'center' | 'end' | 'start' | 'stretch';
  flexDirection?: 'row' | 'column';
  justifyContent?: 'center' | 'space-arount' | 'space-between' | 'space-evenly';
}

export const FlexContainer = styled('div')(
  ({ alignItems, flexDirection, justifyContent }: IFlexContainer) => ({
    display: 'flex',
    alignItems: alignItems || 'start',
    flexDirection: flexDirection || 'column',
    justifyContent: justifyContent || 'start',
    marginTop: '0.25rem',
  }),
);
