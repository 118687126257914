import { BaseColour } from '@heidi-pay/heidi-component-library/enums';
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from '@mui/material';
import { useTranslation } from '@hooks';
import { CopyBlock } from '../../../../../components/CopyBlock';

interface IStepsHeyLight {
  icon: React.ReactNode;
  labelKey: string;
  subLabelKey?: string;
}

interface IStepListProps {
  iconBackgroundColour?: string;
  steps: IStepsHeyLight[];
  titleTranslationKey: string;
}

export const StepsList = ({
  iconBackgroundColour,
  steps,
  titleTranslationKey,
}: IStepListProps) => {
  const { t } = useTranslation();
  return (
    <Box py=".5rem">
      <CopyBlock i18nKey={titleTranslationKey} />
      <List>
        {steps.map(({ icon, labelKey, subLabelKey }) => (
          <ListItem
            key={labelKey}
            sx={{ paddingLeft: 0, marginTop: '30px', paddingTop: 0 }}
          >
            <ListItemAvatar sx={{ alignSelf: 'flex-start' }}>
              <Avatar
                sx={{
                  bgcolor: iconBackgroundColour || 'transparent',
                  marginTop: '-2px',
                  paddingTop: 0,
                }}
              >
                {icon}
              </Avatar>
            </ListItemAvatar>
            <Box sx={{ whiteSpace: 'pre-line' }}>
              <Typography variant="h6">{t(labelKey)}</Typography>
              {subLabelKey ? (
                <Typography
                  color={BaseColour.Grey1}
                  marginTop={'12px'}
                  variant="body1"
                >
                  {t(subLabelKey)}
                </Typography>
              ) : null}
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
