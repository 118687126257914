import { TableCell, styled } from '@mui/material';

export const Cell = styled(TableCell, {
  shouldForwardProp: prop => !['boldText', 'verticalAlign'].includes(prop),
})`
  font-size: 1rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  font-weight: ${props => (props.boldText ? 'bold' : 'normal')};
  vertical-align: ${props => props.verticalAlign || 'inherit'};
  border-bottom: none;

  &:last-child {
    padding-right: 0;
  }
`;

export const Figure = styled('figure', {
  shouldForwardProp: prop =>
    !['color', 'fontSize', 'fullWidth', 'textAlign'].includes(prop),
})`
  color: ${props => props.color || 'inherit'};
  font-size: ${props => props.fontSize || '0.875rem'};
  margin: 0;
  text-align: ${props => props.textAlign || 'start'};
  width: 100%;
`;

export const Figcaption = styled('figcaption')`
  margin: 0.25rem 0.625rem;
`;

export const LoadingBackdropContainer = styled('div', {
  shouldForwardProp: prop => !['contained'].includes(prop),
})`
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7);
  position: ${props => (props.contained ? 'absolute' : 'fixed')};
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  -webkit-tap-highlight-color: transparent;

  & > span > svg.MuiCircularProgress-svg {
    color: #c4c4c4;
  }
`;
