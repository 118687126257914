export const Separators = Object.freeze({
  decimal: 'decimal',
  group: 'group',
});

const separatorsByLocale = {
  'en-gb': [',', '.'],
  'it-it': ['.', ','],
  'de-ch': ['’', '.'],
  'en-ch': ['’', '.'],
  'fr-ch': ['\u202F', ','],
  'it-ch': ['’', '.'],
};

export const getAmountSeparatorsByLocale = (locale, separatorType) => {
  const [groupSeparator, decimalSeparator] = separatorsByLocale[
    locale.toLowerCase()
  ] ?? [',', '.'];
  const separatorTypes = {
    [Separators.group]: groupSeparator,
    [Separators.decimal]: decimalSeparator,
  };
  return separatorTypes[separatorType] ?? '';
};
