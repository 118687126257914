import {
  Country,
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input';

export interface ICountryOption {
  callingCode: string;
  code: Country;
  name: string;
}

export const generateCallingCodes = (
  locale: string,
  countryTranslation: (code: Country) => string,
): ICountryOption[] => {
  const countries = getCountries();
  return countries
    .map(code => ({
      code,
      name: countryTranslation(code),
      callingCode: getCountryCallingCode(code),
    }))
    .sort((a, b) => a.name.localeCompare(b.name, locale.replace('_', '-')));
};
