import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { useStatusPolling } from '@hooks';
import { getIsLoading } from '@redux';
import { checkIntrumSigningStatus } from '../../../services/IntrumStatusService';
import { LoadingBackdropContainer } from '../Styled';
import { IBaseComponent, IComponentBaseOptions } from '../types';

export const IntrumSigningHoldingContainer = ({
  submit,
}: IBaseComponent<IComponentBaseOptions, unknown>) => {
  const isSubmitting = useSelector(getIsLoading);

  useStatusPolling({
    isSubmitting,
    onComplete: submit,
    pollingFunction: checkIntrumSigningStatus,
    retryIntervalMs: 5000,
  });

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <LoadingBackdropContainer contained={true}>
      <CircularProgress aria-label="loading" />
    </LoadingBackdropContainer>
  );
};
