import { Translate } from '@heidi-pay/heidi-common-fe/types';
import { IPaymentRowProps } from '@heidi-pay/heidi-component-library/components/PaymentPlanTable';
import { IUseFormatter } from '../../../../../utils/formatters/types';
import { IScheduleData } from './types';

export const getItalyCreditInstorePaymentPlanFields = (
  {
    currency,
    initialBalance,
    interest,
    numberOfInstallments,
    payMonthly,
  }: IScheduleData,
  formatters: IUseFormatter,
  translate: Translate,
): IPaymentRowProps[] | null => {
  if (!currency) {
    return null;
  }

  const { formatAmountWithCurrency, formatPercentage } = formatters;
  const { apr, nominalInterestRate } = interest;
  const baseKey = 'fields.paymentPlanSwissCredit.pagolightPro';

  return [
    {
      displayLabelBold: true,
      displayValueBold: true,
      label: translate(`${baseKey}.payMonthly`),
      value: formatAmountWithCurrency(payMonthly, currency),
    },
    {
      displayLabelBold: true,
      displayValueBold: true,
      label: translate(`${baseKey}.nominalInterestRate`),
      value: formatPercentage(nominalInterestRate),
    },
    {
      displayLabelBold: true,
      displayValueBold: true,
      label: translate(`${baseKey}.apr`),
      value: formatPercentage(apr),
    },
    {
      displayLabelBold: true,
      displayValueBold: true,
      label: translate(`${baseKey}.total`),
      value: formatAmountWithCurrency(initialBalance, currency),
    },
    {
      displayLabelBold: true,
      displayValueBold: true,
      label: translate(`${baseKey}.numberOfInstallments`),
      value: numberOfInstallments,
    },
  ];
};
