import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@heidi-pay/heidi-component-library/components/Dialog';
import { Box } from '@mui/material';
import { IStringMap } from '@appTypes';
import { IBaseComponent } from '@transaction/components/types';
import { ComplianceElementComponent } from './components/ComplianceElementComponent';
import { getComplianceDetails } from './content/getComplianceContainerContent';

export const ComplianceContainerReadonly = ({
  name,
  value = {},
}: IBaseComponent<null, IStringMap | undefined>) => {
  const { t } = useTranslation('compliance');

  const [dialogId, setDialogId] = useState<string>();

  const handleOnClose = useCallback(() => setDialogId(undefined), []);

  const handleDialogOpen = useCallback(
    (dialogIdParam?: string) => () => setDialogId(dialogIdParam),
    [],
  );

  const { dialogContent = [], formContent } = getComplianceDetails(name);
  const { complianceElements = [], title } =
    dialogContent.find(x => x.id === dialogId) ?? {};

  const Title = title ? t(title.translationKey) : null;

  return (
    <>
      <Box lineHeight="20px" marginY="20px">
        {formContent.map(element => (
          <ComplianceElementComponent
            element={element}
            key={element.translationKey}
            onDialogLinkClick={handleDialogOpen(element.dialogId)}
            translationInterpolatedValues={value}
          />
        ))}
      </Box>
      <Dialog isOpen={!!dialogId} onCloseClick={handleOnClose} title={Title}>
        {complianceElements.map(element => (
          <ComplianceElementComponent
            element={element}
            key={element.translationKey}
            translationInterpolatedValues={value}
          />
        ))}
      </Dialog>
    </>
  );
};
