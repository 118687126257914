export const instoreSalesGuides = [
  {
    lang: 'de',
    url: 'https://storage.googleapis.com/heidi-media-prod/Ha%CC%88ndler%20Guide.pdf',
  },
  {
    lang: 'en',
    url: 'https://storage.googleapis.com/heidi-media-prod/Merchant%20guide.pdf',
  },
  {
    lang: 'fr',
    url: 'https://storage.googleapis.com/heidi-media-prod/Guide%20vendeur.pdf',
  },
  {
    lang: 'it',
    url: 'https://storage.googleapis.com/heidi-media-prod/Guida%20venditore.pdf',
  },
];

const defaultHelpGuideUrl = instoreSalesGuides.find(x => x.lang === 'en').url;

export const getInstoreSalesGuideUrl = locale =>
  instoreSalesGuides.find(x => locale.startsWith(x.lang))?.url ??
  defaultHelpGuideUrl;

export const openInstoreSalesGuide = locale => {
  window.open(getInstoreSalesGuideUrl(locale), '_blank');
};
