import { Box, styled } from '@mui/material';
import { Children } from '@appTypes';
import { ContentWrapper } from './components/heyLight';

const MainContentStyled = styled(Box)({
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
});

interface IMainContent {
  children: Children;
}

export const MainContent = ({ children }: IMainContent) => (
  <MainContentStyled component="section" pt={1}>
    <ContentWrapper>{children}</ContentWrapper>
  </MainContentStyled>
);
