import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from '@hooks';

export default function TextInput({
  autoComplete,
  dataType,
  error,
  hidden = false,
  inputRef,
  name,
  onBlur,
  onChange,
  value,
}) {
  const { t } = useTranslation();

  let inputProps = {
    autoComplete,
  };

  let type = dataType;
  if (type === 'number') {
    type = 'text';
    inputProps = {
      autoComplete,
      inputMode: 'numeric',
      pattern: '[0-9]*',
    };
  }

  return (
    <TextField
      error={!!error}
      fullWidth={true}
      id={name}
      InputLabelProps={{ shrink: value !== '' }}
      inputProps={inputProps}
      inputRef={inputRef}
      label={t(`fields.addressContainer.subFields.${name}.label`, {
        disableKeyCheck: hidden,
      })}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      size="small"
      style={hidden ? visuallyHidden : {}}
      type={type}
      value={value}
      variant="outlined"
    />
  );
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.string,
  hidden: PropTypes.bool,
  inputRef: PropTypes.shape(),
  dataType: PropTypes.string,
  autoComplete: PropTypes.string,
};

TextInput.defaultProps = {
  value: '',
  error: '',
  inputRef: undefined,
  dataType: 'text',
  autoComplete: undefined,
  hidden: false,
};
