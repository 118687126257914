import {
  Amex,
  Building,
  Card,
  Email,
  Mastercard,
  Rejection,
  Tick,
  Visa,
} from '@heidi-pay/heidi-component-library/components';
import {
  BaseColour,
  HeyLightColour,
} from '@heidi-pay/heidi-component-library/enums';

import {
  PagolightBankIcon,
  PagolightEmailIcon,
  PagolightShoppingBagIcon,
} from './pagolight';

export const pagolightIcons = {
  pagolightBankIcon: <PagolightBankIcon />,
  pagolightEmailIcon: <PagolightEmailIcon />,
  pagolightShoppingBagIcon: <PagolightShoppingBagIcon />,
};

export const heyLightIcons = {
  card: <Card />,
  email: <Email />,
  building: <Building />,

  // Card Icons
  amex: <Amex />,
  mastercard: <Mastercard />,
  visa: <Visa />,

  // Swiss Credit Pre Approval Icons
  smallTickIcon: <Tick color={HeyLightColour.BrandAccentsGreen} />,
  smallRejectionIcon: <Rejection color={BaseColour.Error} />,
};

export const icons: { [key: string]: JSX.Element } = {
  ...pagolightIcons,
  ...heyLightIcons,
};
