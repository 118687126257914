/* eslint-disable react/jsx-no-bind */
import { useCallback, useState } from 'react';
import * as yup from 'yup';
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  MenuItem,
  styled,
  Typography,
} from '@mui/material';
import { useTranslation } from '@hooks';
import { FormatterType } from '@utils';
import { formatAmountIfExists } from '@utils/amounts';
import { CopyBlock } from '../../../../components/CopyBlock';
import { SelectPicker } from '../SelectPicker';
import { TextBlockValue } from '../TextBlockValue';
import { ImageContainer } from './components/ImageContainer';
import { ProductMenuItem } from './ProductMenuItem';
import { IProductSelectProps } from './types';

const ProductsFormControl = styled(FormControl)`
  .MuiSelect-select {
    display: flex;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 15px;
    padding-top: 15px;
    font-size: 18px;
    line-height: 30px;

    > div {
      &.MuiListItemIcon-root {
        display: none;
      }

      .MuiTypography-body1 {
        font-size: 18px;
      }
    }
  }
`;

export const ProductSelect = ({
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options = [],
  setValue,
  value,
}: IProductSelectProps) => {
  const { i18n, t } = useTranslation();
  const locale = i18n.language;
  const initialProduct = options.find(option => option.uuid === value);
  const [selectedProduct, setSelectedProduct] = useState(initialProduct);

  const handleChange = useCallback(
    event => {
      onChange(event);
      setSelectedProduct(options.find(option => option.uuid === event));
    },
    [onChange, options],
  );

  const productOptions = options.map(
    ({ imageUrl, name: productName, price, uuid }) => ({
      value: uuid,
      text: `${productName} ${formatAmountIfExists(price, locale)}`,
      image: imageUrl,
    }),
  );

  // for now, only show native when there are images in the product list,
  // we may decide to switch this on for everyone at some point
  const native = !options.some(option => option.imageUrl);

  const selectPickerOptions = {
    items: productOptions,
  };

  return (
    <ProductsFormControl
      error={!!error}
      fullWidth={true}
      size="small"
      variant="outlined"
    >
      <SelectPicker
        complete={!!value}
        displayRawValues={true}
        error={error}
        hasEmptyOption={false} // The empty option comes from BE with a blank uuid
        inputRef={inputRef}
        label={t(`fields.productSelect.${name}.label`)}
        name={name}
        native={native}
        onBlur={onBlur}
        onChange={handleChange}
        options={native ? selectPickerOptions : undefined}
        setValue={setValue}
        value={value}
      >
        {native
          ? null
          : selectPickerOptions.items.map(product => (
              <MenuItem key={product.value} value={product.value}>
                <ProductMenuItem product={product} />
              </MenuItem>
            ))}
      </SelectPicker>
      {selectedProduct?.description ? (
        <Typography sx={{ marginTop: '10px', marginLeft: '16px' }}>
          {selectedProduct.description}
        </Typography>
      ) : null}
      <Collapse in={!!selectedProduct?.priceExcludingResidualValue}>
        <CopyBlock i18nKey="fields.textBlock.mobilezonePaymentLink.priceNoticeMobilezone.content" />
        <Divider sx={{ marginTop: '1.20rem' }} />
        <Box my="1.25rem">
          <TextBlockValue
            name="priceWithoutResidualValue"
            options={{
              currency: selectedProduct?.priceExcludingResidualValue?.currency,
              fontSizeOverride: '18px',
              format: FormatterType.AMOUNT_WITH_CURRENCY,
              large: true,
              spacing: 'small',
            }}
            value={selectedProduct?.priceExcludingResidualValue?.amount}
          />
          <CopyBlock i18nKey="fields.textBlock.paymentLinkFlexPriceNotice.content" />
        </Box>
        <Divider />
      </Collapse>
      <Collapse in={!!selectedProduct?.imageUrl}>
        <ImageContainer
          alt={selectedProduct?.name ?? ''}
          src={selectedProduct?.imageUrl ?? ''}
        />
        <Divider />
      </Collapse>
      {selectedProduct?.name ? (
        <CopyBlock i18nKey="fields.textBlock.mobilezonePaymentLink.priceNotice.content" />
      ) : null}
    </ProductsFormControl>
  );
};

ProductSelect.validation = () =>
  yup.string().required('fields.productSelect.required');
