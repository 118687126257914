import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  setIsManualSigning,
  setOrderDiscountAmount,
  setDepositOverrideAmount,
} from '@instore/redux/index';
import { FieldName } from '../../../enums';
import { IField } from '../../types';

export const ManualSignatureValue = 'PHYSICAL';

export const useFormToReduxLink = (
  fields: IField[],
  watch: (name: string) => unknown,
) => {
  const agreementSigningValue = watch(FieldName.AgreementSigningMethod);
  const orderDiscountAmount = watch(FieldName.OrderDiscountAmount);
  const depositOverrideAmount = watch(FieldName.DepositOverrideAmount);

  const shouldSyncAgreementSigningValue = fields.some(
    field => field.name === FieldName.AgreementSigningMethod,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldSyncAgreementSigningValue) {
      dispatch(
        setIsManualSigning(agreementSigningValue === ManualSignatureValue),
      );
    }
  }, [agreementSigningValue, dispatch, shouldSyncAgreementSigningValue]);

  useEffect(() => {
    dispatch(setOrderDiscountAmount(orderDiscountAmount));
  }, [orderDiscountAmount, dispatch]);

  useEffect(() => {
    dispatch(setDepositOverrideAmount(depositOverrideAmount));
  }, [depositOverrideAmount, dispatch]);
};
