import { InputFieldAdornment } from '@heidi-pay/heidi-component-library/components/InputFieldAdornment';
import { CircularProgress } from '@mui/material';

export const ChainedAutocompleteIcon = ({
  hasError,
  isComplete,
  isDisabled,
  isLoading,
}) => {
  if (isLoading) {
    return <CircularProgress size="25px" />;
  }

  return (
    <InputFieldAdornment
      isComplete={isComplete}
      isDisabled={isDisabled}
      isError={hasError}
    />
  );
};
