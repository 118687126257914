import { CompassRed, ISvgIconProps } from './types';

export const SmartPhoneRedActive = ({ colour = CompassRed }: ISvgIconProps) => (
  <svg
    height="48px"
    id="smartphone-red-active"
    version="1.1"
    viewBox="0 0 48 48"
    width="48px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Smartphone red active</title>
    <g
      fill="none"
      fillRule="evenodd"
      id="smartphone-red-active-g"
      stroke="none"
      strokeWidth="1"
    >
      <path
        // eslint-disable-next-line max-len
        d="M32,0 C34.209139,0 36,1.790861 36,4 L36.0012617,18.2538308 C39.0934862,21.4100616 41,25.7323857 41,30.5 C41,40.1649831 33.1649831,48 23.5,48 C13.8350169,48 6,40.1649831 6,30.5 C6,25.7319493 7.90686288,21.4092703 10.9995875,18.2529642 L11,4 C11,1.790861 12.790861,0 15,0 L32,0 Z M18,2 L15,2 C13.8954305,2 13,2.8954305 13,4 L13.0001081,41.9019646 C15.7614528,44.4461403 19.4492647,46 23.5,46 C27.5512246,46 31.239438,44.4457649 34.0008925,41.9010426 L34,4 C34,2.8954305 33.1045695,2 32,2 L29,2 L29,3 C29,3.55228475 28.5522847,4 28,4 L19,4 C18.4477153,4 18,3.55228475 18,3 L18,2 Z M8,30.5 C8,33.9300517 9.11415447,37.0998786 11.0003043,39.6673217 L10.9999783,21.3331221 C9.11402608,23.9004867 8,27.070146 8,30.5 Z M36.0007045,21.3340516 L36.0011668,39.6653189 C37.8864248,37.0982302 39,33.9291598 39,30.5 C39,27.0705599 37.8862428,23.9012518 36.0007045,21.3340516 Z M23.5,16 C27.6421356,16 31,19.3578644 31,23.5 C31,27.6421356 27.6421356,31 23.5,31 C19.3578644,31 16,27.6421356 16,23.5 C16,19.3578644 19.3578644,16 23.5,16 Z M26.7928932,20.7928932 L22.9922187,24.592 L21.1491808,22.78582 C20.7547501,22.3992416 20.1216171,22.4056072 19.7350387,22.8000379 C19.3484603,23.1944687 19.354826,23.8276017 19.7492567,24.21418 L22.3000379,26.71418 C22.6916675,27.098013 23.3193559,27.0948577 23.7071068,26.7071068 L28.2071068,22.2071068 C28.5976311,21.8165825 28.5976311,21.1834175 28.2071068,20.7928932 C27.8165825,20.4023689 27.1834175,20.4023689 26.7928932,20.7928932 Z"
        fill={colour}
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);
