import * as Sentry from '@sentry/react';
import env from '../../env';
import { SentrySeverity } from '../../utils';
import { TranslationNotFoundError } from './TranslationNotFoundError';

interface ICheckKeyHasTranslation {
  key: string | string[];
  lang: string;
  translation: string;
}

export const missingKeyCache = new Map();

// when the language has not been initialised yet, it will fall back to 'en'
// we can use this to check when the correct language has been set initially
// without the need for accessing the redux store, which complicates a large number of tests
const isFallbackLanguage = (lang: string) => lang === 'en';

export const checkKeyHasTranslation = ({
  key,
  lang,
  translation,
}: ICheckKeyHasTranslation) => {
  const cacheKey = `${key}_${lang}`;

  if (
    !isFallbackLanguage(lang) &&
    translation === key &&
    !missingKeyCache.has(cacheKey)
  ) {
    const error = new TranslationNotFoundError(key, lang);

    // Only enable logging in sbx and prod to avoid E2E spamming the logs when testing pagolight which is IT only
    if (['prod', 'sandbox'].includes(env.SENTRY_ENV)) {
      Sentry.captureException(error, {
        level: SentrySeverity.warning,
      });
    }
    missingKeyCache.set(cacheKey, true);
  }
};
