import { useEffect, useState } from 'react';

const getRemainingTimeSections = endDate => {
  const remaining = Date.parse(endDate) - Date.parse(new Date());
  const isComplete = remaining <= 0;

  if (isComplete) {
    return {
      isComplete,
      sections: { hours: 0, minutes: 0, seconds: 0 },
    };
  }

  const seconds = Math.floor((remaining / 1000) % 60);
  const minutes = Math.floor((remaining / 1000 / 60) % 60);
  const hours = Math.floor(remaining / 1000 / 60 / 60);

  return {
    isComplete,
    sections: { hours, minutes, seconds },
  };
};

export const useCountdownTimer = ({ endDate }) => {
  const [timer, setTimer] = useState(getRemainingTimeSections(endDate));

  useEffect(() => {
    if (!timer.isComplete) {
      const interval = setInterval(() => {
        setTimer(getRemainingTimeSections(endDate));
      }, 1000);

      return () => clearInterval(interval);
    }
    return null;
  }, [endDate, timer]);

  return timer;
};
