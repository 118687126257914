import * as yup from 'yup';
import {
  ISelectInputItem,
  SelectInput,
} from '@heidi-pay/heidi-component-library/components';
import { FormControl, FormHelperText } from '@mui/material';
import { useTranslation } from '@hooks';
import { StackedLabel } from '../../StackedLabel';
import { INumberPicker } from './types';

export const NumberPicker = ({
  error = '',
  inputRef,
  label,
  name,
  onBlur,
  onChange,
  options,
  value,
}: INumberPicker) => {
  const {
    disabled,
    max,
    min,
    overrideKey,
    sortDesc,
    tooltip,
    withStackedLabel,
  } = options;
  const formattedOverrideKey = overrideKey ? `${overrideKey}.` : '';

  const { t } = useTranslation();
  const displayLabel = t(
    label ?? `fields.numberPicker.${formattedOverrideKey}${name}.label`,
  );

  const selectItems: ISelectInputItem[] = Array.from(
    { length: max - min + 1 },
    (_, i) => {
      const num = sortDesc ? max - i : min + i;
      return {
        text: num.toString(),
        value: num.toString(),
      };
    },
  );

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ mt: '20px' }}
      variant="outlined"
    >
      {withStackedLabel ? (
        <StackedLabel label={displayLabel} name={name} tooltip={tooltip} />
      ) : null}
      <SelectInput
        complete={!!value}
        disabled={disabled}
        hasError={!!error}
        id={name}
        inputRef={inputRef}
        label={withStackedLabel ? undefined : displayLabel}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        selectItems={selectItems}
        sx={{ marginTop: '10px' }}
        value={value ?? ''}
      />
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
};

NumberPicker.validation = () =>
  yup.string().required('fields.selectPicker.required');
