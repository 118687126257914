import { func, shape, string } from 'prop-types';
import * as yup from 'yup';
import { FormControl, FormHelperText } from '@mui/material';
import { useTranslation } from '@hooks';
import { CopyBlock } from '../../../components';
import { ButtonGroup } from './components/ButtonGroup';
import { FlexContainer, FormLabelStyled } from './components/Styled';

export const TermsAndConditionsButtonGroupPicker = ({
  error,
  onChange,
  options,
  value,
}) => {
  const { t } = useTranslation();

  const { items } = options;

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      variant="outlined"
    >
      <FlexContainer
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
      >
        <FormLabelStyled
          color="inherit"
          fontSize="1rem"
          htmlFor="termsAndConditions"
        >
          <CopyBlock
            i18nKey="fields.buttonGroupPicker.termsAndConditions.label"
            props={options}
            sizeLarge={true}
          />
        </FormLabelStyled>
        <ButtonGroup
          name="termsAndConditions"
          onChange={onChange}
          options={items}
          value={value}
        />
      </FlexContainer>
      {error ? <FormHelperText>{t(error)}</FormHelperText> : null}
    </FormControl>
  );
};

TermsAndConditionsButtonGroupPicker.propTypes = {
  value: string.isRequired,
  options: shape().isRequired,
  onChange: func.isRequired,
  error: string,
};

TermsAndConditionsButtonGroupPicker.defaultProps = {
  error: null,
};

TermsAndConditionsButtonGroupPicker.validation = () =>
  yup.string().required('fields.buttonGroupPicker.termsAndConditions.required');
