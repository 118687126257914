import { bool } from 'prop-types';
import { Alert, Box, Typography } from '@mui/material';
import { useTranslation } from '@hooks';
import { MerchantRedirectLink } from '../../../../components/MerchantRedirectLink';
import { HourGlassLoader } from './HourGlassLoader';

export const Feedback = ({ isFailedToExecute }) => {
  const { t } = useTranslation();

  return (
    <Box mt={5}>
      {isFailedToExecute ? (
        <Alert severity="error" sx={{ mt: 2 }} variant="outlined">
          <Typography mb={1} variant="body2">
            {t('fields.introPagolightPro.alert')}
          </Typography>
          <MerchantRedirectLink />
        </Alert>
      ) : (
        <HourGlassLoader heightInPx={52} />
      )}
    </Box>
  );
};

Feedback.propTypes = {
  isFailedToExecute: bool.isRequired,
};
