import { bool, func, shape, string } from 'prop-types';
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@mui/material';
import { useTranslation } from '@hooks';
import { PdfTile } from './PdfTile';

export const ElectronicSignatureBlock = ({
  electronicSignatureUri,
  error,
  onChecked,
  onOpened,
  value,
}) => {
  const { t } = useTranslation();

  const handleChecked = event => {
    onChecked(event.target.checked);
  };

  return (
    <FormControl
      error={!!error?.message}
      fullWidth={true}
      sx={{ marginBottom: 4 }}
    >
      <Alert severity="info" variant="outlined">
        <Typography fontWeight="bold" mb={2} textAlign="left">
          {t(
            'fields.customerAgreementPagolightPro.electronicSignatureBlock.subtitle',
          )}
        </Typography>

        <PdfTile
          documentUri={electronicSignatureUri}
          label={t(
            'fields.customerAgreementPagolightPro.electronicSignatureBlock.titleLabel',
          )}
          onOpened={onOpened}
        />

        <FormControlLabel
          control={<Checkbox checked={value} onChange={handleChecked} />}
          label={t(
            'fields.customerAgreementPagolightPro.electronicSignatureBlock.checkboxLabel',
          )}
          sx={{ marginTop: 2 }}
        />
      </Alert>

      <FormHelperText>{t(error?.message)}</FormHelperText>
    </FormControl>
  );
};

ElectronicSignatureBlock.propTypes = {
  value: bool.isRequired,
  onChecked: func.isRequired,
  onOpened: func.isRequired,
  error: shape(),
  electronicSignatureUri: string.isRequired,
};

ElectronicSignatureBlock.defaultProps = {
  error: {},
};
