import { useCallback, useEffect, useState } from 'react';
import {
  Building,
  Card,
  QrCode,
  ToggleButtonList,
} from '@heidi-pay/heidi-component-library/components';
import { Icon } from '@heidi-pay/heidi-component-library/components/Buttons/ToggleButtonList/types';
import { BaseColour } from '@heidi-pay/heidi-component-library/enums';
import { Box, FormControl, styled } from '@mui/material';
import { useTranslation } from '@hooks';
import { CopyBlock } from '../../../../components/CopyBlock';
import { PaymentMethod } from '../../../../enums';

const NotificationContainer = styled(Box)(({ theme }) => ({
  background: BaseColour.GreenLight,
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  marginTop: '10px',
  alignSelf: 'center',
}));

interface IToggleButtonsListOptions {
  items: string[];
  translationAttributes?: Record<string, string>;
}

interface IToggleButtonsList {
  name: string;
  onChange: (updatedValue: string) => void;
  options: IToggleButtonsListOptions;
  value?: string;
}

export const ToggleButtonsList = ({
  name,
  onChange,
  options,
  value,
}: IToggleButtonsList) => {
  const { items, translationAttributes } = options;
  const { t } = useTranslation();
  const [internalValue, setInternalValue] = useState(value);

  // Pre-select the payment method to the first option
  useEffect(() => {
    if (!internalValue && items.length > 0) {
      setInternalValue(items[0]);
      onChange(items[0]);
    }
  }, [internalValue, items, onChange]);

  const handleChange = useCallback(
    (updatedValue: string) => {
      setInternalValue(updatedValue);
      onChange(updatedValue);
    },
    [onChange],
  );

  const iconMapping: Record<string, Icon> = {
    [PaymentMethod.DirectDebit]: Building,
    [PaymentMethod.Card]: Card,
    [PaymentMethod.SwissQr]: QrCode,
  };

  return (
    <FormControl
      component="fieldset"
      fullWidth={true}
      size="small"
      variant="outlined"
    >
      <ToggleButtonList
        minHeight="65px"
        onClick={handleChange}
        options={items.map(item => {
          const IconComponent = iconMapping[item];
          return {
            key: item,
            icon: IconComponent,
            label: t(`fields.toggleButtonsList.${name}.options.${item}.label`),
            subLabel: t(
              `fields.toggleButtonsList.${name}.options.${item}.subLabel`,
              { ...translationAttributes },
            ),
          };
        })}
        sx={{ whiteSpace: 'pre-line' }}
        toggleButtonListName="select-payment-method-togglelist"
        value={internalValue}
      />
      {internalValue === PaymentMethod.SwissQr ? (
        <NotificationContainer>
          <CopyBlock
            i18nKey={`fields.toggleButtonsList.${name}.swissQrSelected`}
            sx={{ color: BaseColour.GreenDark }}
            value={translationAttributes}
          />
        </NotificationContainer>
      ) : null}
    </FormControl>
  );
};
