/* eslint-disable max-len */
import { Translate } from '@heidi-pay/heidi-common-fe/types';

interface IRadioLabelItem {
  label: string;
  value: string;
}

interface IGenerateRadioLabelsProps {
  baseTranslationKey: string;
  labels: string[];
  t: Translate;
}

export const generateRadioItems = ({
  baseTranslationKey,
  labels,
  t,
}: IGenerateRadioLabelsProps): IRadioLabelItem[] =>
  labels?.map(item => ({
    label: t(`${baseTranslationKey}.options.${item}`) ?? item,
    value: item,
  })) ?? [];
