import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import { LoadingBackdropContainer } from '../../transaction/components/Styled';
import { getActionSettings } from '../../utils';

export default function LoadingBackdrop({ contained }) {
  const timerRef = useRef();
  const [isShown, setIsShown] = useState(false);

  // The Onfido waiting page has a permanent loader on it so it doesn't require
  // the global loader to be displayed
  useEffect(() => {
    timerRef.current = setTimeout(() => {
      const { isLoadingBackdropDisabled } = getActionSettings();
      setIsShown(!isLoadingBackdropDisabled);
    }, 300);

    return () => clearTimeout(timerRef.current);
  }, [timerRef]);

  if (!isShown) {
    return null;
  }

  return (
    <LoadingBackdropContainer contained={contained}>
      <CircularProgress aria-label="loading" />
    </LoadingBackdropContainer>
  );
}

LoadingBackdrop.propTypes = {
  contained: PropTypes.bool,
};

LoadingBackdrop.defaultProps = {
  contained: false,
};
