import {
  Body,
  RadioButtonGroup,
} from '@heidi-pay/heidi-component-library/components';
import { useTranslation } from '@hooks';
import { CopyBlock } from '../../../../components/CopyBlock';
import { IBaseInputComponent } from '../../types';
import { IButtonGroupPickerOptions } from './types';
import { generateRadioItems } from './utils/generateRadioItems';
import { getButtonGroupPickerValidation } from './validation';

export const ButtonGroupPicker = ({
  error,
  name,
  onChange,
  options,
  value = '',
}: IBaseInputComponent<IButtonGroupPickerOptions, string>) => {
  const { t } = useTranslation();

  if (!options) {
    throw Error('The options prop is required');
  }

  const { alignItems, items, labelColor, overrideKey, translationAttributes } =
    options;

  const baseTranslationKey = `fields.buttonGroupPicker.${
    overrideKey ? `${overrideKey}.` : ''
  }${name}`;
  const radioItems = generateRadioItems({
    baseTranslationKey,
    labels: items,
    t,
  });

  return (
    <RadioButtonGroup
      alignItems={alignItems}
      error={error ? t(error) : undefined}
      id={name}
      label={
        <CopyBlock
          component={Body}
          i18nKey={`${baseTranslationKey}.label`}
          value={translationAttributes ?? {}}
        />
      }
      labelColor={labelColor}
      onChange={onChange}
      radioItems={radioItems}
      sx={{ paddingTop: '12px', width: '100%', marginLeft: '2px' }}
      value={value}
    />
  );
};

ButtonGroupPicker.validation = getButtonGroupPickerValidation();
