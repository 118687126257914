import { SeverityLevel } from '@sentry/react';

export interface IEnhancedErrorOptions {
  isLockedSession: boolean;
  isLoggedUpstream: boolean;
  originalError: Error;
  severity: SeverityLevel;
  softErrorCode?: string;
  status?: number;
}

export class EnhancedError extends Error {
  constructor(message: string, options: IEnhancedErrorOptions) {
    super(message);
    this.isLoggedUpstream = options.isLoggedUpstream;
    this.isLockedSession = options.isLockedSession;
    this.softErrorCode = options.softErrorCode;
    this.originalError = options.originalError;
    this.severity = options.severity ?? 'error';
    this.status = options.status;
  }

  isLoggedUpstream: boolean;

  isLockedSession: boolean;

  originalError: Error;

  softErrorCode?: string;

  status?: number;

  severity: SeverityLevel;
}
