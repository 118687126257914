import { useSelector } from 'react-redux';
import { getFeatureFlags } from '@redux';
import { FeatureFlag } from './enums';

export const useFeatureFlags = () => {
  const flags = useSelector(getFeatureFlags) ?? {};

  const flagEnabled = (flag: FeatureFlag) => flags[flag] === true;
  return {
    flagEnabled,
  };
};
