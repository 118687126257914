import { bool, node } from 'prop-types';
import { Box } from '@mui/material';

export const VisibilityContainer = ({
  children,
  isHidden = false,
  isRendered,
}) =>
  isRendered ? (
    <Box sx={{ display: isHidden ? 'none' : 'inherit', width: '100%' }}>
      {children}
    </Box>
  ) : null;

VisibilityContainer.propTypes = {
  children: node.isRequired,
  isRendered: bool.isRequired,
};
