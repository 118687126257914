export enum InstoreFormType {
  Category = 'category',
  Default = 'default',
}

export enum FieldNames {
  AdditionalDetails = 'productAdditionalDetails',
  Discount = 'discount',
  Price = 'price',
  ProductIdentity = 'productIdentification',
  ProductName = 'productName',
  ProductSku = 'productSku',
  Quantity = 'quantity',
  ResidualValue = 'residualValue',
}
