import { Box, ListItemIcon, ListItemText, styled } from '@mui/material';

const ProductImageContainer = styled(Box)`
  width: 24px;
  height: 24px;
  text-align: right;
`;

const ProductImage = styled('img')`
  max-width: 24px;
  height: 24px;
`;

interface IProductOption {
  image?: string;
  text: string;
  value: string;
}

interface IProductMenuItem {
  product: IProductOption;
}

export const ProductMenuItem = ({ product }: IProductMenuItem) => (
  <>
    <ListItemIcon>
      <ProductImageContainer>
        {product.image ? (
          <ProductImage alt={product.text} src={product.image} />
        ) : null}
      </ProductImageContainer>
    </ListItemIcon>
    <ListItemText primary={product.text} sx={{ minHeight: '18px' }} />
  </>
);
