import {
  ThemeOptions as ThemeOptionsV5,
  createTheme as createThemeV5,
} from '@mui/material';

export const createThemes = (theme: ThemeOptionsV5) => {
  const themeV5 = createThemeV5(theme);
  return {
    themeV5,
  };
};
