import { HelpTooltip as HelpTooltipLibrary } from '@heidi-pay/heidi-component-library/components/HelpTooltip';
import { IBaseComponent, IComponentBaseOptions } from '../../types';

// Added for testing purposes only, unlikely to be used as a transaction component
// TODO can be removed once we add more components from the common library
export interface IHelpTooltipOptions extends IComponentBaseOptions {
  fontSize?: string;
  iconAriaLabel?: string;
  isPlainText?: boolean;
}

export const HelpTooltip = ({
  options: { fontSize, iconAriaLabel, tooltip } = {},
}: IBaseComponent<IHelpTooltipOptions, undefined>) => (
  <HelpTooltipLibrary
    content={tooltip}
    fontSize={fontSize}
    iconAriaLabel={iconAriaLabel}
  />
);
