import { Grid, styled } from '@mui/material';
import { icons } from '../../../../icons';

interface IPaymentMethodsDisplayProps {
  options?: string[];
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'flex-start',
  marginTop: theme.spacing(1.25),
  marginBottom: theme.spacing(0),
  gap: theme.spacing(1),
}));

export function PaymentMethodsDisplay({
  options = [],
}: IPaymentMethodsDisplayProps) {
  return (
    <StyledGrid container={true}>
      {options.map(option => (
        <Grid item={true} key={option}>
          {icons[option]}
        </Grid>
      ))}
    </StyledGrid>
  );
}
