import { ReactNode, Ref, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RadioButtonGroup,
  IRadioButtonGroupItem,
} from '@heidi-pay/heidi-component-library/components/RadioButtonGroup/RadioButtonGroup';
import { BaseColour } from '@heidi-pay/heidi-component-library/enums';
import { FormControl, FormHelperText, FormLabel, styled } from '@mui/material';

interface IConsentBlockOptions {
  acceptOnly?: boolean;
}

interface IConsentBlockProps {
  innerRef?: Ref<HTMLDivElement>;
  label?: ReactNode;
  name: string;
  onChange?: (name: string, value: boolean) => void;
  options?: IConsentBlockOptions;
  validationErrorTranslationKey?: string;
  value?: string;
}

const FormLabelStyled = styled(FormLabel)({
  color: BaseColour.Black,
  '&.Mui-focused': {
    color: BaseColour.Black,
  },
});

export const ConsentBlock = ({
  label,
  name,
  onChange,
  validationErrorTranslationKey,
  value = '',
}: IConsentBlockProps) => {
  const { t } = useTranslation();
  const radioItems: IRadioButtonGroupItem[] = [
    {
      label: t('common.yes').toUpperCase(),
      value: 'true',
    },
    {
      label: t('common.no').toUpperCase(),
      value: 'false',
    },
  ];

  const handleChange = useCallback(
    (selection: string) => onChange?.(name, selection === 'true'),
    [name, onChange],
  );

  return (
    <FormControl
      component="fieldset"
      error={!!validationErrorTranslationKey}
      fullWidth={true}
      sx={{ paddingLeft: '2px' }}
    >
      {label ? (
        <FormLabelStyled id={`label-${name}`}>{label}</FormLabelStyled>
      ) : null}
      <RadioButtonGroup
        id={name}
        onChange={handleChange}
        radioItems={radioItems}
        value={value}
      />
      {validationErrorTranslationKey ? (
        <FormHelperText>{t(validationErrorTranslationKey)}</FormHelperText>
      ) : null}
    </FormControl>
  );
};
