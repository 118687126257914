import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fallback } from '@redux';
import ErrorBackdrop from './ErrorBackdrop';

export default function ErrorFallback() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fallback());
  }, [dispatch]);

  return <ErrorBackdrop />;
}
