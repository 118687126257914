import PropTypes from 'prop-types';
import { Box } from '@mui/material';

export const Size = Object.freeze({
  xsmall: '0',
  small: '0.5',
  medium: '1',
  large: '2',
});

export default function Spacer({ options }) {
  const { key, size } = options;
  const marginTop = `${Size[size]}rem`;
  return <Box key={key} mt={marginTop} />;
}

Spacer.propTypes = {
  options: PropTypes.shape({
    key: PropTypes.string,
    size: PropTypes.string,
  }),
};

Spacer.defaultProps = {
  options: {
    key: null,
    size: Size.small,
  },
};

Spacer.formatting = () => undefined;
