import { SeverityLevel } from '@sentry/react';
import { axiosNetworkErrorCode, SentrySeverity } from '../sentry';

const warningLevelStatusCodes = [401, 403];

export const getSentrySeverity = (
  statusCode?: number,
  code?: string,
  hasLostNetworkConnectivity?: boolean,
): SeverityLevel => {
  if (code === axiosNetworkErrorCode) {
    if (hasLostNetworkConnectivity) {
      return SentrySeverity.info as SeverityLevel;
    }
    return SentrySeverity.error as SeverityLevel;
  }

  if (statusCode && warningLevelStatusCodes.includes(statusCode)) {
    return SentrySeverity.warning as SeverityLevel;
  }

  return SentrySeverity.warning as SeverityLevel;
};
