import { IPollingResponse } from 'src/transaction/services/types';
import { call } from '../utils/http';

export async function checkOnfidoStatus() {
  // http request retry disabled because the method is called on an interval so will be retried
  return call<IPollingResponse>('/api/checkout-client/onfido/status/', {
    method: 'GET',
    disableRetry: true,
  });
}
