import React, {
  ChangeEvent,
  useCallback,
  FocusEvent,
  KeyboardEvent,
} from 'react';
import { TextField, styled } from '@mui/material';
import { useTranslation } from '@hooks';

const StyledTextField = styled(TextField)(() => ({
  '&.single-digit-input': {
    '> .MuiInputBase-root': {
      width: '45px',
      height: '60px',
      justifyContent: 'space-between',
      '> .MuiInputBase-input': {
        paddingRight: 0,
        paddingTop: 0,
        textAlign: 'center',
      },
    },
  },
}));

interface ICodeInputProps {
  digit: string;
  error: boolean;
  index: number;
  isVerifying: boolean;
  name: string;
  onChange: (index: number, value: string) => void;
  onFocus: (event: FocusEvent<HTMLInputElement>) => void;
  onKeyDown: (index: number, e: KeyboardEvent<HTMLInputElement>) => void;
  setInputRef: (el: HTMLInputElement | null, index: number) => void;
}

export const CodeInput = React.memo(function CodeInput({
  digit,
  error,
  index,
  isVerifying,
  name,
  onChange,
  onFocus,
  onKeyDown,
  setInputRef,
}: ICodeInputProps) {
  const { t } = useTranslation();
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (/^\d*$/.test(value)) {
        onChange(index, value);
      }
    },
    [onChange, index],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      onKeyDown(index, e);
    },
    [onKeyDown, index],
  );

  const handleSetInputRef = useCallback(
    (el: HTMLInputElement | null) => {
      setInputRef(el, index);
    },
    [setInputRef, index],
  );

  return (
    <StyledTextField
      aria-label={t('fields.codeInput.label')}
      className="single-digit-input"
      disabled={isVerifying}
      error={error}
      id={`${name}-${index}`}
      inputProps={{
        maxLength: 1,
        inputMode: 'numeric',
        pattern: '[0-9]*',
      }}
      inputRef={handleSetInputRef}
      onChange={handleChange}
      onFocus={onFocus}
      onKeyDown={handleKeyDown}
      value={digit}
      variant="outlined"
    />
  );
});
