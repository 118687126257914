import { useSelector } from 'react-redux';
import { FormControl } from '@mui/material';
import { useTranslation } from '@hooks';
import { getPaymentSummary } from '@instore/redux/selectors';
import { CopyBlock } from '../../components';

const InstorePaymentSummary = () => {
  const { t } = useTranslation();
  const paymentSummary = useSelector(getPaymentSummary);

  if (!paymentSummary || JSON.stringify(paymentSummary) === '{}') {
    return null;
  }

  const { currency } = paymentSummary.total;

  return (
    <FormControl component="fieldset" fullWidth={true} size="small">
      <CopyBlock bold={true} i18nKey="fields.instorePaymentSummary.label" />
      <p>
        <span>
          {t('fields.instorePaymentSummary.components.payNow')}{' '}
          <b>
            {paymentSummary.initialPayment} {currency}
          </b>
          ,{' '}
        </span>
        <span>
          <b>{paymentSummary.schedule.length}x</b>{' '}
          {t('fields.instorePaymentSummary.components.paymentsOf')}{' '}
          <b>
            {paymentSummary.schedule[0].amount} {currency}
          </b>
        </span>
        {paymentSummary.holdPayment > 0 && (
          <span>
            , {t('fields.instorePaymentSummary.components.heldOnCard')}{' '}
            <b>
              {paymentSummary.holdPayment} {currency}
            </b>
          </span>
        )}
      </p>
    </FormControl>
  );
};

export default InstorePaymentSummary;
