import { ElementType } from 'react';
import { Nullable } from '@heidi-pay/heidi-common-fe/types';
import { TableCellBaseProps, Typography } from '@mui/material';
import { Align } from '@appTypes';
import { Cell } from '../../Styled';

interface IPaymentRowValue {
  alignAmount: Align;
  component: ElementType<TableCellBaseProps>;
  currency?: Nullable<string>;
  displayValueBold: boolean;
  isSmallFont: boolean;
  value: string;
}

export const PaymentRowValue = ({
  alignAmount,
  component,
  currency,
  displayValueBold,
  isSmallFont,
  value,
}: IPaymentRowValue) => (
  <>
    <Cell component={component}>
      <Typography
        component="span"
        fontSize={isSmallFont ? '0.875rem' : '1rem'}
        fontWeight={displayValueBold ? 'bold' : 'inherit'}
      >
        {currency}
      </Typography>
    </Cell>
    <Cell align={alignAmount} component={component}>
      <Typography
        component="span"
        fontSize={isSmallFont ? '0.875rem' : '1rem'}
        fontWeight={displayValueBold ? 'bold' : 'inherit'}
        style={{ whiteSpace: 'nowrap' }}
      >
        {value}
      </Typography>
    </Cell>
  </>
);
