export const formatAmountWithCurrency = (
  amount: string | number,
  locale: string,
  currency?: string,
) => {
  const localeCode = locale.replace('_', '-');
  const currencyFormatted = Number(amount).toLocaleString(localeCode, {
    style: 'currency',
    currency,
  });

  return currencyFormatted.trim();
};
