import { AddressField } from '../../AddressContainerItalianPost/utils/constants';
import { AddressFieldValue } from '../types';

const regexBisTerm = /bis/gi;

export const getParsedFieldValue = (
  fieldName: AddressFieldValue,
  fieldValue: string | null,
) => {
  if (!fieldValue) {
    return fieldValue;
  }

  let parsedFieldValue = fieldValue;
  /* Although "bis" is a legitimate term for house numbers, we don't allow it as it leads to the max character count
    being exceeded */
  if (
    fieldName === AddressField.streetNumber &&
    fieldValue.match(regexBisTerm)
  ) {
    parsedFieldValue = parsedFieldValue.replaceAll(regexBisTerm, '').trim();
  }

  return parsedFieldValue;
};
