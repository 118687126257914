import PropTypes from 'prop-types';
import { Button, styled } from '@mui/material';
import { Figcaption, Figure } from '../../Styled';

const IconButton = styled(Button)`
  & > span > svg {
    height: 32px;
    width: 32px;
  }
  & img {
    height: 32px;
    width: 32px;
  }
`;

export const ActionContainer = ({
  buttonVariant,
  caption,
  icon,
  label,
  onClick,
}) => (
  <Figure color="legacyGrey" fontSize="0.75rem" textAlign="center">
    <IconButton
      color="externalSpid"
      fullWidth={true}
      onClick={onClick}
      size="large"
      startIcon={icon}
      sx={{ textTransform: 'none' }}
      variant={buttonVariant}
    >
      {label}
    </IconButton>
    <Figcaption>{caption}</Figcaption>
  </Figure>
);

ActionContainer.propTypes = {
  buttonVariant: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
