import { Translate } from '@heidi-pay/heidi-common-fe/types';
import { IPaymentRowProps } from '@heidi-pay/heidi-component-library/components/PaymentPlanTable';
import { IUseFormatter } from '../../../../../utils/formatters/types';
import { IPaymentPlanHeyLightOptions, PaymentPlanVariant } from '../types';
import { getItalyBnplPaymentPlanFields } from './getItalyBbplPaymentPlanFields';
import { getItalyCreditInstorePaymentPlanFields } from './getItalyCreditInstorePaymentPlanFields';
import { getItalyCreditPaymentPlanFields } from './getItalyCreditPaymentPlanFields';
import { getSwissBnplPaymentPlanFields } from './getSwissBnplPaymentPlanFields';
import { getSwissCreditPaymentPlanFields } from './getSwissCreditPaymentPlanFields';
import { getSwissMobilezoneInstorePaymentPlanFields } from './getSwissMobilezoneInstorePaymentPlanFields';
import { getSwissMobilezonePaymentPlanFields } from './getSwissMobilezonePaymentPlanFields';
import { IScheduleData } from './types';

interface IFilters {
  isRegulatedProduct: boolean;
  originatorUuid: string;
  variant: PaymentPlanVariant;
}

interface IGetPaymentPlanFields {
  filters: IFilters;
  formatters: IUseFormatter;
  options: IPaymentPlanHeyLightOptions;
  scheduleData: IScheduleData;
  translate: Translate;
}

const mappingFunctions: Record<
  PaymentPlanVariant,
  (
    scheduleData: IScheduleData,
    formatters: IUseFormatter,
    translate: Translate,
    options?: IPaymentPlanHeyLightOptions,
  ) => IPaymentRowProps[] | null
> = {
  italyBnpl: getItalyBnplPaymentPlanFields,
  italyCredit: getItalyCreditPaymentPlanFields,
  italyCreditInstore: getItalyCreditInstorePaymentPlanFields,
  swissBnpl: getSwissBnplPaymentPlanFields,
  swissCredit: getSwissCreditPaymentPlanFields,
  swissMobilezone: getSwissMobilezonePaymentPlanFields,
  swissMobilezoneInstore: getSwissMobilezoneInstorePaymentPlanFields,
};

export const getPaymentPlanFields = ({
  filters,
  formatters,
  options,
  scheduleData,
  translate,
}: IGetPaymentPlanFields) =>
  mappingFunctions[filters.variant]?.(
    scheduleData,
    formatters,
    translate,
    options,
  );
