import { ElementType } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Typography, IconButton, TableCellBaseProps } from '@mui/material';
import { Translate } from '@hooks/useTranslation/types';
import { HelpTooltip } from '../../HelpTooltip';
import { Cell } from '../../Styled';

interface IPaymentRowLabel {
  component: ElementType<TableCellBaseProps>;
  displayLabelBold: boolean;
  displayLabelOnly: boolean;
  isExpanded: boolean;
  isScheduleToggle: boolean;
  isSmallFont: boolean;
  label: string;
  t: Translate;
  toggleExpanded?: () => void;
  tooltip?: string;
}

export const PaymentRowLabel = ({
  component,
  displayLabelBold,
  displayLabelOnly,
  isExpanded,
  isScheduleToggle,
  isSmallFont,
  label,
  t,
  toggleExpanded,
  tooltip,
}: IPaymentRowLabel) => (
  <Cell
    colSpan={displayLabelOnly ? 2 : 1}
    component={component}
    {...(isScheduleToggle && toggleExpanded
      ? {
          onClick: toggleExpanded,
        }
      : null)}
  >
    <Typography
      component="span"
      fontSize={isSmallFont ? '0.875rem' : '1rem'}
      fontWeight={displayLabelBold ? 'bold' : 'inherit'}
    >
      {label}
    </Typography>
    {tooltip ? <HelpTooltip isPlainText={true} tooltip={tooltip} /> : null}
    {isScheduleToggle && toggleExpanded ? (
      <IconButton
        aria-label={t('fields.paymentPlan.expand')}
        onClick={toggleExpanded}
        size="small"
      >
        {isExpanded ? (
          <KeyboardArrowUpIcon onClick={toggleExpanded} />
        ) : (
          <KeyboardArrowDownIcon onClick={toggleExpanded} />
        )}
      </IconButton>
    ) : null}
  </Cell>
);
