/* eslint-disable curly */
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import { components } from 'react-select'; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from '@hooks';
import env from '../../../../../env';

function LoadingIndicator() {
  return (
    <Box ml={1} mr={1}>
      <CircularProgress
        color="primary"
        role="progressbar"
        size="1.3rem"
        thickness={3}
        variant="indeterminate"
      />
    </Box>
  );
}

function Menu(props) {
  const { children, getValue, hasValue } = props; // eslint-disable-line react/prop-types
  if (!hasValue) return null;
  if (!getValue()?.[0]?.label) return null;
  if (children?.props?.children?.props?.children?.props?.isLoading) return null; // eslint-disable-line react/prop-types,max-len
  return (
    <components.Menu {...props} /> // eslint-disable-line react/jsx-props-no-spreading
  );
}

function NoOptionsMessage({ ...props }) {
  // eslint-disable-line react/prop-types
  const { t } = useTranslation();
  return (
    <components.NoOptionsMessage {...props}>
      {t('fields.addressContainer.noMatches')}
    </components.NoOptionsMessage> // eslint-disable-line react/jsx-props-no-spreading,max-len
  );
}

export default function GooglePlacesAdapter({
  country,
  inputRef,
  isConverting,
  name,
  onBlur,
  onChange,
  value,
  ...rest
}) {
  const handleChange = async ({ label, value: { place_id: placeId } }) => {
    onChange({
      label,
    });

    const details = await geocodeByPlaceId(placeId);
    onChange({
      label,
      details,
      placeId,
    });
  };

  const handleType = val => {
    onChange({
      label: val,
    });
  };

  const onFocus = () => {
    onChange({ label: '' });
  };

  const handleMenuClose = () => {
    onChange({
      label: value,
    });
  };

  return (
    <GooglePlacesAutocomplete
      apiKey={env.GOOGLE_API_KEY}
      autocompletionRequest={{
        componentRestrictions: {
          country: [country],
        },
        types: ['address'],
      }}
      minLengthAutocomplete={1}
      selectProps={{
        ref: inputRef,
        inputId: name,
        value: { label: value, value: { description: value } },
        onChange: handleChange,
        onInputChange: handleType,
        onMenuClose: handleMenuClose,
        onBlur,
        blurInputOnSelect: true,
        onFocus,
        isLoading: isConverting,
        styles: {
          control: provided => ({
            ...provided,
            border: 'none',
            boxShadow: 'none',
            fontSize: '18px',
            marginLeft: '-10px',
            paddingTop: '15px',
          }),
          container: provided => ({
            ...provided,
            width: '100%',
          }),
          menu: provided => ({
            ...provided,
            zIndex: 1000,
          }),
        },
        placeholder: '',
        components: {
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator,
          Menu,
          NoOptionsMessage,
        },
      }}
      withSessionToken={true}
      {...rest} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
}

GooglePlacesAdapter.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
  isConverting: PropTypes.bool.isRequired,
};
