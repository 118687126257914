import * as yup from 'yup';
import { ComponentTypes } from '@utils';

interface IField {
  name: string;
  type?: string;
}

interface IValidations {
  [key: string]: yup.AnySchema;
}

const customValidations: IValidations = {
  netMonthlyIncomeSwiss: yup
    .number()
    .transform(value => (Number.isNaN(value) ? undefined : value))
    .required('fields.additionalUnderwritingContainer.inputRequired')
    .min(1, 'fields.additionalUnderwritingContainer.greaterThanZero'),
};

const defaultValidationBuilder = (name: string, componentType?: string) => {
  const validationKey =
    componentType === ComponentTypes.SelectPicker ||
    componentType === ComponentTypes.NumberPicker
      ? 'fields.additionalUnderwritingContainer.selectionRequired'
      : 'fields.additionalUnderwritingContainer.inputRequired';

  return yup.string().required(validationKey);
};

export function buildSchema(fields: IField[], parsedFormFields: IField[]) {
  return Object.fromEntries(
    fields.map(({ name }) => {
      const componentType = parsedFormFields.find(
        field => field.name === name,
      )?.type;

      const validation =
        customValidations[name] ||
        defaultValidationBuilder(name, componentType);

      return [name, validation];
    }),
  );
}
