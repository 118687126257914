export enum FeatureFlag {
  IsExternalSigningEnabled = 'isExternalSigningEnabled',
  IsHelpWidgetEnabled = 'isHelpWidgetEnabled',
  IsHeylightOverwriteByMerchantBrandingEnabled = 'isHeylightOverwriteByMerchantBrandingEnabled',
  IsInstorePreviousOrderSelectorEnabled = 'isInstorePreviousOrderSelectorEnabled',
  IsItalianPostalServiceProxyEnabled = 'isItalianPostalServiceProxyEnabled',
  IsNewOrderProfileLabelEnabled = 'isNewOrderProfileLabelEnabled',
  IsSalesforceLiveChatEnabled = 'isSalesforceLiveChatEnabled',
  MobilezoneEnableFlexPriceFeature = 'mobilezoneEnableFlexPriceFeature',
}
