import PropTypes from 'prop-types';
import { FormLabel } from '@mui/material';
import { HelpTooltip } from '../HelpTooltip';

export const StackedLabel = ({ className, label, name, tooltip }) => {
  return (
    <FormLabel className={className} htmlFor={name}>
      {label}
      {tooltip ? <HelpTooltip tooltip={tooltip} /> : null}
    </FormLabel>
  );
};

StackedLabel.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

StackedLabel.defaultProps = {
  className: undefined,
  tooltip: undefined,
};
