/* eslint-disable max-lines */
import { ComponentTypes } from '../enums';
import { IComplianceContainerContent } from '../types';
import { BnplDialogId, ConsentTypeItalyBnpl } from './enums';

export const selectScheduleComplianceContent: IComplianceContainerContent = {
  dialogContent: [
    {
      complianceElements: [
        {
          componentType: ComponentTypes.ComplianceTrans,
          translationKey: 'selectScheduleItalyBnpl.dialog.subtitle',
        },
        {
          componentType: ComponentTypes.ComplianceTrans,
          translationKey: 'selectScheduleItalyBnpl.dialog.content',
        },
      ],
      id: BnplDialogId.SelectScheduleDialog1,
      title: {
        componentType: ComponentTypes.RawText,
        translationKey: 'selectScheduleItalyBnpl.dialog.title',
      },
    },
  ],
  formContent: [
    {
      componentType: ComponentTypes.ComplianceTrans,
      dialogId: BnplDialogId.SelectScheduleDialog1,
      translationKey: 'selectScheduleItalyBnpl.form.block1',
    },
  ],
};

export const personalDetailsComplianceContent: IComplianceContainerContent = {
  dialogContent: [
    {
      complianceElements: [
        {
          componentType: ComponentTypes.ComplianceTrans,
          translationKey: 'personalDetailsItalyBnpl.dialog1.content',
        },
      ],
      id: BnplDialogId.PersonalDetailsDialog1,
      signatureElement: {
        componentType: ComponentTypes.ConsentBlock,
        id: ConsentTypeItalyBnpl.PaymentExtension,
        translationKey: 'personalDetailsItalyBnpl.dialog1.consentLabel',
      },
      title: {
        componentType: ComponentTypes.RawText,
        translationKey: 'personalDetailsItalyBnpl.dialog1.title',
      },
    },
    {
      complianceElements: [
        {
          componentType: ComponentTypes.ComplianceTrans,
          translationKey: 'personalDetailsItalyBnpl.dialog2.content',
        },
      ],
      id: BnplDialogId.PersonalDetailsDialog2,
      signatureElement: {
        componentType: ComponentTypes.ConsentBlock,
        id: ConsentTypeItalyBnpl.NoPaymentTermsAndConditions,
        translationKey: 'personalDetailsItalyBnpl.dialog2.consentLabel',
      },
      title: {
        componentType: ComponentTypes.RawText,
        translationKey: 'personalDetailsItalyBnpl.dialog2.title',
      },
    },
    {
      complianceElements: [
        {
          componentType: ComponentTypes.ComplianceTrans,
          translationKey: 'personalDetailsItalyBnpl.dialog3.content',
        },
      ],
      id: BnplDialogId.PersonalDetailsDialog3,
      signatureElement: {
        componentType: ComponentTypes.ConsentBlock,
        id: ConsentTypeItalyBnpl.TransferOfCreditFromMerchantToCompass,
        translationKey: 'personalDetailsItalyBnpl.dialog3.consentLabel',
      },
      title: {
        componentType: ComponentTypes.RawText,
        translationKey: 'personalDetailsItalyBnpl.dialog3.title',
      },
    },
    {
      complianceElements: [
        {
          componentType: ComponentTypes.ComplianceTrans,
          translationKey: 'personalDetailsItalyBnpl.dialog4.content',
        },
      ],
      id: BnplDialogId.PersonalDetailsDialog4,
      signatureElement: {
        componentType: ComponentTypes.ConsentBlock,
        id: ConsentTypeItalyBnpl.CompassDataProcessing,
        translationKey: 'personalDetailsItalyBnpl.dialog4.consentLabel',
      },
      title: {
        componentType: ComponentTypes.RawText,
        translationKey: 'personalDetailsItalyBnpl.dialog4.title',
      },
    },
    {
      complianceElements: [
        {
          componentType: ComponentTypes.ComplianceTrans,
          translationKey: 'personalDetailsItalyBnpl.dialog5.content',
        },
      ],
      id: BnplDialogId.PersonalDetailsDialog5,
      signatureElement: {
        componentType: ComponentTypes.ConsentBlock,
        id: ConsentTypeItalyBnpl.ProfilingActivitiesForPromotionalCommunications,
        translationKey: 'personalDetailsItalyBnpl.dialog5.consentLabel',
      },
      title: {
        componentType: ComponentTypes.RawText,
        translationKey: 'personalDetailsItalyBnpl.dialog5.title',
      },
    },
    {
      complianceElements: [
        {
          componentType: ComponentTypes.ComplianceTrans,
          translationKey: 'personalDetailsItalyBnpl.dialog6.content',
        },
      ],
      id: BnplDialogId.PersonalDetailsDialog6,
      signatureElement: {
        componentType: ComponentTypes.ConsentBlock,
        id: ConsentTypeItalyBnpl.ExternalDataProcessing,
        translationKey: 'personalDetailsItalyBnpl.dialog6.consentLabel',
      },
      title: {
        componentType: ComponentTypes.RawText,
        translationKey: 'personalDetailsItalyBnpl.dialog6.title',
      },
    },
  ],
  formContent: [
    {
      componentType: ComponentTypes.ComplianceTrans,
      translationKey: 'personalDetailsItalyBnpl.form.block0',
    },
    {
      componentType: ComponentTypes.ComplianceTrans,
      dialogId: BnplDialogId.PersonalDetailsDialog1,
      translationKey: 'personalDetailsItalyBnpl.form.block1',
    },
    {
      componentType: ComponentTypes.ConsentBlockWithoutLabel,
      id: ConsentTypeItalyBnpl.PaymentExtension,
      translationKey: '',
    },
    {
      componentType: ComponentTypes.ComplianceTrans,
      dialogId: BnplDialogId.PersonalDetailsDialog2,
      translationKey: 'personalDetailsItalyBnpl.form.block2',
    },
    {
      componentType: ComponentTypes.ConsentBlockWithoutLabel,
      id: ConsentTypeItalyBnpl.NoPaymentTermsAndConditions,
      translationKey: '',
    },
    {
      componentType: ComponentTypes.ComplianceTrans,
      dialogId: BnplDialogId.PersonalDetailsDialog3,
      translationKey: 'personalDetailsItalyBnpl.form.block3',
    },
    {
      componentType: ComponentTypes.ConsentBlockWithoutLabel,
      id: ConsentTypeItalyBnpl.TransferOfCreditFromMerchantToCompass,
      translationKey: '',
    },
    {
      componentType: ComponentTypes.ComplianceTrans,
      dialogId: BnplDialogId.PersonalDetailsDialog4,
      translationKey: 'personalDetailsItalyBnpl.form.block4',
    },
    {
      componentType: ComponentTypes.ConsentBlockWithoutLabel,
      id: ConsentTypeItalyBnpl.CompassDataProcessing,
      translationKey: '',
    },
    {
      componentType: ComponentTypes.ComplianceTrans,
      dialogId: BnplDialogId.PersonalDetailsDialog5,
      translationKey: 'personalDetailsItalyBnpl.form.block5',
    },
    {
      componentType: ComponentTypes.ConsentBlockWithoutLabel,
      id: ConsentTypeItalyBnpl.ProfilingActivitiesForPromotionalCommunications,
      translationKey: '',
    },
    {
      componentType: ComponentTypes.ComplianceTrans,
      dialogId: BnplDialogId.PersonalDetailsDialog6,
      translationKey: 'personalDetailsItalyBnpl.form.block6',
    },
    {
      componentType: ComponentTypes.ConsentBlockWithoutLabel,
      id: ConsentTypeItalyBnpl.ExternalDataProcessing,
      translationKey: '',
    },
  ],
};

export const securityCodeVerificationItalyBnplDirectDebitComplianceContent: IComplianceContainerContent =
  {
    dialogContent: [
      {
        complianceElements: [
          {
            componentType: ComponentTypes.ComplianceTrans,
            translationKey:
              'securityCodeVerificationItalyBnplDirectDebit.dialog.content',
          },
        ],
        id: BnplDialogId.SecurityCodeVerificationDialog,
        title: {
          componentType: ComponentTypes.RawText,
          translationKey:
            'securityCodeVerificationItalyBnplDirectDebit.dialog.title',
        },
      },
    ],
    formContent: [
      {
        componentType: ComponentTypes.ComplianceTrans,
        dialogId: BnplDialogId.SecurityCodeVerificationDialog,
        translationKey:
          'securityCodeVerificationItalyBnplDirectDebit.form.block1',
      },
    ],
  };

export const securityCodeVerificationItalyBnplCardComplianceContent: IComplianceContainerContent =
  {
    dialogContent: [
      {
        complianceElements: [
          {
            componentType: ComponentTypes.ComplianceTrans,
            translationKey:
              'securityCodeVerificationItalyBnplCard.dialog.content',
          },
        ],
        id: BnplDialogId.SecurityCodeVerificationDialog,
        title: {
          componentType: ComponentTypes.RawText,
          translationKey: 'securityCodeVerificationItalyBnplCard.dialog.title',
        },
      },
    ],
    formContent: [
      {
        componentType: ComponentTypes.ComplianceTrans,
        dialogId: BnplDialogId.SecurityCodeVerificationDialog,
        translationKey: 'securityCodeVerificationItalyBnplCard.form.block1',
      },
    ],
  };
