import { AddressField } from '../AddressContainerItalianPost/utils/constants';
import { Choices, IAddressField, IAddressFieldConfig } from './types';

export const defaultChoices: Choices = {
  [AddressField.zipCode]: [],
  [AddressField.streetName]: [],
  [AddressField.streetNumber]: [],
  [AddressField.city]: [],
  [AddressField.province]: [],
  [AddressField.country]: [],
};

export const defaultFormFields: IAddressField = {
  [AddressField.zipCode]: '',
  [AddressField.streetName]: '',
  [AddressField.streetNumber]: '',
  [AddressField.city]: '',
  [AddressField.province]: '',
  [AddressField.country]: '',
};

export const addressValidationFields = [
  { name: AddressField.province },
  { name: AddressField.country },
  { name: AddressField.city },
  { name: AddressField.zipCode },
  { name: AddressField.streetName },
  { name: AddressField.streetNumber },
];

export const fieldConfig: IAddressFieldConfig[] = [
  {
    fieldName: AddressField.zipCode,
    onResetClearChoices: [
      AddressField.zipCode,
      AddressField.streetName,
      AddressField.streetNumber,
      AddressField.city,
      AddressField.province,
      AddressField.country,
    ],
    dependsOn: [
      AddressField.streetName,
      AddressField.streetNumber,
      AddressField.city,
      AddressField.province,
      AddressField.country,
    ],
  },
  {
    fieldName: AddressField.streetName,
    enabledDependsOn: AddressField.zipCode,
    onResetClearChoices: [
      AddressField.streetName,
      AddressField.streetNumber,
      AddressField.city,
      AddressField.province,
      AddressField.country,
    ],
    dependsOn: [
      AddressField.streetNumber,
      AddressField.city,
      AddressField.province,
      AddressField.country,
    ],
  },
  {
    fieldName: AddressField.streetNumber,
    enabledDependsOn: AddressField.streetName,
    onResetClearChoices: [
      AddressField.city,
      AddressField.province,
      AddressField.country,
    ],
    dependsOn: [AddressField.city],
  },
  {
    fieldName: AddressField.city,
    enabledDependsOn: AddressField.streetNumber,
    dependsOn: [AddressField.province],
  },
  {
    fieldName: AddressField.province,
    dependsOn: [],
  },
  {
    fieldName: AddressField.country,
    dependsOn: [],
  },
];

export const clearReason = ['reset', 'clear'];
