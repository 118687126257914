import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  ISelectPickerOptions,
  SelectPicker,
} from '../../../../transaction/components/heylight/SelectPicker';
import { IBaseInputComponent } from '../../../../transaction/components/types';

import {
  setPricingStructureCode,
  updateIsPricingStructureCodeRequired,
} from '../../../redux';

export const InstorePricingStructureCodePicker = ({
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  setValue,
  value = '',
}: IBaseInputComponent<ISelectPickerOptions, string, string>) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateIsPricingStructureCodeRequired(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPricingStructureCode(value));
  }, [dispatch, value]);

  return (
    <SelectPicker
      displayRawValues={true}
      error={error}
      inputRef={inputRef}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      setValue={setValue}
      value={value}
    />
  );
};
