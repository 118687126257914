import { Nullable } from '@heidi-pay/heidi-common-fe/types';
import { BaseColour } from '@heidi-pay/heidi-component-library/enums';
import {
  heyLightChTheme,
  heyLightTheme,
  getMerchantThemeOverrides,
} from '@heidi-pay/heidi-component-library/themes';
import { ThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { ColorCodes, Themes } from './enums';

const themeMap = {
  [Themes.HeyLight]: heyLightTheme,
  [Themes.HeyLightSwiss]: heyLightChTheme,
};

interface IGetHeyLightThemeProps {
  isHeylightOverwriteByMerchantBrandingEnabled: boolean;
  logo?: Nullable<string>;
  merchantColor?: string;
  merchantName?: string;
  themeType?: Themes;
}

export const getHeyLightTheme = ({
  isHeylightOverwriteByMerchantBrandingEnabled,
  logo,
  merchantColor,
  merchantName,
  themeType,
}: IGetHeyLightThemeProps): Partial<ThemeOptions> => {
  const merchantThemeOverrides = isHeylightOverwriteByMerchantBrandingEnabled
    ? getMerchantThemeOverrides({ merchantColor, logo, merchantName })
    : {};

  const heyLightThemeVariant = themeType ? themeMap[themeType] : heyLightTheme;

  const checkoutClientOverrides = deepmerge(
    {
      palette: {
        ...heyLightThemeVariant.palette,
        // required for some of the legacy components
        black: {
          main: BaseColour.Black,
        },
        externalSpid: {
          contrastText: ColorCodes.ExternalSpidContrastText,
          dark: ColorCodes.ExternalSpidDark,
          main: ColorCodes.ExternalSpidMain,
        },
        inherit: {
          main: ColorCodes.Inherit,
        },
        legacyGrey: {
          main: ColorCodes.LegacyGreyMain,
          light: ColorCodes.LegacyGreyLight,
        },
      },
      components: {
        // Theme override for multi selection Autocomplete used for instore term selection
        MuiAutocomplete: {
          styleOverrides: {
            root: {
              '&.HeyLight-multi-select': {
                '>.MuiFormControl-root >.MuiInputBase-root': {
                  minHeight: '60px',
                  height: 'auto',
                  '>.MuiButtonBase-root': {
                    marginTop: '20px',
                  },
                  '>fieldset': {
                    minHeight: '60px',
                    height: 'auto',
                  },
                },
              },
            },
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              marginLeft: 0,
            },
          },
        },
      },
    } as ThemeOptions,
    merchantThemeOverrides,
  );

  return deepmerge(heyLightThemeVariant, checkoutClientOverrides);
};
