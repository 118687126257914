/* eslint-disable typescript-sort-keys/string-enum */
export enum Size {
  Xxs = '4px',
  Xs = '8px',
  Sm = '12px',
  Md = '16px',
  Lg = '20px',
  Xl = '24px',
  Xxl = '28px',
}
/* eslint-enable typescript-sort-keys/string-enum */

export enum Emoji {
  Hourglass = '⌛',
  MagnifyingGlass = '🔎',
  Muscle = '💪',
  SlightFrowningFace = '🙁',
  Waving = '👋',
}
