import { FeatureFlag } from './enums';

interface IFlagValue {
  type: FeatureFlag;
  value: boolean;
}

export interface IMockUseFeatureFlagsOptions {
  flagValues: IFlagValue[];
}

export const flagEnabledMock = (
  flag: FeatureFlag,
  { flagValues }: IMockUseFeatureFlagsOptions,
) => !!flagValues?.find(({ type, value }) => (type === flag ? value : false));
