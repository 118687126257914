import { IBaseComponent } from '../../types';
import { PaymentPlanBase } from './PaymentPlanBase';
import { IPaymentPlanHeyLightOptions } from './types';

export const PaymentPlan = ({
  name,
  options = {},
}: IBaseComponent<IPaymentPlanHeyLightOptions, unknown>) => (
  <PaymentPlanBase
    name={name}
    options={{ ...options, variant: options.variant ?? 'swissBnpl' }}
  />
);
