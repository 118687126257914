import PropTypes from 'prop-types';
import { Box, FormControl, styled } from '@mui/material';

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  maxHeight: '100px',
  marginTop: '10px',
});

const ImageBox = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  paddingLeft: '20px',
  paddingRight: '20px',
});

const StyledImage = styled('img')({
  display: 'block',
  maxHeight: '100%',
  maxWidth: '100%',
  objectPosition: 'center',
});

export default function ImageRow({ value }) {
  const { images = [] } = value;

  return (
    <FormControl component="fieldset" fullWidth={true}>
      <StyledBox>
        {images.map(({ alt, url }) => (
          <ImageBox key={url}>
            <StyledImage alt={alt ?? ''} src={url} />
          </ImageBox>
        ))}
      </StyledBox>
    </FormControl>
  );
}

ImageRow.propTypes = {
  value: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }),
    ),
  }).isRequired,
};
