import { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

export const NumericFormatInput = forwardRef(
  (
    {
      decimalPlaces,
      decimalSeparator,
      groupSeparator,
      onChange,
      ...otherProps
    },
    ref,
  ) => (
    <NumericFormat
      allowLeadingZeros={true}
      allowNegative={false}
      decimalScale={decimalPlaces}
      decimalSeparator={decimalSeparator}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      getInputRef={ref}
      // eslint-disable-next-line react/jsx-no-bind
      onValueChange={({ value }) => onChange(value)}
      thousandSeparator={groupSeparator}
      valueIsNumericString={true}
    />
  ),
);

NumericFormatInput.displayName = 'NumericFormatInput';

NumericFormatInput.propTypes = {
  decimalPlaces: PropTypes.number,
  decimalSeparator: PropTypes.string,
  groupSeparator: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

NumericFormatInput.defaultProps = {
  decimalPlaces: 0,
  decimalSeparator: undefined,
  groupSeparator: undefined,
};
