import { call } from '../../utils/http';

export async function verifyPassword(password) {
  return call('/api/checkout-client/consumer/login/', {
    method: 'POST',
    body: JSON.stringify({
      password,
    }),
  });
}
