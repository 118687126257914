import * as yup from 'yup';
import { IButtonGroupPickerOptions } from './types';

export const getButtonGroupPickerValidation =
  () =>
  (name: string, { overrideKey, requiredItem }: IButtonGroupPickerOptions) => {
    const requiredValidation = yup
      .string()
      .required('fields.buttonGroupPicker.required');

    if (requiredItem) {
      const baseTranslationKey = `fields.buttonGroupPicker.${
        overrideKey ? `${overrideKey}.` : ''
      }${name}`;
      const itemSpecificTranslationSection = `${requiredItem.toLowerCase()}Required`;
      return requiredValidation.oneOf(
        [requiredItem],
        `${baseTranslationKey}.${itemSpecificTranslationSection}`,
      );
    }

    return requiredValidation;
  };
