import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Children, Nullable } from '@appTypes';

interface IContinueBtbPortal {
  children: Children;
}

export const AutoScanningPortal = ({ children }: IContinueBtbPortal) => {
  const [portalRoot, setPortalRoot] = useState<Nullable<HTMLElement>>(null);

  useEffect(() => {
    const portalElement = document.getElementById('portal-mitek-sdk-close-btn');
    setPortalRoot(portalElement);
  }, []);

  return portalRoot ? (
    ReactDOM.createPortal(children, portalRoot)
  ) : (
    <>{children}</>
  );
};
