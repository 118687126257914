import { call } from '../utils/http';

export async function getToken(otc) {
  // eslint-disable-next-line camelcase
  const { checkout_token: token, config } = await call(
    '/api/checkout-client/otc/exchange/',
    {
      method: 'POST',
      body: JSON.stringify({
        otc,
      }),
    },
  );

  return { token, config };
}

export async function getConfig() {
  return call('/api/checkout-client/config/');
}

export async function putLocale(locale) {
  return call('/api/checkout-client/config/', {
    method: 'PUT',
    body: JSON.stringify({
      locale,
    }),
  });
}
