import React, { useCallback } from 'react';
import PhoneInput, { Country } from 'react-phone-number-input/input';
import { InputBaseComponentProps } from '@mui/material';

export interface IPhoneInputAdapterProps
  extends Omit<InputBaseComponentProps, 'onChange'> {
  country: Country;
  inputRef: React.RefObject<HTMLInputElement>;
  label?: string;
  onAnimationStart: (event: React.AnimationEvent<HTMLInputElement>) => void;
  onChange: (value: string) => void;
}

export const PhoneInputAdapter = ({
  country,
  inputRef,
  label,
  onAnimationStart,
  onChange,
  ...rest
}: IPhoneInputAdapterProps) => {
  const handleChange = useCallback(
    (number?: string) => onChange(number ?? ''),
    [onChange],
  );

  const handleAnimationStart = useCallback(
    (event: React.AnimationEvent<HTMLInputElement>) => {
      if (event.animationName !== 'mui-auto-fill-cancel') {
        onAnimationStart(event);
      }
    },
    [onAnimationStart],
  );

  return (
    <PhoneInput
      aria-label={label}
      country={country}
      international={true}
      onAnimationStart={handleAnimationStart}
      onChange={handleChange}
      ref={inputRef}
      {...rest}
    />
  );
};
