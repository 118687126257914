import { Themes } from './enums';

export const getParsedThemeTypeOrDefault = (themeName: string) => {
  const lowerCaseThemeName = themeName.toLowerCase();
  return (Object.values(Themes) as unknown as string[]).includes(
    lowerCaseThemeName,
  )
    ? (lowerCaseThemeName as unknown as Themes)
    : Themes.HeyLight;
};
