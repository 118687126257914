import { useCallback, useEffect, useRef, useState } from 'react';

// Google's document viewer doesn't always load so we need to force a reload...
// We should only use this component for external resource with CORS restrictions.
export const useReloadIframeForFailure = uri => {
  const [iframeTimeoutId, setIframeTimeoutId] = useState();

  const iframeRef = useRef(null);

  const updateIframeSrc = useCallback(() => {
    if (iframeRef.current) {
      iframeRef.current.src = uri;
    }
  }, [uri]);

  useEffect(() => {
    const intervalId = setInterval(updateIframeSrc, 1000 * 3);
    setIframeTimeoutId(intervalId);

    return () => clearInterval(intervalId);
  }, [updateIframeSrc]);

  const onIframeLoaded = useCallback(
    () => clearInterval(iframeTimeoutId),
    [iframeTimeoutId],
  );

  return {
    iframeRef,
    onError: updateIframeSrc,
    onIframeLoaded,
  };
};
