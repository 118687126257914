import { AxiosError } from 'axios';
import { LockedSessionStatusCode } from './enums';

export const retryHandler = async <TResponse>(
  httpRequest: () => Promise<TResponse>,
  cancelRetry: (error: AxiosError) => unknown,
) => {
  try {
    return await httpRequest();
  } catch (error) {
    const axiosError = error as AxiosError;
    if (
      axiosError.isAxiosError &&
      axiosError?.response?.status === LockedSessionStatusCode
    ) {
      cancelRetry(axiosError);
    }
    throw error;
  }
};
