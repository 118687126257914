import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IBaseInputComponent,
  IComponentBaseOptions,
} from '../../../transaction/components/types';
import { getProductDetails, setProductSku } from '../../redux';
import { IProduct } from '../../services/types';
import { InstoreProductSelect } from './InstoreProductSelect';

interface IInstoreProductSelectStandaloneOptions extends IComponentBaseOptions {
  priceFieldName?: string;
  productAdditionalDetailsField?: string;
  productNameField?: string;
  productSkuField?: string;
}

export interface IInstoreProductSelectStandaloneProps
  extends IBaseInputComponent<
    IInstoreProductSelectStandaloneOptions,
    string,
    string
  > {
  options: IInstoreProductSelectStandaloneOptions;
}

export const InstoreProductSelectStandalone = (
  props: IInstoreProductSelectStandaloneProps,
) => {
  const { onChange, options, setValue } = props;
  const {
    priceFieldName,
    productAdditionalDetailsField,
    productNameField,
    productSkuField,
  } = options;

  const dispatch = useDispatch();
  const productDetailsFromStore = useSelector(getProductDetails);

  const updateFormFields = useCallback(
    (productDetails: IProduct | null) => {
      if (priceFieldName) {
        setValue(
          priceFieldName,
          productDetails?.expectedPrice ?? { amount: '', currency: '' },
        );
      }

      if (productNameField) {
        setValue(productNameField, productDetails?.name ?? '', {
          shouldDirty: true,
        });
      }

      if (productAdditionalDetailsField) {
        setValue(
          productAdditionalDetailsField,
          productDetails?.additionalDetails ?? {},
        );
      }

      if (productSkuField) {
        setValue(productSkuField, productDetails?.sku ?? '', {
          shouldDirty: true,
        });
      }
    },
    [
      priceFieldName,
      productAdditionalDetailsField,
      productNameField,
      productSkuField,
      setValue,
    ],
  );

  useEffect(() => {
    updateFormFields(productDetailsFromStore);
  }, [productDetailsFromStore, updateFormFields]);

  const handleSearchResult = useCallback(
    productDetails => {
      updateFormFields(null);
      dispatch(setProductSku(productDetails.sku));
      onChange(productDetails.sku);
    },
    [dispatch, onChange, updateFormFields],
  );

  return (
    <InstoreProductSelect
      {...props}
      name="name"
      onSearchResult={handleSearchResult}
      options={undefined}
      searchKey={'name'}
      withTopMargin={true}
    />
  );
};
