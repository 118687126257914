import { useCallback, useEffect } from 'react';
import { TextInput } from '@heidi-pay/heidi-component-library/components';
import { FormControl, FormHelperText } from '@mui/material';
import { useTranslation } from '@hooks';
import { StackedLabel } from '../../StackedLabel';
import { validationSchema } from './validationSchema';

interface ITextInputDocumentNumberOptions {
  disabled?: boolean;
  documentNumberFieldName?: string;
  documentValidationCountry: string;
  hidden?: boolean;
  labelOverride?: string;
  tooltip?: string;
  type?: string;
  withStackedLabel?: boolean;
}

interface ITextInputDocumentNumber {
  controlName?: string;
  error: string;
  inputRef?: React.Ref<HTMLInputElement>;
  name: string;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: ITextInputDocumentNumberOptions;
  setValue: (name: string, value: string) => void;
  trigger: (name?: string | string[] | undefined) => Promise<boolean>;
  value: string;
  watch: (name: string) => unknown;
}

export const TextInputDocumentNumber = ({
  controlName,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  setValue,
  trigger,
  value,
  watch,
}: ITextInputDocumentNumber) => {
  const { t } = useTranslation();

  const {
    disabled = false,
    documentNumberFieldName = 'documentType',
    hidden = false,
    labelOverride = undefined,
    tooltip,
    type = '',
    withStackedLabel = false,
  } = options;

  const documentType = watch(documentNumberFieldName);
  const label = t(
    `fields.textInputDocumentNumber.${
      labelOverride ? `${labelOverride}.` : ''
    }${name}.label`,
  );

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(controlName ?? name, e.target.value);
      onChange(e);
    },
    [controlName, name, onChange, setValue],
  );

  useEffect(() => {
    trigger(name);
  }, [documentType, name, trigger]);

  if (hidden) {
    return null;
  }

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ mt: '0.625rem' }}
      variant="outlined"
    >
      {withStackedLabel ? (
        <StackedLabel
          className="MuiFormLabel-external"
          label={label}
          name={name}
          tooltip={tooltip}
        />
      ) : null}
      <TextInput
        disabled={disabled}
        hasError={!!error}
        id={name}
        inputRef={inputRef}
        label={withStackedLabel ? undefined : label}
        name={name}
        onBlur={onBlur}
        onChange={handleOnChange}
        type={type}
        value={value}
      />
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
};

TextInputDocumentNumber.validation = validationSchema;
