import { Contract } from '@heidi-pay/heidi-component-library/components';
import { Box } from '@mui/material';
import { CopyBlock } from '../../../../components/CopyBlock';

interface ICustomerAgreementHeyLight {
  value: {
    uri: string;
  };
}
export const CustomerAgreementHeyLight = ({
  value,
}: ICustomerAgreementHeyLight) => (
  <>
    <CopyBlock
      i18nKey={'fields.customerAgreementHeyLight.content'}
      value={value}
    />
    <Box paddingBottom="14px" paddingTop="37px">
      <Contract height="141px" width="136px" />
    </Box>
  </>
);
