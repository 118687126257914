import { useCallback } from 'react';
import * as yup from 'yup';
import { Checkbox } from '@heidi-pay/heidi-component-library/components';
import {
  styled,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { useTranslation } from '@hooks';

import { CopyBlock } from '../../../../components';
import { IConfirmationCheckboxProps, IOptions } from './types';

const StyledFormControlLabel = styled(FormControlLabel)<IOptions>(
  ({ labelPlacement, textAlign }) => ({
    fontSize: '0.8rem',
    marginLeft: '0',
    marginRight: labelPlacement === 'start' ? '-11px' : '0',
    '& > .MuiFormControlLabel-label': {
      fontSize: '0.8rem',
      margin: '0.5rem 0',
      paddingRight: labelPlacement === 'start' ? '1rem' : '0',
      textAlign,
    },
    '& > .MuiCheckbox-root': {
      paddingLeft: labelPlacement === 'start' ? '9px' : '0',
    },
  }),
);

export function ConfirmationCheckbox({
  error = '',
  inputRef,
  name,
  onBlur,
  onChange,
  options = {},
  value,
}: IConfirmationCheckboxProps) {
  const {
    labelPlacement = 'start',
    overrideKey,
    textAlign = 'justify',
  } = options;
  const { t } = useTranslation();

  const formattedOverrideKey = overrideKey ? `${overrideKey}.` : '';
  const displayLabel = `fields.confirmationCheckbox.${formattedOverrideKey}${name}`;

  const handleChange = useCallback(
    event => onChange(event.target.checked),
    [onChange],
  );

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      variant="outlined"
    >
      <StyledFormControlLabel
        control={
          <Checkbox
            checked={value}
            error={error}
            inputRef={inputRef}
            name={name}
            onBlur={onBlur}
            onChange={handleChange}
          />
        }
        label={<CopyBlock i18nKey={displayLabel} />}
        labelPlacement={labelPlacement}
        textAlign={textAlign}
      />
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
}

ConfirmationCheckbox.validation = () =>
  yup.bool().oneOf([true], 'fields.confirmationCheckbox.required');
