export const Image = <img alt="" aria-hidden={true} />;

export const RejectionIcon = () => (
  <img alt="rejected-icon" src="/images/rejection.svg" />
);
export const SuccessIcon = () => (
  <img alt="success-icon" src="/images/success.svg" />
);

export const components = {
  image: Image,
  rejectedIcon: <RejectionIcon />,
  successIcon: <SuccessIcon />,
};
