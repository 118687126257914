import { IBaseComponent } from '../../types';
import { PaymentPlanBase } from './PaymentPlanBase';
import { IPaymentPlanHeyLightOptions } from './types';

// This component should replace the old PaymentPlanSwissCredit and will be mapped as an override of it
export const PaymentPlanSwissCredit = ({
  name,
  options = {},
}: IBaseComponent<IPaymentPlanHeyLightOptions, unknown>) => (
  <PaymentPlanBase
    name={name}
    options={{ ...options, variant: options.variant ?? 'swissCredit' }}
    rowSpacing="large"
  />
);
