import { StringSchema } from 'yup';

export const OnlyNameApropriateCharactesRegex = /[^a-zA-Z\s,.`'\-\p{L}]/gu;

interface IRegisterIbanValidation {
  errorTranslationKey: string;
  schema: StringSchema;
}

const isValidString = (input?: string) =>
  !input?.match(OnlyNameApropriateCharactesRegex);

export const registerNameValidation = ({
  errorTranslationKey,
  schema,
}: IRegisterIbanValidation) =>
  schema.test('name-field', errorTranslationKey, value => isValidString(value));
