import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

const extractError = (errors, name) =>
  errors[name]?.message || errors[name] || undefined;

export function isComplete({
  Component,
  defaultValue,
  errors,
  isDirty,
  name,
  value,
}) {
  // if we have a custom isComplete, what does it say for the current value?
  if (Component.isComplete) {
    return Component.isComplete(value);
  }

  // if we've typed, and if there's no error, it's complete
  if (isDirty && !extractError(errors, name)) {
    return true;
  }

  // if we have a non-empty default value, its pre-filled
  return !!defaultValue && JSON.stringify(defaultValue) !== JSON.stringify({});
}

export default function Field({
  clearErrors,
  content,
  control,
  errors,
  name,
  options,
  setValue,
  submit,
  submitAttempted,
  trigger,
  type,
  value: defaultValue,
  watch,
}) {
  const Component = type;
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ onBlur, onChange, ref, value }, { isDirty, isTouched }) => (
        <Component
          clearError={() => clearErrors(name)}
          complete={isComplete({
            isDirty,
            errors,
            name,
            Component,
            defaultValue,
            value,
          })}
          content={content}
          control={control}
          defaultValue={defaultValue}
          error={
            isTouched || submitAttempted
              ? extractError(errors, name)
              : undefined
          }
          inputRef={ref}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          options={options}
          setValue={setValue}
          submit={submit}
          submitAttempted={submitAttempted}
          trigger={trigger}
          value={value}
          watch={watch}
        />
      )}
    />
  );
}

Field.propTypes = {
  control: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  clearErrors: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.any.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape()),
  ]),
  content: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape(),
      ]),
    ),
    PropTypes.shape(),
  ]),
  setValue: PropTypes.func.isRequired,
  submitAttempted: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  trigger: PropTypes.func,
};

Field.defaultProps = {
  content: '',
  value: {},
  options: [],
};
