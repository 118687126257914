import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { setErrorState } from '@redux';
import { hasToken } from '@utils';
import { checkCurrentWaterfallAction } from '../../services/CurrentActionService';
import { LoadingBackdropContainer } from '../components/Styled';
import { PollingStatus } from '../services/types';

export interface ICheckCurrentAction {
  maximumRetryCount?: number;
}

export const CheckCurrentAction = ({
  maximumRetryCount = 10,
}: ICheckCurrentAction) => {
  const retryInterval = 5000;

  const dispatch = useDispatch();
  const [pollingRequestCount, setPollingRequestCount] = useState(1);

  const incrementRequestCount = useCallback(
    () => setPollingRequestCount(prev => prev + 1),
    [setPollingRequestCount],
  );

  const timeoutIntervalCoefficient = Math.max(
    Math.floor(pollingRequestCount / 3),
    1,
  );

  const timeoutInterval = timeoutIntervalCoefficient * retryInterval;

  useEffect(() => {
    if (!hasToken()) {
      window.location.assign('404');
      return () => null;
    }

    if (pollingRequestCount > maximumRetryCount) {
      dispatch(setErrorState('Too many requests, retry count exceeded'));
    }

    const interval = window.setInterval(async () => {
      try {
        const result = await checkCurrentWaterfallAction();
        if (result?.status === PollingStatus.Complete) {
          window.clearInterval(interval);
          window.location.assign(result.actionName);
        } else {
          incrementRequestCount();
        }
      } catch (error) {
        incrementRequestCount();
      }
    }, timeoutInterval);

    return () => clearInterval(interval);
  }, [
    dispatch,
    incrementRequestCount,
    maximumRetryCount,
    pollingRequestCount,
    timeoutInterval,
  ]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <LoadingBackdropContainer contained={true}>
      <CircularProgress aria-label="loading" />
    </LoadingBackdropContainer>
  );
};
