import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { setAmount, setProductSku } from '@instore/redux';
import { getProductDetails } from '@instore/redux/selectors';
import { TextInput } from '../TextInput';

export const InstoreProductSearchTextInput = ({
  complete,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options,
  setValue,
  value,
}) => {
  const { priceFieldName, productAdditionalDetailsField, productNameField } =
    options;

  const dispatch = useDispatch();
  const productDetails = useSelector(getProductDetails);

  useEffect(() => {
    if (value) {
      dispatch(setProductSku(value));
      // if sku changes, reset all values
      dispatch(setAmount({ amount: '', currency: '' }));
      setValue(priceFieldName, { amount: '', currency: '' });
      setValue(productNameField, '', { shouldDirty: true });
      setValue(productAdditionalDetailsField, {});
    }
  }, [
    dispatch,
    priceFieldName,
    productAdditionalDetailsField,
    productNameField,
    setValue,
    value,
  ]);

  useEffect(() => {
    if (productDetails?.expectedPrice) {
      setValue(priceFieldName, productDetails.expectedPrice);
    }

    if (productDetails?.name) {
      setValue(productNameField, productDetails.name, { shouldDirty: true });
    }

    if (productDetails?.additionalDetails) {
      setValue(productAdditionalDetailsField, productDetails.additionalDetails);
    }
  }, [
    priceFieldName,
    productAdditionalDetailsField,
    productNameField,
    productDetails,
    setValue,
  ]);

  return (
    <TextInput
      complete={complete}
      error={error}
      inputRef={inputRef}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      setValue={setValue}
      value={value}
    />
  );
};

InstoreProductSearchTextInput.propTypes = {
  complete: PropTypes.bool,
  error: PropTypes.string,
  inputRef: PropTypes.shape(),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  options: PropTypes.shape({
    priceFieldName: PropTypes.string,
    productAdditionalDetailsField: PropTypes.string,
    productNameField: PropTypes.string,
  }),
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

InstoreProductSearchTextInput.defaultProps = {
  complete: false,
  error: '',
  inputRef: undefined,
  options: {
    disabled: false,
  },
};
