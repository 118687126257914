import {
  enableSentryReplay,
  disableSentryReplay,
} from '../../../../utils/sentry';
import { IEmbeddedSvc } from '../types';
import { SalesforceChatEvent } from './enums';

export const addSalesforceChatEventHandlers = (embeddedSvc: IEmbeddedSvc) => {
  embeddedSvc.addEventHandler(
    SalesforceChatEvent.OnHelpButtonClick,
    disableSentryReplay,
  );
  embeddedSvc.addEventHandler(
    SalesforceChatEvent.OnChatConferenceEnded,
    enableSentryReplay,
  );
  embeddedSvc.addEventHandler(
    SalesforceChatEvent.OnChatEndedByChasitor,
    enableSentryReplay,
  );
  embeddedSvc.addEventHandler(
    SalesforceChatEvent.OnChatEndedByAgent,
    enableSentryReplay,
  );
};
