import { call } from '@utils';
import { IPollingResponse } from './types';

export async function checkPagolightInitialiseStatus(): Promise<IPollingResponse> {
  // http request retry disabled because the method is called on an interval so will be retried
  return call('/api/checkout-client/pagolight/initialise-status/', {
    method: 'GET',
    disableRetry: true,
  });
}
