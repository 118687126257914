import { bool, func, string } from 'prop-types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Checkbox, FormControlLabel, styled } from '@mui/material';

const InlineContiner = styled('div')`
  display: inline-flex;
`;

export const Signature = ({ label, onChange, value }) => (
  <InlineContiner>
    <ChevronRightIcon color="primary" fontSize="large" />
    <FormControlLabel
      control={<Checkbox checked={value} onChange={onChange} size="small" />}
      label={label}
    />
  </InlineContiner>
);

Signature.propTypes = {
  label: string.isRequired,
  value: bool.isRequired,
  onChange: func.isRequired,
};
