import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, func } from 'prop-types';
import { Box, ListItem, List, Typography } from '@mui/material';
import { useTranslation } from '@hooks';
import { getIsLoading, setIsLoading } from '@redux';
import { Feedback } from './components/Feedback';

export const IntroPagolightPro = ({ submit, submitAttempted }) => {
  const isLoading = useSelector(getIsLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isFailedToExecute = submitAttempted && !isLoading;

  useEffect(() => {
    if (!submitAttempted) {
      dispatch(setIsLoading(false));
      submit();
    }
  }, [dispatch, submit, submitAttempted]);

  const listItems = [
    {
      altText: 'card',
      imageSrc: '/images/card.svg',
      translationKey: 'fields.introPagolightPro.listItemCard',
    },
    {
      altText: 'bank',
      imageSrc: '/images/bank.svg',
      translationKey: 'fields.introPagolightPro.listItemIban',
    },
    {
      altText: 'documents',
      imageSrc: '/images/id_document.svg',
      translationKey: 'fields.introPagolightPro.listItemDocument',
    },
  ];

  return (
    <>
      <Box textAlign="center">
        <Typography mt={2} textAlign="center" variant="h6">
          {t('fields.introPagolightPro.title')}
        </Typography>

        <Typography mt={4} variant="body1">
          {t('fields.introPagolightPro.description')}
        </Typography>
      </Box>

      <Box mt={5}>
        <Feedback isFailedToExecute={isFailedToExecute} />
      </Box>

      <Box mt={8}>
        <Typography fontWeight="bold" variant="body1">
          {t('fields.introPagolightPro.listTitle')}
        </Typography>

        <List>
          {listItems.map((item, index) => (
            <ListItem key={item.imageSrc} sx={{ marginTop: index > 0 ? 4 : 1 }}>
              <img alt={item.altText} src={item.imageSrc} width="50px" />
              <Typography component="span" ml={2} variant="body1">
                {t(item.translationKey)}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
};

IntroPagolightPro.propTypes = {
  submit: func.isRequired,
  submitAttempted: bool.isRequired,
};
