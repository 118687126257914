import { BaseColour } from '@heidi-pay/heidi-component-library/enums';
import { Divider as MUIDivider, useTheme } from '@mui/material';
import { FeatureFlag, useFeatureFlags } from '@hooks';

interface IDividerProps {
  options?: {
    spaced?: boolean;
    themed?: boolean;
  };
}

export const Divider = ({ options }: IDividerProps) => {
  const theme = useTheme();
  const { flagEnabled } = useFeatureFlags();

  const isHeylightOverwriteByMerchantBrandingEnabled = flagEnabled(
    FeatureFlag.IsHeylightOverwriteByMerchantBrandingEnabled,
  );

  const dividerColor =
    options?.themed && isHeylightOverwriteByMerchantBrandingEnabled
      ? theme.palette.primary.main
      : BaseColour.Grey4;

  return (
    <MUIDivider
      sx={{
        my: options?.spaced ? 2 : 0,
        width: '100%',
        borderColor: dividerColor,
      }}
    />
  );
};
