import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Box, Collapse } from '@mui/material';
import { setSoftErrorCode } from '@redux';
import { useSoftError } from '../utils/useSoftError';

const SoftErrorAlertController = () => {
  const dispatch = useDispatch();
  const clearErrorCode = () => dispatch(setSoftErrorCode(null));
  const { isSoftError, softErrorTranslation } = useSoftError();

  useEffect(() => {
    if (isSoftError) {
      window.scrollTo({ behavior: 'smooth', top: 0 });
    }
  }, [isSoftError]);

  return (
    <Collapse in={isSoftError} sx={{ width: '100%', marginBottom: '0' }}>
      <Box pb={2} pt={1}>
        <Alert onClose={clearErrorCode} severity="error">
          {isSoftError ? softErrorTranslation : ''}
        </Alert>
      </Box>
    </Collapse>
  );
};

export default SoftErrorAlertController;
