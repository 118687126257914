import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRegisterScriptFromUri } from '@hooks';
import { getMetricsData } from '@redux';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    jbfa1215?: {
      pfhp: (domain: string, orgId: string, sessionId: string) => void;
    };
  }
}

const tmxUrl =
  'https://storage.googleapis.com/heidi-core.appspot.com/hpassets.js';

const MetricsLoader = memo(() => {
  const { domain, orgId, sessionId } = useSelector(getMetricsData) || {};
  const { isLoaded } = useRegisterScriptFromUri(tmxUrl);

  useEffect(() => {
    if (sessionId && domain && orgId && isLoaded) {
      window.jbfa1215?.pfhp(domain, orgId, sessionId);
    }
  }, [domain, orgId, sessionId, isLoaded]);

  return null;
});

MetricsLoader.displayName = 'MetricsLoader';
export default MetricsLoader;
