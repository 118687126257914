import { useState, useCallback } from 'react';
import { Box, styled } from '@mui/material';
import { useTranslation } from '@hooks';
import { AcceptedBlock } from './components/AcceptedBlock';
import { BottomSheet } from './components/BottomSheet';
import { SignatureContinueButton } from './components/SignatureContinueButton';
import { SignatureListContent } from './components/SignatureListContent';
import { ISignatureListProps } from './types';

const SignatureListContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const SignatureList = ({
  onChange,
  options,
  submit,
  value: signatures,
}: ISignatureListProps) => {
  const { signatureLegalTextBlocks } = options;

  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const signatureKeys = Object.keys(signatureLegalTextBlocks);
  const totalSignatures = signatureKeys.length;

  const getSignatureList = signatures.map(({ key, value }) => ({
    key,
    value,
    textContent: signatureLegalTextBlocks[key],
  }));

  const handleBackClick = useCallback(
    () => setCurrentIndex(prevIndex => Math.max(0, prevIndex - 1)),
    [],
  );

  const handleForwardClick = useCallback(
    () => setCurrentIndex(prevIndex => prevIndex + 1),
    [],
  );

  const handleContinue = useCallback(() => {
    const currentKey = signatureKeys[currentIndex];
    const newSignatures = signatures.map(signature =>
      signature.key === currentKey ? { ...signature, value: true } : signature,
    );

    onChange(newSignatures);

    const isLastSignature = currentIndex === totalSignatures - 1;

    if (isLastSignature) {
      const acceptedSignatures = Object.fromEntries(
        newSignatures.map(({ key, value }) => [key, value]),
      );
      submit?.(acceptedSignatures);
    } else {
      setCurrentIndex(prevIndex => prevIndex + 1);
    }
  }, [
    currentIndex,
    signatureKeys,
    totalSignatures,
    signatures,
    onChange,
    submit,
  ]);

  const currentSignature = getSignatureList[currentIndex];
  const isCurrentAccepted = currentSignature?.value;
  const isLastSignature = currentIndex === totalSignatures - 1;

  const bottomSheetTitle = t('fields.signatureList.title', {
    current: currentIndex + 1,
    total: totalSignatures,
  });

  return (
    <BottomSheet
      onBackClick={currentIndex > 0 ? handleBackClick : undefined}
      onForwardClick={
        !isLastSignature && isCurrentAccepted ? handleForwardClick : undefined
      }
      title={bottomSheetTitle}
    >
      <SignatureListContainer>
        <AcceptedBlock isCurrentAccepted={isCurrentAccepted} />
        <SignatureListContent currentSignature={currentSignature} />
        <SignatureContinueButton
          isCurrentAccepted={isCurrentAccepted}
          onContinue={handleContinue}
        />
      </SignatureListContainer>
    </BottomSheet>
  );
};
