import { useDispatch } from 'react-redux';
import { setIsLoading, setErrorState } from '@redux';
import { EnhancedError } from '@utils/http/EnhancedError';

interface IUseExecuteAsyncOptions {
  softErrorCode?: string;
}

export const useExecuteAsync = (
  func: () => unknown,
  { softErrorCode }: IUseExecuteAsyncOptions,
) => {
  const dispatch = useDispatch();

  return async () => {
    dispatch(setIsLoading(true));

    try {
      return await func();
    } catch (error) {
      if (softErrorCode) {
        (error as EnhancedError).softErrorCode = softErrorCode;
      }
      dispatch(setErrorState(error));
    } finally {
      dispatch(setIsLoading(false));
    }

    return null;
  };
};
