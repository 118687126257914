export const getGoogleAnalytics = token =>
  token
    ? `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', '${token}');
    
    window.onbeforeunload = function (e) {
        e.returnValue = 'You have some unsaved changes';
    
        // For Safari
        return 'You have some unsaved changes';
    };
  `
    : null;
