import delay from 'delay';
import { object } from 'yup';
import { SubFormWithType } from '../../transaction/components/types';

export const testFormHasErrors = async <T>(form?: SubFormWithType<T>) => {
  await delay(100); // Allow above validation trigger to run
  return !form?.hasErrors?.();
};

export const registerSubFormValidation =
  <T>(componentName: string, triggerOnSubmitOnly = false) =>
  () =>
    object()
      .transform((form: SubFormWithType<T>) => {
        const shouldTrigger = triggerOnSubmitOnly
          ? form.isSubmitAttempted?.()
          : true;

        if (shouldTrigger) {
          form.trigger?.();
        }
        const formValues = form.getValues?.();
        return {
          ...form,
          ...formValues, // Resolve form values during validation
        };
      })
      .test(componentName, 'ERROR', testFormHasErrors)
      .nullable();
