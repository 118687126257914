import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { CopyBlock } from '../../../../components/CopyBlock';
import { getMonthlyPayments } from '../../../../payment/redux';
import { SecciLink } from './components/SecciLink';

interface IDocument {
  key: string;
  uri: string;
}

interface IAdditionalDocumentsContainerProps {
  options: {
    documents: IDocument[];
    isUsedWithInstallmentSelector: boolean;
  };
}

export const AdditionalDocumentsContainer = ({
  options,
}: IAdditionalDocumentsContainerProps) => {
  const { documents, isUsedWithInstallmentSelector } = options;
  const monthlyPayments = useSelector(getMonthlyPayments);

  if (isUsedWithInstallmentSelector && !monthlyPayments) {
    return null;
  }

  return (
    <Box>
      <Box mt="20px">
        <CopyBlock i18nKey="fields.additionalDocumentsContainer.label" />
      </Box>
      {
        <Box mt="30px">
          <SecciLink numberOfInstallments={monthlyPayments} />
        </Box>
      }
      <Box my="10px">
        {documents.map(({ key, uri }) => (
          <Box key={key} my="10px">
            <CopyBlock
              i18nKey={`fields.additionalDocumentsContainer.documents.${key}`}
              value={{ uri }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
