import PropTypes from 'prop-types';
import delay from 'delay';
import { useExecuteWithCountdown } from '@hooks';
import { ProgressBar } from '../ProgressBar';

export const SpidCallbackProcessor = ({ onChange, options, submit }) => {
  const { timeoutMs = 5000 } = options;

  const executeChangeAndSubmit = async () => {
    const queryParameters = Object.fromEntries(
      new URLSearchParams(window.location.search),
    );

    onChange(queryParameters);

    await delay(200);

    submit();
  };

  const { countdown } = useExecuteWithCountdown({
    callback: executeChangeAndSubmit,
    timeoutMs,
  });

  return <ProgressBar countdown={countdown} />;
};

SpidCallbackProcessor.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    timeoutMs: PropTypes.number,
  }),
  submit: PropTypes.func.isRequired,
};

SpidCallbackProcessor.defaultProps = {
  options: {
    timeoutMs: 5000,
  },
};
