import { useSelector } from 'react-redux';
import { getFeatureFlags } from '@redux';
import { Actions } from '@utils';
import { getCurrentAction } from '../../../transaction/redux';

const hideHelpWidgetForActions = [
  Actions.initialiseHeylightItalianBNPL,
  Actions.initialiseHeylightItalianCredit,
  Actions.initialiseHeylightSwiss,
];

export const useShowHelpWidget = () => {
  const { isHelpWidgetEnabled, isSalesforceLiveChatEnabled } =
    useSelector(getFeatureFlags);
  const currentAction = useSelector(getCurrentAction);

  const shouldHideWidget = hideHelpWidgetForActions.includes(currentAction);

  return {
    showHelpWidget:
      isHelpWidgetEnabled && !isSalesforceLiveChatEnabled && !shouldHideWidget,
    isSalesforceLiveChatEnabled,
    shouldHideWidget,
  };
};
