import { useState } from 'react';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';
import { RadioButtonGroup } from '@heidi-pay/heidi-component-library/components';
import { useTranslation } from '@hooks';
import { CopyBlock } from '../../../../../components/CopyBlock';
import { ComponentTypes } from '../../../../../utils';
import TextBlock from '../../../TextBlock';
import { generateRadioItems } from '../../ButtonGroupPicker/utils/generateRadioItems';
import { NumberPicker } from '../../NumberPicker';
import { SelectPicker } from '../../SelectPicker';
import { TextInputNumeric } from '../../TextInputNumeric';
import { notApplicableFieldValue } from '../utils/constants';
import { PoliticallyExposedPersonDialog } from './PoliticallyExposedPersonDialog';

export const ControlledField = ({
  control,
  errors,
  id,
  name,
  options,
  type,
}) => {
  const {
    field: { onBlur, onChange, ref, value },
  } = useController({
    name,
    control,
  });
  const { t } = useTranslation();

  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const dialogComponentMap = {
    politicallyExposedIndividual: PoliticallyExposedPersonDialog,
  };
  const Dialog = dialogComponentMap[name] ?? null;

  // Hide the field when it's not applicable. This is controlled by the container
  if (value === notApplicableFieldValue) {
    return null;
  }

  if (type === ComponentTypes.TextBlock) {
    return (
      <TextBlock
        error={errors[name]?.message}
        name={name}
        onChange={onChange}
        options={options}
        value={value}
      />
    );
  }

  if (type === ComponentTypes.ButtonGroupPicker) {
    const BaseTranslationKey = `fields.buttonGroupPicker.${name}`;
    const radioItems = generateRadioItems({
      baseTranslationKey: BaseTranslationKey,
      labels: options.items,
      t,
    });

    return (
      <>
        <RadioButtonGroup
          error={errors[name]?.message ? t(errors[name]?.message) : null}
          id={name}
          label={
            <CopyBlock
              i18nKey={`${BaseTranslationKey}.label`}
              props={{
                ...options,
                onClick: Dialog ? () => setDialogIsOpen(true) : null,
              }}
            />
          }
          onChange={onChange}
          radioItems={radioItems}
          sx={{ paddingTop: '12px', width: '100%', marginLeft: '2px' }}
          value={value}
        />
        {Dialog ? (
          <Dialog isOpen={dialogIsOpen} setDialogIsOpen={setDialogIsOpen} />
        ) : null}
      </>
    );
  }

  if (type === ComponentTypes.TextInputNumeric) {
    return (
      <TextInputNumeric
        complete={!!value}
        error={errors[name]?.message}
        id={id}
        inputRef={ref}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        options={options}
        value={value}
      />
    );
  }

  if (type === ComponentTypes.NumberPicker) {
    return (
      <NumberPicker
        complete={!!value}
        error={errors[name]?.message}
        id={id}
        inputRef={ref}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        options={options}
        value={value}
      />
    );
  }

  return (
    <SelectPicker
      complete={!!value}
      error={errors[name]?.message}
      id={id}
      inputRef={ref}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      value={value}
    />
  );
};

ControlledField.propTypes = {
  control: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape(),
  ]).isRequired,
  type: PropTypes.string.isRequired,
  errors: PropTypes.shape(),
};

ControlledField.defaultProps = {
  errors: {},
};
