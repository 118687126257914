import { createActions, handleActions } from 'redux-actions';

import { resetErrorState, setErrorState } from '@redux';
import { actionCreator } from '../../utils';

import * as addressService from '../services/AddressService';

export const { setConvertingState } = createActions({
  SET_CONVERTING_STATE: isConverting => ({ isConverting }),
});

export const convertAddress = actionCreator(
  'convertAddress',
  details => async dispatch => {
    dispatch(setConvertingState(true));
    dispatch(resetErrorState());
    try {
      const address = await addressService.convertAddress(details);
      dispatch(setConvertingState(false));
      return address;
    } catch (error) {
      dispatch(setConvertingState(false));
      dispatch(setErrorState(error));
    }

    return {};
  },
);

export const getIsConverting = state => state.address.isConverting;

export default handleActions(
  {
    [setConvertingState]: (state, { payload: { isConverting } }) => ({
      ...state,
      isConverting,
    }),
  },
  {
    isConverting: false,
  },
);
