import { useSelector } from 'react-redux';
import { BaseButton } from '@heidi-pay/heidi-component-library/components/Buttons/BaseButton';
import { Typography, styled } from '@mui/material';
import { useTranslation } from '@hooks';
import { getIsLoading } from '@redux';
import { ContinueButtonPortal } from './ContinueButtonPortal';

export interface IContinueButtonOptions {
  overrideTextKey?: string;
}

interface IContinueButtonProps {
  options?: IContinueButtonOptions;
  submit: () => void;
  width?: string;
}

const StyledButton = styled(BaseButton, {
  shouldForwardProp: (prop): boolean => prop !== 'width',
})<{ width?: string }>(({ width }) => ({
  maxWidth: '327px',
  width: width || 'auto',
  textTransform: 'uppercase',
}));

export const ContinueButton = ({
  options = {},
  submit,
  width,
}: IContinueButtonProps) => {
  const { t } = useTranslation();
  const isLoading = useSelector(getIsLoading);
  const translationKey = `fields.continueButton${
    options.overrideTextKey ? `.${options.overrideTextKey}` : ''
  }.label`;

  return (
    <ContinueButtonPortal>
      <StyledButton
        color="primary"
        disabled={isLoading}
        onClick={submit}
        type="submit"
        variant="contained"
        width={width}
      >
        <Typography variant="button">{t(translationKey)}</Typography>
      </StyledButton>
    </ContinueButtonPortal>
  );
};

ContinueButton.formatting = () => undefined;
