import { Translate } from '@heidi-pay/heidi-common-fe/types';
import {
  IPaymentRowProps,
  RowType,
} from '@heidi-pay/heidi-component-library/components/PaymentPlanTable';
import { IUseFormatter } from '../../../../../utils/formatters/types';
import { IScheduleData } from './types';

export function getItalyBnplPaymentPlanFields(
  { currency, monthlyPayments, payMonthly, payNow, total }: IScheduleData,
  formatters: IUseFormatter,
  translate: Translate,
): IPaymentRowProps[] | null {
  if (!currency) {
    return null;
  }

  const { formatAmountWithCurrency } = formatters;
  const baseKey = 'fields.paymentPlanHeyLight';
  const isPayNowAmount = Number(payNow?.amount) > 0;

  return [
    ...(isPayNowAmount
      ? [
          {
            displayValueBold: true,
            label: translate(`${baseKey}.italy.payNow`),
            value: formatAmountWithCurrency(payNow.amount, currency),
          },
        ]
      : [
          {
            displayValueBold: true,
            label: translate(`${baseKey}.italy.monthlyPayment`),
            value: formatAmountWithCurrency(payMonthly, currency),
          },
        ]),
    {
      displayValueBold: true,
      label: translate(`${baseKey}.italy.remainingPayments`),
      value: `${formatAmountWithCurrency(payMonthly, currency)} x ${
        monthlyPayments - 1
      }`,
    },
    {
      label: '-',
      rowType: RowType.Divider,
    },
    {
      displayLabelBold: true,
      displayValueBold: true,
      label: translate(`${baseKey}.common.total`),
      labelVariant: 'h6',
      rowType: RowType.ScheduleToggle,
      value: formatAmountWithCurrency(total, currency),
      valueVariant: 'h6',
    },
  ];
}
