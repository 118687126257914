import { ChangeEvent, ReactNode, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButton } from '@heidi-pay/heidi-component-library/components/Buttons/BaseButton';
import {
  BaseColour,
  PaletteColorOptions,
} from '@heidi-pay/heidi-component-library/enums';
import CameraAltOutlined from '@mui/icons-material/CameraAltOutlined';
import UploadFileOutlined from '@mui/icons-material/UploadFileOutlined';
import { Box, styled, Typography } from '@mui/material';

const BaseTransKey = 'fields.mitekDocumentOcrContainer';

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'flex-start',
  padding: '9px 11px',
  textAlign: 'left',
  justifyContent: 'center',
});

const IconWrapper = styled(Box)({
  flexShrink: 0,
  height: 30,
  width: 30,
  marginRight: 16,
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface IToggleButtonProps {
  children?: ReactNode;
  iconComponent: ReactNode;
  isFileSelect?: boolean;
  label: string;
  onClick?: () => void;
  subLabel: string;
}

const ToggleButton = ({
  children,
  iconComponent,
  isFileSelect = false,
  label,
  onClick,
  subLabel,
}: IToggleButtonProps) => (
  <BaseButton
    color={PaletteColorOptions.ToggleUnselected}
    onClick={onClick}
    tabIndex={-1}
    variant="contained"
    {...(isFileSelect ? { component: 'label', role: undefined } : {})}
  >
    <IconWrapper>{iconComponent}</IconWrapper>
    <ContentWrapper>
      <Typography
        color={PaletteColorOptions.ToggleUnselected}
        fontWeight="bold"
        variant="body1"
      >
        {label}
      </Typography>
      <Typography color={BaseColour.Grey1} variant="caption">
        {subLabel}
      </Typography>
    </ContentWrapper>
    {children}
  </BaseButton>
);

export interface IToggleButtonsProps {
  onCaptureButtonClick: () => void;
  onUploadButtonClick: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const ToggleButtons = ({
  onCaptureButtonClick,
  onUploadButtonClick,
}: IToggleButtonsProps) => {
  const fileSelectorRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();

  const handleFileSelection = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onUploadButtonClick(event);

      if (fileSelectorRef.current) {
        fileSelectorRef.current.value = '';
      }
    },
    [onUploadButtonClick],
  );

  return (
    <>
      <ToggleButton
        iconComponent={
          <UploadFileOutlined
            sx={{ color: 'black', height: '32px', width: '32px' }}
          />
        }
        isFileSelect={true}
        label={t(`${BaseTransKey}.selectFileButton.label`)}
        subLabel={t(`${BaseTransKey}.selectFileButton.subLabel`)}
      >
        <VisuallyHiddenInput
          multiple={false}
          onChange={handleFileSelection}
          ref={fileSelectorRef}
          role="button"
          sx={{ width: '100%' }}
          type="file"
        />
      </ToggleButton>
      <ToggleButton
        iconComponent={
          <CameraAltOutlined
            sx={{ color: 'black', height: '32px', width: '32px' }}
          />
        }
        label={t(`${BaseTransKey}.captureImageButton.label`)}
        onClick={onCaptureButtonClick}
        subLabel={t(`${BaseTransKey}.captureImageButton.subLabel`)}
      />
    </>
  );
};
