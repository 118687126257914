import { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormControl, useTheme, styled } from '@mui/material';
import { useTranslation } from '@hooks';
import {
  getOrigin,
  initialisePaymentsIframe,
} from './PaymentGatewayContainerUtil';

const StyledIframe = styled('iframe')({
  flex: '1 1 auto',
  marginTop: '1rem',
  border: 'none',
});

export default function PaymentGatewayContainer({
  options,
  submit,
  value: { allowedOrigins = [], iframeSrc: url },
}) {
  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const iframeRef = useRef();
  const translationOverrideKey = options?.overrideTextKey
    ? `.${options.overrideTextKey}`
    : '';

  const onMessage = useCallback(
    ({ data, origin }) => {
      if (
        !allowedOrigins.some(allowedOrigin => origin.startsWith(allowedOrigin))
      ) {
        return;
      }

      if (data === 'opened') {
        submit();
        return;
      }
      if (!data.message) {
        return;
      }

      switch (data.message) {
        case 'init': {
          const continueButtonText = t(
            `fields.continueButton${translationOverrideKey}.label`,
          );
          initialisePaymentsIframe({
            continueButtonText,
            iframeRef,
            theme,
            url,
          });
          break;
        }
        case 'resize':
        case 'css': {
          if (data.height && iframeRef.current) {
            iframeRef.current.height = `${data.height}px`;
          }
          break;
        }

        case 'opened': {
          submit();
          break;
        }

        default: {
          // Unhandled
        }
      }
    },
    [allowedOrigins, submit, t, theme, translationOverrideKey, url, iframeRef],
  );

  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, [onMessage]);

  // submit changes in locale to the repayments iframe
  // (but do not submit it on first load!)
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      return;
    }
    const iframeWindow = iframeRef.current.contentWindow;
    iframeWindow.postMessage(
      { message: 'changeLocale', locale: i18n.language },
      getOrigin(url),
    );
  }, [i18n.language, mounted, url]);

  return (
    <>
      <FormControl
        component="fieldset"
        fullWidth={true}
        sx={{ flex: '1 1 auto' }}
      >
        <StyledIframe
          key={url}
          ref={iframeRef}
          src={url}
          title={t('fields.paymentGatewayContainer.label')}
        />
      </FormControl>
    </>
  );
}

PaymentGatewayContainer.propTypes = {
  value: PropTypes.shape({
    iframeSrc: PropTypes.string.isRequired,
    allowedOrigins: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  submit: PropTypes.func.isRequired,
  options: PropTypes.shape(),
};

PaymentGatewayContainer.defaultProps = {
  options: undefined,
};

PaymentGatewayContainer.formatting = () => undefined;
