import Alert from '@mui/material/Alert';

import { CopyBlock } from '../../../../components';
import { IAlertText } from './types';

export const AlertText = ({ name, options, value }: IAlertText) => {
  const { alertSeverity = 'info' } = options;
  const { amount } = value ?? {};

  return (
    <Alert severity={alertSeverity} sx={{ width: '100%' }}>
      <CopyBlock
        i18nKey={`fields.alertText.${name}.content`}
        value={{ amount }}
      />
    </Alert>
  );
};
