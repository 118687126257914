import { Translate } from '@heidi-pay/heidi-common-fe/types';
import {
  IPaymentRowProps,
  RowType,
} from '@heidi-pay/heidi-component-library/components/PaymentPlanTable';
import { IUseFormatter } from '../../../../../utils/formatters/types';
import { IScheduleData } from './types';

function conditionalRender(condition: boolean, object: IPaymentRowProps) {
  return condition ? [object] : [];
}

export const getSwissBnplPaymentPlanFields = (
  { currency, numberOfInstallments, payMonthly, payNow, total }: IScheduleData,
  formatters: IUseFormatter,
  translate: Translate,
) => {
  if (!currency) {
    return null;
  }

  const baseKey = 'fields.paymentPlan.rows';
  const { formatAmountWithCurrency } = formatters;

  const fields: IPaymentRowProps[] = [
    ...conditionalRender(parseFloat(payNow.amount) > 0, {
      displayValueBold: true,
      label: translate(`${baseKey}.payNow`),
      value: formatAmountWithCurrency(payNow.amount, currency),
    }),
    {
      displayValueBold: true,
      label: translate(`${baseKey}.payMonthly`),
      value: `${formatAmountWithCurrency(
        payMonthly,
        currency,
      )} x ${numberOfInstallments}`,
    },
    {
      displayValueBold: true,
      label: translate(`${baseKey}.interest`),
      value: `${formatAmountWithCurrency(0, currency)}`,
    },
    {
      label: '-',
      rowType: RowType.Divider,
    },
    {
      displayLabelBold: true,
      displayValueBold: true,
      label: translate(`${baseKey}.total`),
      rowType: RowType.ScheduleToggle,
      value: formatAmountWithCurrency(total, currency),
    },
  ];

  return fields.filter(field => field?.value !== null);
};
