export enum SalesforceChatEvent {
  OnAgentJoinedConference = 'onAgentJoinedConference',
  OnAgentLeftConference = 'onAgentLeftConference',
  OnAgentMessage = 'onAgentMessage',
  OnAgentRichMessage = 'onAgentRichMessage',
  OnChasitorMessage = 'onChasitorMessage',
  OnChatConferenceEnded = 'onChatConferenceEnded',
  OnChatConferenceInitiated = 'onChatConferenceInitiated',
  OnChatEndedByAgent = 'onChatEndedByAgent',
  OnChatEndedByChasitor = 'onChatEndedByChasitor',
  OnChatEndedByChatbot = 'onChatEndedByChatbot',
  OnChatReconnectSuccessful = 'onChatReconnectSuccessful',
  OnChatRequestSuccess = 'onChatRequestSuccess',
  OnChatTransferInitiated = 'onChatTransferInitiated',
  OnChatTransferSuccessful = 'onChatTransferSuccessful',
  OnConnectionError = 'onConnectionError',
  OnHelpButtonClick = 'onHelpButtonClick',
  OnIdleTimeoutClear = 'onIdleTimeoutClear',
  OnIdleTimeoutOccurred = 'onIdleTimeoutOccurred',
  OnIdleTimeoutWarningStart = 'onIdleTimeoutWarningStart',
  OnQueueUpdate = 'onQueueUpdate',
}
