import axios from 'axios';
import { getToken } from '../sessionToken';
import { HttpMethod } from './enums';
import { ICallOptions, IParsedHttpResponse } from './types';

export const jsonFetch = async <TResponse = unknown>(
  url: string,
  options: ICallOptions,
): Promise<IParsedHttpResponse<TResponse>> => {
  const token = getToken();
  const headers = {
    'content-type': 'application/json',
    ...(token ? { Authorization: `Token ${token}` } : {}),
    ...options?.headers,
  };

  const response = await axios.request<TResponse>({
    data: options?.body,
    headers,
    method: options?.method || HttpMethod.Get.toString(),
    timeout: options.timeout ?? 1000 * 100, // The timeout matches cloudflare's default connection timeout
    url,
  });

  return {
    originalResponse: response,
    parsedData: response?.data,
  };
};
