import { IInstoreState } from '../index';

interface IIncrementStateField {
  fieldName: keyof IInstoreState;
  incrementAmount?: number;
  state: IInstoreState;
}

export const incrementStateField = ({
  fieldName,
  incrementAmount = 1,
  state,
}: IIncrementStateField): IInstoreState => ({
  ...state,
  [fieldName]: ((state?.[fieldName] as number) ?? 0) + incrementAmount,
});
