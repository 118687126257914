import { node } from 'prop-types';
import { ErrorBoundary } from '@sentry/react';
import ErrorFallback from '../ErrorFallback';

export const SentryErrorBoundary = ({ children }) => (
  <ErrorBoundary fallback={<ErrorFallback />}>{children}</ErrorBoundary>
);

SentryErrorBoundary.propTypes = {
  children: node.isRequired,
};
