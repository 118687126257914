export class TranslationNotFoundError extends Error {
  key: string;

  lang: string;

  constructor(key: string, lang: string) {
    super(`Translation not found for key ${key} and language ${lang}`);
    this.name = 'TranslationNotFoundError';
    this.key = key;
    this.lang = lang;
  }
}
