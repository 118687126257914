import { func, shape, string } from 'prop-types';
import { Alert, FormControl, FormHelperText, Typography } from '@mui/material';
import { useTranslation } from '@hooks';
import { PdfTile } from './PdfTile';

export const CustomerAgreementBlock = ({
  customerAgreementUri,
  error,
  onOpened,
}) => {
  const { t } = useTranslation();

  return (
    <FormControl
      error={!!error?.message}
      fullWidth={true}
      sx={{ marginBottom: 4 }}
    >
      <Alert severity="info" variant="outlined">
        <Typography fontWeight="bold" mb={2} textAlign="left">
          {t(
            'fields.customerAgreementPagolightPro.customerAgreementBlock.subtitle',
          )}
        </Typography>
        <PdfTile
          documentUri={customerAgreementUri}
          label={t(
            'fields.customerAgreementPagolightPro.customerAgreementBlock.tileLabel',
          )}
          onOpened={onOpened}
        />
      </Alert>
      <FormHelperText>{t(error?.message)}</FormHelperText>
    </FormControl>
  );
};

CustomerAgreementBlock.propTypes = {
  customerAgreementUri: string.isRequired,
  error: shape(),
  onOpened: func.isRequired,
};

CustomerAgreementBlock.defaultProps = {
  error: {},
};
