import PropTypes from 'prop-types';
import { useExecuteWithCountdown } from '@hooks';
import { ProgressBar } from '../ProgressBar';

export const RedirectComponent = ({ options, value: uri }) => {
  const { displayProgress = true, timeoutMs = 5000 } = options;

  const { countdown } = useExecuteWithCountdown({
    callback: () => {
      window.location.assign(uri);
    },
    prematureExecutionTimeMs: 200,
    timeoutMs: timeoutMs || 5000,
  });

  if (!displayProgress) {
    return null;
  }

  return <ProgressBar countdown={countdown} />;
};

RedirectComponent.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.shape({
    displayProgress: PropTypes.bool,
    timeoutMs: PropTypes.number,
  }),
};

RedirectComponent.defaultProps = {
  options: {
    displayProgress: true,
    timeoutMs: 5000,
  },
};
