import PropTypes from 'prop-types';
import {
  Box,
  styled,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { useTranslation } from '@hooks';
import { CopyBlock } from '../../components/CopyBlock';
import { IconMapping } from '../../utils/enums';

const StyledIcon = styled('img')({
  marginBottom: '3rem',
  marginLeft: '1rem',
  marginRight: '1rem',
  width: '50px',
});

const StyledRadioGroup = styled(RadioGroup)({
  justifyContent: 'space-evenly',
  marginTop: '0.5rem',
});

const StyledFormLabel = styled(FormLabel, {
  shouldForwardProp: prop => prop !== 'isLargeText',
})(({ isLargeText }) => ({
  fontSize: isLargeText ? '1rem' : '.875rem',
  '&.Mui-focused, &.Mui-error': {
    color: '#333',
  },
}));

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: prop =>
    prop !== 'isLargeText' && prop !== 'withSpaceBetween',
})(({ isLargeText, theme, withSpaceBetween }) => ({
  '& .MuiFormControlLabel-label': {
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    fontSize: isLargeText ? '1rem' : '.875rem !important',
  },
  '& .Mui-checked svg:nth-child(2)': {
    color: theme.palette.primary.main,
  },
  marginBottom: withSpaceBetween ? '3rem' : '0',
}));

export default function RadioButtons({
  isLargeText = false,
  name,
  onBlur,
  onChange,
  options,
  overrideKey,
  stacked = false,
  translationAttributes,
  value,
  withIcons,
  withSpaceBetween = false,
}) {
  const { t } = useTranslation();
  const overrideLabel = overrideKey ? `${overrideKey}.` : '';

  return (
    <FormControl component="fieldset" fullWidth={true} size="small">
      <StyledFormLabel component="legend" isLargeText={isLargeText}>
        {t(`fields.radioButtons.${name}.label`)}
      </StyledFormLabel>
      <StyledRadioGroup
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        row={!stacked}
        value={value}
      >
        {options.map(option => (
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            key={option}
            width={'100%'}
          >
            <StyledFormControlLabel
              checked={value === option}
              control={<Radio />}
              isLargeText={isLargeText}
              key={option}
              label={
                <CopyBlock
                  component={'span'}
                  i18nKey={`fields.radioButtons.${overrideLabel}${name}.options.${option}`}
                  value={translationAttributes}
                />
              }
              value={option}
              withSpaceBetween={withSpaceBetween}
            />
            {withIcons ? (
              <StyledIcon
                alt={IconMapping[option]?.altText}
                src={IconMapping[option]?.src}
              />
            ) : null}
          </Box>
        ))}
      </StyledRadioGroup>
    </FormControl>
  );
}

RadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  stacked: PropTypes.bool,
};

RadioButtons.defaultProps = {
  stacked: false,
};
