import { useState } from 'react';
import { Grid, Link, ListItemText, Typography } from '@mui/material';
import { useTranslation, useExecuteAsync } from '@hooks';
import { SoftErrorKeys } from '@utils';
import { getPagolightProSecciUri } from '../../services/PagolightProSecciUriService';

interface IDocumentLinkMap {
  [key: number]: string;
}

interface ISecciLinkOptions {
  numberOfInstallments?: number;
}

interface ISecciLink {
  numberOfInstallments?: number;
  options?: ISecciLinkOptions;
}

export const SecciLink = ({ numberOfInstallments, options }: ISecciLink) => {
  const { t } = useTranslation();

  const numberOfInstallmentsUnified =
    options?.numberOfInstallments ?? numberOfInstallments;

  const [documentsMap, setDocumentsMap] = useState<IDocumentLinkMap>({});

  const openDocumentInNewTab = async () => {
    if (!numberOfInstallmentsUnified) {
      throw Error(
        'Number of installments not provided to SecciLink component.',
      );
    }

    const documentUri = documentsMap[numberOfInstallmentsUnified];

    if (documentUri) {
      window.open(documentUri, '_blank');
    } else {
      const { secciPdfUrl } = await getPagolightProSecciUri(
        numberOfInstallmentsUnified,
      );
      setDocumentsMap(previous => ({
        ...previous,
        [numberOfInstallmentsUnified]: secciPdfUrl,
      }));
      window.open(secciPdfUrl, '_blank');
    }
  };

  const handleOnClick = useExecuteAsync(openDocumentInNewTab, {
    softErrorCode: SoftErrorKeys.retry,
  });

  return (
    <Grid container={true} pb={2} pt={4} textAlign="center">
      <ListItemText
        primary={
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            color="#000"
            component="button"
            fontWeight="500"
            onClick={handleOnClick}
            underline="always"
          >
            {t('fields.secciLink.title')}
          </Link>
        }
        secondary={
          <Typography
            color="text.primary"
            component="span"
            fontWeight="normal"
            variant="subtitle2"
          >
            {t('fields.secciLink.subtitle')}
          </Typography>
        }
      />
    </Grid>
  );
};
