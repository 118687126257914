import { createSelector } from 'reselect';

export const getCurrentAction = state => state.transaction.currentAction;

export const getCurrentStep = createSelector(
  getCurrentAction,
  state => state.transaction.steps,
  (currentAction, steps) => steps[currentAction] ?? {},
);

export const getCurrentStepNumber = createSelector(
  getCurrentStep,
  currentStep => currentStep?.meta?.currentStep ?? 0,
);

export const getRoutes = state => state.transaction.routes;

export const getTotalNumberOfSteps = createSelector(
  getCurrentStep,
  currentStep => currentStep?.meta?.totalSteps ?? 0,
);

export const getPathToActionMap = createSelector(getRoutes, routes =>
  Object.fromEntries(
    Object.entries(routes).map(([key, value]) => [value, key]),
  ),
);

export const getActionFromPathResolver = createSelector(
  getPathToActionMap,
  pathToActionMap => path => pathToActionMap[path],
);

export const getPathFromActionResolver = createSelector(
  getRoutes,
  routes => actionName => routes[actionName],
);

export const getTranslationForCurrentAction = createSelector(
  getCurrentAction,
  state => state.transaction.translatedSteps,
  (currentAction, translatedSteps) => translatedSteps[currentAction] ?? '',
);
