import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

export const ControlledField = ({
  Component,
  control,
  controlName,
  error,
  id,
  name,
  onSearchResult,
  options,
  setValue,
  trigger,
}) => {
  const {
    field: { onBlur, onChange, ref, value },
  } = useController({
    name: controlName,
    control,
    defaultValue: '',
  });

  return (
    <Component
      complete={!!value}
      controlName={controlName}
      error={error}
      id={id}
      inputRef={ref}
      key={name}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onSearchResult={onSearchResult}
      options={options}
      setValue={setValue}
      trigger={trigger}
      value={value}
    />
  );
};

ControlledField.propTypes = {
  control: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape(),
  ]),
  errors: PropTypes.shape(),
};

ControlledField.defaultProps = {
  errors: {},
  options: {},
};
