import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Field from '../../../../components/Field';
import { registerSubFormValidation } from '../../../../utils';

import fieldToComponentMapping from './fieldToComponentMapping';

function Unknown() {
  return null;
}

const getComponentByType = type => {
  const component = fieldToComponentMapping[type] ?? Unknown;
  return component.lazy ?? component;
};

export function VisibilityController(props) {
  const {
    onChange,
    options: visibilityOptions,
    submitAttempted,
    watch,
  } = props;
  const { hiddenField } = visibilityOptions;
  const {
    content,
    name: hiddenFieldName,
    options,
    type,
    value: defaultValue,
  } = hiddenField;

  const rules = visibilityOptions.watchRules.reduce(
    (prev, rule) => ({ ...prev, [rule.fieldName]: rule.visibleForValues }),
    {},
  );

  const [hasLoaded, setHasLoaded] = useState(false);

  const HiddenComponent = getComponentByType(type);

  const fieldKeys = Object.keys(rules);
  const fieldValue = watch(fieldKeys);

  // Validation only triggers if there's a default value in the parent form
  // We don't set default values in the parent form because the fields are dynamic
  // So the validation will not trigger when submitting without touching the field
  // This effect will force the form to re-render with a default value...
  useEffect(() => {
    if (!hasLoaded) {
      setHasLoaded(true);
    }
  }, [hasLoaded]);

  const shouldRenderComponent = fieldKeys.some(key =>
    rules[key].includes(fieldValue[key]),
  );

  const validationSchema = yup.object().shape({
    [hiddenFieldName]:
      HiddenComponent?.validation(hiddenFieldName, options) ??
      yup.string().nullable(),
  });

  const { control, errors, getValues, setValue, trigger } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: { [hiddenFieldName]: defaultValue },
  });

  useEffect(() => {
    // Return the value as form functions - for use in the validator
    onChange({
      getValues,
      // The only way to conditionally apply Yup validation is via the .when function
      // But that can only be used to conditionally validate based onfields within the same form
      // Below is a workarround for the yup validation always running even when the field is hidden
      hasErrors: () =>
        shouldRenderComponent ? Object.values(errors).length !== 0 : false,
      isSubmitAttempted: () => submitAttempted,
      trigger,
    });
  }, [
    getValues,
    onChange,
    trigger,
    errors,
    submitAttempted,
    shouldRenderComponent,
  ]);

  if (!shouldRenderComponent) {
    return null;
  }

  return (
    <Field
      content={content}
      control={control}
      errors={errors}
      key={`${hiddenFieldName}-${JSON.stringify(options)}-${JSON.stringify(
        defaultValue,
      )}`}
      name={hiddenFieldName}
      options={options}
      setValue={setValue}
      submitAttempted={submitAttempted}
      type={getComponentByType(type)}
      value={defaultValue}
      watch={watch}
    />
  );
}

VisibilityController.validation = registerSubFormValidation(
  'visibilityController',
);
