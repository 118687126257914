import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { TextField, FormControl, FormHelperText } from '@mui/material';
import { useTranslation } from '@hooks';

export const ControlledTextField = ({
  control,
  errors,
  id,
  name,
  submitAttempted,
}) => {
  const { t } = useTranslation();

  const shouldDisplayError = submitAttempted && !!errors[name]?.message;

  return (
    <Controller
      control={control}
      name={name}
      render={({ onBlur, onChange, ref, value }) => (
        <FormControl error={shouldDisplayError} fullWidth={true}>
          <TextField
            disabled={true}
            error={shouldDisplayError}
            id={id}
            inputRef={ref}
            label={t(`fields.addressContainerItalianPostOcr.${name}.label`)}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            size="small"
            value={value?.displayValue || ''}
          />
          {shouldDisplayError ? (
            <FormHelperText>
              {t(`fields.addressContainerItalianPostOcr.${name}.required`)}
            </FormHelperText>
          ) : null}
        </FormControl>
      )}
    />
  );
};

ControlledTextField.propTypes = {
  control: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.shape(),
  submitAttempted: PropTypes.bool,
};

ControlledTextField.defaultProps = {
  errors: false,
  submitAttempted: false,
};
