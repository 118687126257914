import { useEffect } from 'react';
import { SplashScreenAlt } from '@heidi-pay/heidi-component-library/components';
import { styled } from '@mui/material';
import { MerchantRedirectLink } from '@components';
import { useIntervalCountdown, useTranslation } from '@hooks';

interface IIntroScreenSwiss {
  submit: () => void;
}

export const StyledMerchantRedirectContainer = styled('div')`
  position: fixed;
  width: 100vw;
  bottom: 0.5vh;
  z-index: 10002;
`;

export const StyledSplashScreenContainer = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  zindex: 10000;
`;

export const IntroScreenSwiss = ({ submit }: IIntroScreenSwiss) => {
  const isCounting = useIntervalCountdown({ duration: 1500 });
  useEffect(() => {
    if (!isCounting) {
      submit();
    }
  }, [isCounting, submit]);

  const { t } = useTranslation();

  return (
    <StyledSplashScreenContainer>
      <SplashScreenAlt overrideLogoText={t('fields.splashScreenSwiss.label')} />
      <StyledMerchantRedirectContainer>
        <MerchantRedirectLink isIntroScreen={true} />
      </StyledMerchantRedirectContainer>
    </StyledSplashScreenContainer>
  );
};
