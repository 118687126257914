import { ContractType } from '../../enums';

const ContractTypeMap = {
  [ContractType.Factoring]: 'common.bnplFactoring',
  [ContractType.RegulatedCredit]: 'common.regulatedCredit',
  [ContractType.UnregulatedCredit]: 'common.unregulatedCredit',
};

export const getContractTypeTranslationKey = (contractType: ContractType) =>
  ContractTypeMap[contractType];
