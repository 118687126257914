import { IField } from '../../types';
import { useFormToReduxLink } from '../utils/useFormToReduxLink';

interface IFormToReduxLinkProps {
  fields: IField[];
  watch: (name: string) => unknown;
}

export const FormToReduxLink = ({ fields, watch }: IFormToReduxLinkProps) => {
  useFormToReduxLink(fields, watch);

  return <></>;
};
