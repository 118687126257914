import { useState } from 'react';
import { string, node } from 'prop-types';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { Typography, IconButton, styled } from '@mui/material';
import { Dialog } from '../Dialog';
import { ScrollableContainer } from '../ScrollableContainer';

const ZoomContainer = styled('div')`
  position: relative;
`;

const ZoomButton = styled(IconButton)`
  position: absolute;
  bottom: 0.25rem;
  right: 1rem;
  background-color: #000;
  border-radius: 0;
  color: #fff;
  opacity: 0.5;
  padding: 0.15rem;
  &:hover {
    background-color: #000;
    opacity: 0.3;
  }
  @media (max-width: 600px) {
    right: 0.5rem;
  }
`;

const ZoomIcon = styled(ZoomOutMapIcon)`
  height: 0.9rem;
  width: 0.9rem;
`;

export const ZoomScrollableContainer = ({ children, name }) => {
  const [zoomedOpen, setZoomedOpen] = useState(false);
  const handleZoomOpen = () => setZoomedOpen(true);
  const handleCloseZoom = () => setZoomedOpen(false);

  return (
    <>
      <ZoomContainer>
        <ZoomButton aria-label="zoom" onClick={handleZoomOpen}>
          <ZoomIcon />
        </ZoomButton>
        <ScrollableContainer
          name={name}
          options={{ height: 80, heightMobile: 80 }}
        >
          <Typography component="p" textAlign="left" variant="caption">
            {children}
          </Typography>
        </ScrollableContainer>
      </ZoomContainer>
      <Dialog isOpen={zoomedOpen} onClose={handleCloseZoom}>
        <p>{children}</p>
      </Dialog>
    </>
  );
};

ZoomScrollableContainer.propTypes = {
  children: node.isRequired,
  name: string.isRequired,
};
