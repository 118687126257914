import { setLoadingState, setErrorState } from '@redux';
import { actionCreator } from '../../utils';
import * as consumerService from '../services/ConsumerService';

export const verifyPassword = actionCreator(
  'verifyPassword',
  password => async dispatch => {
    try {
      dispatch(setLoadingState(true));
      const { status } = await consumerService.verifyPassword(password);
      return status === 'valid';
    } catch (error) {
      dispatch(setErrorState(error));
    } finally {
      dispatch(setLoadingState(false));
    }
    return false;
  },
);

export default {};
