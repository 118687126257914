import { IField, IStringMap } from '@appTypes';

export interface IStepMetadata {
  currentStep: number;
  totalSteps: number;
}

export interface IStepDetails {
  fields: IField[];
  meta: IStepMetadata;
}

export interface IStep {
  [key: string]: IStepDetails;
}

export interface ITransactionState {
  currentAction: string;
  routes: IStringMap;
  steps: IStep;
  translatedSteps: IStringMap;
}

export default {
  currentAction: '',
  routes: {},
  steps: {},
  translatedSteps: {},
} as ITransactionState;
