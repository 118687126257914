import { useCallback } from 'react';
import PropTypes from 'prop-types';
import delay from 'delay';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Grid } from '@mui/material';
import { useTranslation } from '@hooks';
import { ActionContainer } from './components/ActionContainer';

const identityProviders = Object.freeze({
  onfido: 'onfido',
  spid: 'spid',
});

export const SPIDWelcomeContainer = ({ onChange, submit }) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async provider => {
      onChange(provider);

      await delay(200);

      submit();
    },
    [onChange, submit],
  );

  const handleSpidSubmit = useCallback(
    () => handleSubmit(identityProviders.spid),
    [handleSubmit],
  );

  const handleOnfidoSubmit = useCallback(
    () => handleSubmit(identityProviders.onfido),
    [handleSubmit],
  );

  return (
    <Grid
      container={true}
      direction="row"
      justifyContent="space-between"
      mt={4}
      spacing={4}
    >
      <Grid item={true} sm={6} xs={12}>
        <ActionContainer
          buttonVariant="contained"
          caption={t('fields.SPIDWelcomeContainer.SPID.caption')}
          icon={<img alt="Spid icon" src="/images/spid.svg" />}
          label={t('fields.SPIDWelcomeContainer.SPID.label')}
          onClick={handleSpidSubmit}
        />
      </Grid>
      <Grid item={true} sm={6} xs={12}>
        <ActionContainer
          buttonVariant="outlined"
          caption={t('fields.SPIDWelcomeContainer.onfido.caption')}
          icon={<CameraAltIcon />}
          label={t('fields.SPIDWelcomeContainer.onfido.label')}
          onClick={handleOnfidoSubmit}
        />
      </Grid>
    </Grid>
  );
};

SPIDWelcomeContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};
