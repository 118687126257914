import { AnyAction } from 'redux';
import { createActions } from 'redux-actions';

import { ThunkDispatch } from 'redux-thunk';
import {
  setLoadingState,
  changePath,
  setErrorState,
  exchangeToken,
  loadConfig,
  resolveFallbackUrl,
  resetErrorState,
} from '@redux';
import { IRootState } from '../../store';
import {
  actionCreator,
  getToken,
  sanitiseActionParameters,
  shouldDisableInitActionRetry,
} from '../../utils';
import * as transactionService from '../services/TransactionService';
import {
  getCurrentAction,
  getActionFromPathResolver,
  getPathFromActionResolver,
} from './selectors';

export const { updateRoutes, updateTransactionStep, updateTranslatedSteps } =
  createActions({
    UPDATE_TRANSACTION_STEP: transactionStep => ({ transactionStep }),
    UPDATE_ROUTES: routes => ({ routes }),
    UPDATE_TRANSLATED_STEPS: translatedSteps => ({ translatedSteps }),
  });

export const loadTransactionStep = actionCreator(
  'loadTransactionStep',
  (actionName: string, parameters: Record<string, string>) =>
    async (
      dispatch: ThunkDispatch<IRootState, void, AnyAction>,
      getState: () => IRootState,
    ) => {
      dispatch(setLoadingState(true));

      try {
        // For now we can not retry the initial action for pagolightPro
        // This might be removed in the future
        const { app } = getState() || {};
        const disableRetry = shouldDisableInitActionRetry(
          app?.brand?.logo,
          actionName,
        );
        const actionParameters = parameters
          ? sanitiseActionParameters(parameters)
          : null;
        const transactionStep = await transactionService.getTransactionStep(
          actionName,
          actionParameters,
          disableRetry,
        );

        dispatch(updateTransactionStep(transactionStep));
      } catch (error) {
        dispatch(setErrorState(error));
      } finally {
        dispatch(setLoadingState(false));
      }
    },
);

export const navigateToCurrentActionStep = actionCreator(
  'navigateToCurrentActionStep',
  ({ replace = false } = {}) =>
    (
      dispatch: ThunkDispatch<IRootState, void, AnyAction>,
      getState: () => IRootState,
    ) => {
      const state = getState();
      const currentAction = getCurrentAction(state);
      const path = getPathFromActionResolver(state)(currentAction);

      dispatch(changePath(path, replace));
    },
);

export const loadInitialStep = actionCreator(
  'loadInitialStep',
  () =>
    async (
      dispatch: ThunkDispatch<IRootState, void, AnyAction>,
      getState: () => IRootState,
    ) => {
      const { pathname } = window.location;
      const parameters = window.location.search
        ? Object.fromEntries(new URLSearchParams(window.location.search))
        : null;
      const requestedAction = getActionFromPathResolver(getState())(pathname);
      await dispatch(loadTransactionStep(requestedAction, parameters));
      // we want to replace the history entry for ?otc with the first action
      dispatch(navigateToCurrentActionStep({ replace: true }));
    },
);

export const reloadConfig = actionCreator(
  'reloadConfig',
  () => async (dispatch: ThunkDispatch<IRootState, void, AnyAction>) => {
    // get the session token from session storage for a session which was already initialised
    const token = getToken();
    if (token) {
      // if there is a session token, the user probably reloaded the page
      const config = await dispatch(loadConfig());
      if (config) {
        await dispatch(updateRoutes(config.steps));
        await dispatch(updateTranslatedSteps(config.translatedSteps));
        await dispatch(loadInitialStep());
      }
    } else {
      // if there's no session token, the user came to the base uri with an invalid (or no) otc
      dispatch(changePath('/404', true));
    }
  },
);

export const init = actionCreator(
  'init',
  () => async (dispatch: ThunkDispatch<IRootState, void, AnyAction>) => {
    // extract the fallback url from uri parameters
    dispatch(resolveFallbackUrl());

    // if the uri has an otc parameter, exchange the otc for a session token and config
    const config = await dispatch(exchangeToken());
    if (config) {
      await dispatch(updateRoutes(config.steps));
      await dispatch(updateTranslatedSteps(config.translatedSteps));
      await dispatch(loadInitialStep());
    } else {
      // if we didn't get session config we will try again using the session token
      await dispatch(reloadConfig());
    }
  },
);

export const userNavigation = actionCreator(
  'userNavigation',
  (pathname: string) =>
    async (
      dispatch: ThunkDispatch<IRootState, void, AnyAction>,
      getState: () => IRootState,
    ) => {
      const state = getState();

      if (getCurrentAction(state)) {
        const requestedAction = getActionFromPathResolver(state)(pathname);
        const currentAction = getCurrentAction(state);

        if (currentAction !== requestedAction) {
          await dispatch(loadTransactionStep(requestedAction));
          dispatch(navigateToCurrentActionStep());
        }
      }
    },
);

export const submitStep = actionCreator(
  'submitStep',
  (fields: Record<string, unknown>) =>
    async (
      dispatch: ThunkDispatch<IRootState, void, AnyAction>,
      getState: () => IRootState,
    ) => {
      dispatch(setLoadingState(true));
      dispatch(resetErrorState());

      const state = getState();
      const currentAction = getCurrentAction(state);
      try {
        const nextTransactionStep =
          await transactionService.updateTransactionStep(currentAction, fields);

        const backendResponseNextAction =
          nextTransactionStep?.action?.actionName;
        const isAllowedAction = !!getPathFromActionResolver(state)(
          backendResponseNextAction,
        );
        if (isAllowedAction) {
          dispatch(updateTransactionStep(nextTransactionStep));
          dispatch(navigateToCurrentActionStep());
        }
      } catch (error) {
        dispatch(setErrorState(error));
      } finally {
        dispatch(setLoadingState(false));
      }
    },
);
