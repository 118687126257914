import { TableCell, TableRow } from '@mui/material';

interface IPaymentRow {
  amount: string;
  currency?: string;
  header?: boolean;
  title: string;
}

export const PaymentRow = ({
  amount,
  currency = '',
  header = false,
  title,
}: IPaymentRow) => {
  const component = header ? 'th' : 'td';

  return (
    <TableRow>
      <TableCell component={component}>{title}</TableCell>
      <TableCell component={component}>{currency}</TableCell>
      <TableCell align="right" component={component}>
        {amount}
      </TableCell>
    </TableRow>
  );
};
