import { Translate } from '@heidi-pay/heidi-common-fe/types';
import {
  IPaymentRowProps,
  RowType,
} from '@heidi-pay/heidi-component-library/components/PaymentPlanTable';
import { IUseFormatter } from '../../../../../utils/formatters/types';
import { IScheduleData } from './types';

export function getItalyCreditPaymentPlanFields(
  {
    currency,
    interest,
    monthlyPayments,
    payMonthly,
    total,
    totalAmountPayable,
  }: IScheduleData,
  formatters: IUseFormatter,
  translate: Translate,
): IPaymentRowProps[] | null {
  if (!currency || !totalAmountPayable) {
    return null;
  }

  const { formatAmountWithCurrency, formatPercentage } = formatters;
  const { apr, nominalInterestRate } = interest;
  const baseKey = 'fields.paymentPlanHeyLight';

  return [
    {
      displayValueBold: true,
      label: translate(`${baseKey}.italy.creditAmount`),
      value: formatAmountWithCurrency(total, currency),
    },
    {
      displayValueBold: true,
      label: translate(`${baseKey}.italy.monthlyPayment`),
      value: formatAmountWithCurrency(payMonthly, currency),
    },
    {
      displayValueBold: true,
      label: translate(`${baseKey}.italy.remainingPayments`),
      value: `${formatAmountWithCurrency(payMonthly, currency)} x ${
        monthlyPayments - 1
      }`,
    },
    {
      label: '-',
      rowType: RowType.Divider,
    },
    {
      displayLabelBold: true,
      displayValueBold: true,
      label: translate(`${baseKey}.common.totalAmountDue`),
      labelVariant: 'h6',
      rowType: RowType.ScheduleToggle,
      value: formatAmountWithCurrency(totalAmountPayable, currency),
      valueVariant: 'h6',
    },
    {
      displayValueBold: true,
      label: translate(`${baseKey}.italy.nominalInterestRate`),
      labelVariant: 'h6',
      value: formatPercentage(nominalInterestRate),
      valueVariant: 'h6',
    },
    {
      displayValueBold: true,
      label: translate(`${baseKey}.italy.apr`),
      labelVariant: 'h6',
      value: formatPercentage(apr),
      valueVariant: 'h6',
    },
  ];
}
