/* eslint-disable max-len */
export enum ConsentTypeItalyBnpl {
  CompassDataProcessing = 'has_agreed_to_compass_data_processing', // fourth block
  ExternalDataProcessing = 'has_agreed_to_external_data_processing', // sixth block
  NoPaymentTermsAndConditions = 'has_agreed_to_no_payment_terms_and_conditions', // second block
  PaymentExtension = 'has_agreed_to_payment_extension', // first block
  ProfilingActivitiesForPromotionalCommunications = 'has_agreed_to_profiling_activities_for_promotional_communications', // fifth block
  TransferOfCreditFromMerchantToCompass = 'has_agreed_to_trafer_of_credit_from_merchant_to_compass', // third block
}

export enum ConsentTypeItalyCredit {
  DataProcessingInternalAndExternal = 'has_agreed_to_data_processing_internal_and_external', // fourth block
  MarketResearchCommercialAndPromotional = 'has_agreed_to_market_research_commercial_and_promotional_activities', // first block
  ProfilingActivitiesForPromotionalCommunications = 'has_agreed_to_profiling_activities_for_promotional_communications', // second block
  ThirdPartyCommercialAndPromotionalActivities = 'has_agreed_to_third_party_commercial_and_promotional_activities', // third block
}

export enum BnplDialogId {
  PersonalDetailsDialog1 = 'personal-details-dialog-1',
  PersonalDetailsDialog2 = 'personal-details-dialog-2',
  PersonalDetailsDialog3 = 'personal-details-dialog-3',
  PersonalDetailsDialog4 = 'personal-details-dialog-4',
  PersonalDetailsDialog5 = 'personal-details-dialog-5',
  PersonalDetailsDialog6 = 'personal-details-dialog-6',
  SecurityCodeVerificationDialog = 'security-code-verification-dialog',
  SelectScheduleDialog1 = 'select-schedule-dialog-1',
}

export enum CreditDialogId {
  PersonalDetailsDialog1 = 'personal-details-dialog-1',
  PersonalDetailsDialog2 = 'personal-details-dialog-2',
  PersonalDetailsDialog3 = 'personal-details-dialog-3',
  PersonalDetailsDialog4 = 'personal-details-dialog-4',
}
