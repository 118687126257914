import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  MenuItem,
  Box,
  Button,
  Select,
  FormControl,
  InputLabel,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useTranslation } from '@hooks';
import {
  loadPreviousOrders,
  loadSelectedPreviousOrderDetails,
  updatePrefillFormCount,
  updateSelectedPreviousOrder,
} from '../../../redux';
import {
  getPreviousOrders,
  getSelectedPreviousOrder,
  getSelectedPreviousOrderDetails,
} from '../../../redux/selectors';
import { getFormValuesFromOrderDetails } from '../utils/getFormValuesFromOrderDetails';
import { getOrderInformation } from '../utils/getOrderInformation';

export const PreviousOrderSelector = ({ closeMenu, setFormValue }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const orders = useSelector(getPreviousOrders);
  const selectedOrder = useSelector(getSelectedPreviousOrder);
  const orderDetails = useSelector(getSelectedPreviousOrderDetails);
  const orderDetailsListItems = getOrderInformation(orderDetails);

  const setSelectedOrder = event =>
    dispatch(updateSelectedPreviousOrder(event.target.value));

  useEffect(() => dispatch(loadPreviousOrders()), [dispatch]);

  useEffect(() => {
    if (selectedOrder) {
      dispatch(loadSelectedPreviousOrderDetails(selectedOrder));
    }
  }, [dispatch, selectedOrder]);

  const handleUpdateFormValues = () => {
    const formValues = getFormValuesFromOrderDetails(orderDetails);
    dispatch(updatePrefillFormCount());
    Object.keys(formValues).forEach(key => {
      const value = formValues[key];
      if (value) {
        setFormValue(key, value, { shouldDirty: true });
      }
    });
    closeMenu();
  };

  return (
    <Box maxWidth="90vw" px={3} py={2} width="400px">
      <Typography component="h4" fontWeight="bold" mb={1} variant="body2">
        {t('instore.tasksMenu.previousOrderSelector.title')}
      </Typography>
      <FormControl fullWidth={true}>
        <InputLabel htmlFor="ordersSelect">
          {t('instore.tasksMenu.previousOrderSelector.ordersList.label')}
        </InputLabel>
        <Select
          fullWidth={true}
          id="ordersSelect"
          label={t('instore.tasksMenu.previousOrderSelector.ordersList.label')}
          name="ordersSelect"
          onChange={setSelectedOrder}
          placeholder="Previous orders"
          value={selectedOrder}
        >
          {orders.map(({ key, label }) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {orderDetailsListItems ? (
        <>
          <Typography
            component="h4"
            fontWeight="bold"
            mt="1rem"
            variant="body2"
          >
            {t('instore.tasksMenu.previousOrderSelector.orderDetailsTitle')}
          </Typography>
          <List disablePadding={true}>
            {orderDetailsListItems.map(listItem => (
              <ListItem key={listItem} sx={{ padding: '0.25rem 1rem' }}>
                {listItem}
              </ListItem>
            ))}
          </List>
          <Box mt={2}>
            <Button
              fullWidth={true}
              onClick={handleUpdateFormValues}
              sx={{ textTransform: 'none' }}
              variant="contained"
            >
              {t('instore.tasksMenu.previousOrderSelector.confirm')}
            </Button>
          </Box>
        </>
      ) : null}
    </Box>
  );
};

PreviousOrderSelector.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  setFormValue: PropTypes.func.isRequired,
};
