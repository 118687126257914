import * as yup from 'yup';
import { DocumentType, ValidationFunction } from '../../types';

export const registerIdCardValidation = (
  schema: yup.StringSchema,
  labelOverride?: string,
) => {
  return schema.matches(
    /^[A-Za-z]{2}(\d{5}[A-Za-z]{2}|\d{7})$/,
    `fields.textInputDocumentNumber.${
      labelOverride ? `${labelOverride}.` : ''
    }idCard.incorrectFormat`,
  );
};

export const registerDrivingLicenseValidation = (
  schema: yup.StringSchema,
  labelOverride?: string,
) => {
  return schema.matches(
    /^[A-Za-z][A-Za-z0-9]{8}[A-Za-z]$/,
    `fields.textInputDocumentNumber.${
      labelOverride ? `${labelOverride}.` : ''
    }drivingLicense.incorrectFormat`,
  );
};

export const registerPassportValidation = (
  schema: yup.StringSchema,
  labelOverride?: string,
) => {
  return schema.matches(
    /^[A-Za-z]{2}\d{7}$/,
    `fields.textInputDocumentNumber.${
      labelOverride ? `${labelOverride}.` : ''
    }passport.incorrectFormat`,
  );
};

export const documentTypeValidationMap: Record<
  DocumentType,
  ValidationFunction
> = {
  NATIONAL_IDENTITY_CARD: registerIdCardValidation,
  DRIVING_LICENCE: registerDrivingLicenseValidation,
  PASSPORT: registerPassportValidation,
};
