import { styled } from '@mui/material';
import { useTranslation, useCountdownTimer } from '@hooks';
import { CopyBlock } from '../../../components';
import { CountdownCell } from './components/CountdownCell';

const Container = styled('div')`
  border: 1.5px solid ${props => props.theme.palette.primary.main};
  border-radius: 4px;
  color: ${props => props.theme.palette.primary.main};
  display: flex;
  margin-top: 1.5rem;
  padding: 1rem 2rem;
  text-align: center;
`;

export const CountdownTimer = ({ name, options }) => {
  const { t } = useTranslation();

  const { endDate } = options;

  const { isComplete, sections } = useCountdownTimer({ endDate });

  const timerItems = [
    {
      label: t('fields.countdownTimer.hours'),
      value: sections.hours,
    },
    {
      label: t('fields.countdownTimer.minutes'),
      value: sections.minutes,
    },
    {
      label: t('fields.countdownTimer.seconds'),
      value: sections.seconds,
    },
  ];

  if (isComplete) {
    return <CopyBlock i18nKey={`fields.countdownTimer.${name}.complete`} />;
  }

  return (
    <>
      <CopyBlock i18nKey={`fields.countdownTimer.${name}.awaiting`} />
      <Container>
        {timerItems.map((item, index) => (
          <CountdownCell
            hideSeparator={index >= timerItems.length - 1}
            key={item.label}
            label={item.label}
            value={item.value}
          />
        ))}
      </Container>
    </>
  );
};
