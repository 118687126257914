import { ReactNode } from 'react';
import {
  BackArrow,
  ForwardArrow,
} from '@heidi-pay/heidi-component-library/components';
import { Box, IconButton, styled, Typography, useTheme } from '@mui/material';

interface IBottomSheetProps {
  children: ReactNode;
  onBackClick?: () => void;
  onForwardClick?: () => void;
  title: string;
}

const IconButtonStyled = styled(IconButton)`
  padding: 0;
  height: 24px;
  width: 24px;
`;

const StyledPanel = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0px 0px 30px 0px #0000004D',
  padding: '20px',
  flexGrow: 1,
  height: '70vh',
  borderRadius: `${theme.space.space_3}`,

  [theme.breakpoints.down('md')]: {
    borderRadius: `${theme.space.space_3} ${theme.space.space_3}  0 0`,
    height: 'calc(100vh - 185px)',
    margin: '0 -24px -20px',
    '&:last-child': {
      marginBottom: '-20px',
    },
  },
}));

const ContentWrapper = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  marginTop: '16px',
});

const HeaderWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  position: 'relative',
  height: '24px',
});

const ButtonWrapper = styled(Box)({
  position: 'absolute',
  top: 0,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

const LeftButtonWrapper = styled(ButtonWrapper)({
  left: 0,
});

const RightButtonWrapper = styled(ButtonWrapper)({
  right: 0,
});

export const BottomSheet = ({
  children,
  onBackClick,
  onForwardClick,
  title,
}: IBottomSheetProps) => {
  const theme = useTheme();
  return (
    <StyledPanel>
      <HeaderWrapper>
        <LeftButtonWrapper>
          {onBackClick ? (
            <IconButtonStyled onClick={onBackClick}>
              <BackArrow color={theme.dialog.closeButton.color} />
            </IconButtonStyled>
          ) : null}
        </LeftButtonWrapper>
        <Typography component="h2" sx={{ textAlign: 'center' }} variant="h5">
          {title}
        </Typography>
        <RightButtonWrapper>
          {onForwardClick ? (
            <IconButtonStyled onClick={onForwardClick}>
              <ForwardArrow color={theme.dialog.closeButton.color} />
            </IconButtonStyled>
          ) : null}
        </RightButtonWrapper>
      </HeaderWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </StyledPanel>
  );
};
