import React from 'react';
import { BaseButton } from '@heidi-pay/heidi-component-library/components/Buttons/BaseButton';
import { styled } from '@mui/material';
import { useTranslation } from '@hooks';

interface ISignatureContinueButtonProps {
  isCurrentAccepted: boolean;
  onContinue: () => void;
}

const StyledBaseButton = styled(BaseButton)({
  width: '327px',
  textTransform: 'uppercase',
  alignSelf: 'center',
  marginBottom: '30px',
});

export const SignatureContinueButton = ({
  isCurrentAccepted,
  onContinue,
}: ISignatureContinueButtonProps) => {
  const { t } = useTranslation();

  if (isCurrentAccepted) {
    return null;
  }

  return (
    <StyledBaseButton color="primary" onClick={onContinue} variant="contained">
      {t('fields.signatureList.continueButton')}
    </StyledBaseButton>
  );
};
