import { styled } from '@mui/material';

const List = styled('ul', {
  shouldForwardProp: prop => !['marginBottom', 'marginTop'].includes(prop),
})`
  margin-bottom: ${props => props.marginBottom || '0'};
  margin-top: ${props => props.marginTop || '0'};
  padding: 15px;
  padding-bottom: 0;
  padding-top: 0;
  > li > a {
    color: ${props => props.theme.palette.primary.main};
  }
`;

const DescriptionList = styled('dl')`
  display: block;
  margin: 0;
  padding-left: 15px;
  > dt {
    float: left;
    width: 10px;
  }
  > dd {
    margin-left: 20px;
  }
`;

export const CollectPhoneNumberPagolightPro = () => (
  <div>
    <p>IL CLIENTE PRENDE ATTO ED ACCETTA</p>
    <List marginTop="30px">
      <li>
        Che l’Esercente cede a Compass Banca S.p.A. (il “Cessionario”), con sede
        legale in Via Caldera 21/D - 20153 Milano - Italia, a titolo oneroso, il
        credito - derivante dal contratto di acquisto del bene/servizio di cui
        l’accordo di dilazione costituisce parte integrale e sostanziale - pari
        all’importo risultante dalla somma delle rate indicate nell’accordo di
        dilazione e degli accessori, riconoscendo espressamente tale debito
        verso Compass Banca S.p.A..
      </li>
      <li>
        Che la cessione del credito è risolutivamente condizionata all’esito
        negativo:
      </li>
    </List>
    <DescriptionList>
      <dt>ii)</dt>
      <dd>
        delle verifiche di capienza, stato attivo dello strumento di pagamento e
        di abilitazione al servizio TTL (Terminale Telematico) utilizzato o
        all’esito negativo della verifica dell’importo minimo cedibile e
        dell’addebito della prima rata eventualmente richiesta all’atto
        dell’acquisto;
      </dd>
      <dt>ii)</dt>
      <dd>
        delle verifiche di regolarità della documentazione acquisita da Compass
        Banca S.p.A. o delle verifiche effettuate sul Cliente (debitore ceduto)
        anche mediante interrogazione delle banche dati gestite da soggetti
        privati (SIC), in conformità all’informativa resa ai sensi del relativo
        codice di condotta.
      </dd>
    </DescriptionList>
    <List marginBottom="30px">
      <li>
        Che nei casi predetti, seguirà la cessazione degli effetti della
        cessione del credito e, per l’effetto, della dilazione di pagamento
        collegata, con obbligo di corrispondere, interamente o parzialmente ove
        dovuto, il prezzo del bene/servizio direttamente all’Esercente.
      </li>
      <li>
        Che le comunicazioni del Cessionario possono essere effettuate mediante
        informativa pubblicata sul Sito Internet&nbsp;
        <a href="https://compass.it" rel="noreferrer" target="_blank">
          www.compass.it
        </a>
        &nbsp;o trasmesse tramite tecniche di comunicazione a distanza, anche ai
        fini del preavviso di imminente segnalazione, nelle banche dati, delle
        informazioni relative al ritardo nei pagamenti (ivi compresi, e-mail,
        SMS, contatto telefonico registrato o altri servizi di messaggistica),
        ovvero rese disponibili all’interno dell’area riservata del Sito
        Internet&nbsp;
        <a href="https://compass.it" rel="noreferrer" target="_blank">
          www.compass.it
        </a>
        &nbsp;ovvero mediante deposito nell’app dedicata, se previste.
      </li>
    </List>
  </div>
);
