import { ReactNode } from 'react';
import { useFormatters } from '@utils';

export interface IFormatterProps {
  children?: ReactNode;
  currency?: string;
  format?: Intl.DateTimeFormatOptions;
}

export const DateFormatter = ({ children, format }: IFormatterProps) => {
  const { formatDate } = useFormatters();

  if (!Array.isArray(children)) {
    return null;
  }

  const date = children.length === 1 ? children[0] : null;
  if (!date) {
    return null;
  }

  const options = format || {};

  return <span>{formatDate({ date, options })}</span>;
};

export const FormatAmount = ({ children, currency }: IFormatterProps) => {
  const { formatAmountWithCurrency } = useFormatters();

  if (!Array.isArray(children)) {
    return null;
  }

  const amount = children.length === 1 ? children[0] : null;

  if (!amount) {
    return null;
  }

  return <span>{formatAmountWithCurrency(amount, currency)}</span>;
};

export const FormatDateDDMM = (props: IFormatterProps) => (
  <DateFormatter format={{ month: 'long', day: 'numeric' }} {...props} />
);
