import {
  useTranslation as useTranslationReactI18Next,
  Namespace,
  DefaultNamespace,
  UseTranslationOptions,
} from 'react-i18next';
import { TOptions } from 'i18next';
import { getTranslationConstants } from '../../constants';
import { checkKeyHasTranslation } from './checkKeyHasTranslation';
import { useTranslationOverrides } from './TranslateOverrideProvider';
import { IExtendedOptions, IUseTranslation } from './types';

export const useTranslation = <N extends Namespace = DefaultNamespace>(
  ns?: N | Readonly<N>,
  options?: TOptions,
): IUseTranslation => {
  const overrides = useTranslationOverrides();
  const {
    i18n,
    ready,
    t: translate,
  } = useTranslationReactI18Next(ns, options as UseTranslationOptions);

  const translationConstants = getTranslationConstants();

  const t = (key: string | string[], opts?: string | IExtendedOptions) => {
    if (!key) {
      return key;
    }

    const extendedOpts =
      typeof opts === 'object'
        ? {
            ...opts,
            ...translationConstants,
          }
        : translationConstants;

    const translationKey = Array.isArray(key) ? key[0] : key;
    const overriddenKey = overrides?.[translationKey] ?? key;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const translation = translate(overriddenKey as any, extendedOpts);

    const disableCheck = typeof opts !== 'string' && opts?.disableKeyCheck;

    if (!disableCheck) {
      checkKeyHasTranslation({
        key: overriddenKey,
        translation,
        lang: i18n?.language ?? 'en',
      });
    }

    return translation;
  };

  return {
    t,
    i18n,
    ready,
  };
};
