import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useFormatters } from '@utils';
import { PaymentPlanVariant } from '../types';

const baseTranslationKey = 'fields.paymentPlanHeyLight.common';

export interface IScheduleTableHeadlineProps {
  currency: string;
  numberOfInstallments: number;
  totalAmountDue: string;
  variant: PaymentPlanVariant;
}

export const ScheduleTableHeadline = ({
  currency,
  numberOfInstallments,
  totalAmountDue,
  variant,
}: IScheduleTableHeadlineProps) => {
  const { t } = useTranslation();
  const { formatAmountWithCurrency } = useFormatters();

  const formattedTotalAmountDue = formatAmountWithCurrency(
    totalAmountDue,
    currency,
  );

  const totalAmountChunk = t(`${baseTranslationKey}.scheduleTableTitle`, {
    numberOfInstallments,
    totalAmountDue: formattedTotalAmountDue,
  });

  if (variant === 'italyCredit') {
    return (
      <>
        <Typography component="h5" variant="h5">
          {t(`${baseTranslationKey}.totalAmountDue`)}
        </Typography>
        <Typography component="h5" variant="h5">
          {totalAmountChunk}
        </Typography>
      </>
    );
  }
  return (
    <Typography component="h5" variant="h5">
      {t(`${baseTranslationKey}.total`)} {totalAmountChunk}
    </Typography>
  );
};
