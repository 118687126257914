import { useTranslation } from 'react-i18next';
import { formatAmount } from './formatAmount';
import { formatAmountClean } from './formatAmountClean';
import { formatAmountWithCurrency } from './formatAmountWithCurrency';
import { formatDate } from './formatDate';
import { formatDecimalToPercentage } from './formatDecimalToPercentage';
import { formatPercentage } from './formatPercentage';
import {
  getAmountSeparatorsByLocale,
  Separators,
} from './getAmountSeparatorsByLocale';
import { IFormatDate, IUseFormatter } from './types';

const useFormatters = (): IUseFormatter => {
  const {
    i18n: { language: locale },
  } = useTranslation();

  return {
    getDecimalSeparator: () =>
      getAmountSeparatorsByLocale(locale, Separators.decimal),
    getGroupSeparator: () =>
      getAmountSeparatorsByLocale(locale, Separators.group),
    formatAmount: (amount: number | string) => formatAmount(amount, locale),
    formatAmountClean: (amount: number | string) => formatAmountClean(amount),
    formatAmountWithCurrency: (amount: number | string, currency?: string) =>
      formatAmountWithCurrency(amount, locale, currency),
    formatDate: ({ date, options }: IFormatDate) =>
      formatDate({ date, locale, options }),
    formatPercentage: (value: number | string) =>
      formatPercentage(value, locale),
    formatDecimalToPercentage: (value: number | string) =>
      formatDecimalToPercentage(value, locale),
    locale,
  };
};

export default useFormatters;
