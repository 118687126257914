import { Box, Typography } from '@mui/material';

export const CountdownCell = ({ hideSeparator = false, label, value }) => (
  <>
    <Box display="flex" flexDirection="column">
      <Typography aria-labelledby={label} component="span" variant="h4">
        {value}
      </Typography>
      <Typography color="#000" component="label" id={label} variant="caption">
        {label}
      </Typography>
    </Box>
    {hideSeparator ? null : (
      <Typography component="span" lineHeight={1.5} mx={3} variant="h4">
        :
      </Typography>
    )}
  </>
);
