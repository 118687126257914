import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { SplashScreen } from '@heidi-pay/heidi-component-library/components';
import { Container, ContainerProps, styled } from '@mui/material';
import { Children } from '@appTypes';
import { getIsLoading, getIsUnhandledError } from '@redux';
import ErrorBackdrop from './components/ErrorBackdrop';
import { Footer } from './components/Footer';
import HelpWidget from './components/HelpWidget';
import { isWaitingForInitialActionToLoad } from './components/heyLight/isWaitingForInitialActionToLoad';
import { useShowHelpWidget } from './components/hooks';
import LoadingBackdrop from './components/LoadingBackdrop';
import MetricsLoader from './components/MetricsLoader';
import { SalesforceLiveChat } from './components/SalesforceLiveChat';
import { SentryErrorBoundary } from './components/SentryErrorBoundary';
import { MainContent } from './MainContent';

interface IStyledContainerProps extends ContainerProps {
  component: React.ElementType;
}

const StyledContainer = styled(Container)<IStyledContainerProps>({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  margin: '0 auto',
  padding: '0',

  // iPhone 5 fix:
  // https://gist.github.com/pburtchaell/e702f441ba9b3f76f587#file-styles-css-L40
  '@media screen and (device-aspect-ratio: 40/71)': {
    minHeight: '500px',
  },
  '@media (min-width: 600px)': {
    paddingLeft: 0,
    paddingRight: 0,
  },
  paddingLeft: 0,
  paddingRight: 0,
});

interface IMain {
  children: Children;
}

const Main = ({ children }: IMain) => {
  const isLoading = useSelector(getIsLoading);
  const isUnhandledError = useSelector(getIsUnhandledError);
  const { isSalesforceLiveChatEnabled, shouldHideWidget, showHelpWidget } =
    useShowHelpWidget();
  const isInitialActionLoading = isWaitingForInitialActionToLoad();

  return (
    <StyledContainer component="main" maxWidth="sm">
      <SentryErrorBoundary>
        {isInitialActionLoading ? (
          <SplashScreen originatorBy={null} />
        ) : (
          <Suspense fallback={<LoadingBackdrop />}>
            <MainContent>{children}</MainContent>
            <Footer />

            {showHelpWidget ? <HelpWidget /> : null}
            {isSalesforceLiveChatEnabled ? (
              <SalesforceLiveChat shouldHideWidget={shouldHideWidget} />
            ) : null}

            {isUnhandledError ? <ErrorBackdrop /> : null}
          </Suspense>
        )}
        {isLoading && !isUnhandledError && !isInitialActionLoading ? (
          <LoadingBackdrop />
        ) : null}
        <MetricsLoader />
      </SentryErrorBoundary>
    </StyledContainer>
  );
};

export default Main;
