import { useReducer } from 'react';

export const maximumCharacterCount = 250;

const helpWidgetSessionKey = 'helpWidgetSession';
const actions = Object.freeze({
  setIsError: 'set_is_error',
  setIsLoading: 'set_is_loading',
  setIsSent: 'set_is_sent',
  setMessage: 'set_message',
});

const initialState = {
  isSent: false,
  isLoading: false,
  isError: false,
  message: '',
  numberOfCharactersRemaining: maximumCharacterCount,
};

const loadDataFromSessionStorage = state => {
  const hasSubmitted = sessionStorage.getItem(helpWidgetSessionKey) === 'true';
  return { ...state, isSent: hasSubmitted };
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.setIsSent:
      return { ...state, isSent: action.payload };
    case actions.setIsLoading:
      return { ...state, isLoading: action.payload };
    case actions.setIsError:
      return { ...state, isError: action.payload };
    case actions.setMessage:
      return {
        ...state,
        message: action.payload,
        numberOfCharactersRemaining:
          maximumCharacterCount - action.payload.length,
      };
    default:
      throw Error(`Unknown action: ${action.type}`);
  }
};

const setIsSent = (dispatch, value) => {
  sessionStorage.setItem(helpWidgetSessionKey, value.toString());

  dispatch({ type: actions.setIsSent, payload: value });
};

export const useHelpWidget = () => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState,
    loadDataFromSessionStorage,
  );

  return {
    setIsError: value => dispatch({ type: actions.setIsError, payload: value }),
    setIsLoading: value =>
      dispatch({ type: actions.setIsLoading, payload: value }),
    setIsSent: value => setIsSent(dispatch, value),
    setMessage: value => dispatch({ type: actions.setMessage, payload: value }),
    state,
  };
};
