import { useCallback, useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { CircularProgress, IconButton, styled } from '@mui/material';
import { useTranslation } from '@hooks';
import { AutoScanningPortal } from './AutoScanningPortal';

const AutocaptureTimeoutMs = 5000;

const CloseButtonStyled = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  background-color: #ffffff21;

  &:hover {
    background-color: #ffffff3d;
  }
`;

const CircularProgressContainer = styled('div')`
  position: absolute;
  bottom: 20px;
  text-align: center;
  z-index: 3;
  left: 0;
  right: 0;
`;

const CameraButton = styled(IconButton)`
  background-color: white;
  height: 45px;
  margin-left: -52px;
  margin-top: 7px;
  position: absolute;
  width: 45px;

  &:hover {
    background-color: #ffffff91;
  }
`;

export interface IAutoScanningContainerProps {
  onAutocaptureComplete: () => void;
  onCloseClick: () => void;
}

export const AutoScanningContainer = ({
  onAutocaptureComplete,
  onCloseClick,
}: IAutoScanningContainerProps) => {
  const [progress, setProgress] = useState(0);
  const [captureTriggered, setCaptureTriggered] = useState(false);
  const { t } = useTranslation();

  const handleCaptureTriggeredClick = useCallback(() => {
    setCaptureTriggered(true);
  }, []);

  useEffect(() => {
    if (captureTriggered) {
      const interval = setInterval(() => {
        if (progress * 50 <= AutocaptureTimeoutMs) {
          // eslint-disable-next-line max-nested-callbacks
          setProgress(prev => prev + 2);
        } else {
          clearInterval(interval);
          onAutocaptureComplete();
        }
      }, 100);

      return () => clearInterval(interval);
    }

    return () => {};
  }, [captureTriggered, onAutocaptureComplete, progress]);

  return (
    <AutoScanningPortal>
      <CloseButtonStyled
        aria-label={t('common.close')}
        id="mitek-close-btn"
        onClick={onCloseClick}
      >
        <Close color="secondary" />
      </CloseButtonStyled>

      <CircularProgressContainer>
        {captureTriggered ? (
          <CircularProgress
            aria-valuemax={100}
            aria-valuemin={0}
            aria-valuenow={progress}
            size="60px"
            value={progress}
            variant="determinate"
          />
        ) : (
          <>
            <CircularProgress
              aria-hidden={true}
              color="secondary"
              size="60px"
              value={100}
              variant="determinate"
            />
            <CameraButton
              aria-label={t('common.start')}
              onClick={handleCaptureTriggeredClick}
            />
          </>
        )}
      </CircularProgressContainer>
    </AutoScanningPortal>
  );
};
