import { Checkbox as MuiCheckbox, styled } from '@mui/material';
import { Tick } from '../Icons';
import { ICheckboxProps, IIconProps } from './types';

const Icon = styled('div')<IIconProps>(({ error, theme }) => ({
  width: '1.3rem',
  height: '1.3rem',
  border: '2px solid',
  color: theme.palette.primary.main,
  borderColor: error ? 'red' : theme.palette.primary.main,
  position: 'relative',
  '& > svg': {
    position: 'absolute',
    left: -1,
    top: -2,
  },
}));

export const Checkbox = ({
  checked,
  error,
  name,
  onBlur,
  onChange,
}: ICheckboxProps) => {
  return (
    <MuiCheckbox
      checked={checked}
      checkedIcon={
        <Icon>
          <Tick fontSize="small" />
        </Icon>
      }
      icon={<Icon error={error} />}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};
