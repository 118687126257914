import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { getRoutes } from './redux';
import Transaction from './Transaction';

export default function Routes() {
  const routes = useSelector(getRoutes);
  const paths = Object.values(routes);
  return (
    <>
      {paths.map(path => (
        <Route component={Transaction} key={path} path={path} />
      ))}
    </>
  );
}
