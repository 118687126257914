import { useSelector, useDispatch } from 'react-redux';
import { Button, ButtonGroup, styled } from '@mui/material';
import { useTranslation } from '@hooks';

import { changeLocale, getCurrentLocale, getLocales } from '@redux';
import { parseLocale } from '../../utils';

const StyledButton = styled(Button)(({ disabled }) => ({
  ...(disabled && {
    color: '#989898',
  }),
}));

function lang(locale) {
  // assume latin based langs for now
  return parseLocale(locale).lang.toUpperCase();
}

export default function LocalePicker() {
  const dispatch = useDispatch();
  const locales = useSelector(getLocales);
  const currentLocale = useSelector(getCurrentLocale);
  const { t } = useTranslation();

  const change = locale => dispatch(changeLocale(locale));

  return (
    <ButtonGroup
      aria-label={t('footer.localePicker.label')}
      color="primary"
      size="small"
      variant="text"
    >
      {locales.map(locale => (
        <StyledButton
          disabled={locale === currentLocale}
          key={locale}
          onClick={() => change(locale)}
        >
          {lang(locale)}
        </StyledButton>
      ))}
    </ButtonGroup>
  );
}
