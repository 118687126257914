const defaultDateFormatOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

interface IFormatDate {
  date: string;
  locale: string;
  options?: Intl.DateTimeFormatOptions;
}

export const formatDate = ({
  date,
  locale,
  options = defaultDateFormatOptions,
}: IFormatDate) => {
  const localeCode = locale.replace('_', '-');
  return new Date(date).toLocaleDateString(localeCode, options);
};
