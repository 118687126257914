export function formatAmount(amount, locale, currency) {
  const localeCode = locale.replace('_', '-');
  const currencyFormatted = Number(amount).toLocaleString(localeCode, {
    style: 'currency',
    currency,
  });
  return currencyFormatted.replace(/[a-z]{3}/i, '').trim();
}

export function formatAmountIfExists(price, locale) {
  if (!price) {
    return '';
  }
  return formatAmount(price.amount, locale, price.currency);
}
