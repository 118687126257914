import { createActions, handleActions } from 'redux-actions';

import { resetErrorState, setLoadingState, setErrorState } from '@redux';
import { actionCreator } from '../../utils';

import * as verificationService from '../services/VerificationService';

export const initialState = {};

export const { setVerifyingState } = createActions({
  SET_VERIFYING_STATE: isVerifying => ({ isVerifying }),
});

export const checkVerificationCode = actionCreator(
  'checkVerificationCode',
  code => async dispatch => {
    dispatch(setVerifyingState(true));
    try {
      const { isValid } = await verificationService.verifyCode(code);
      dispatch(setVerifyingState(false));
      return isValid;
    } catch (error) {
      dispatch(setVerifyingState(false));
      dispatch(setErrorState(error));
    }

    return false;
  },
);

export const resendVerificationCode = actionCreator(
  'resendVerificationCode',
  verificationCodeType => async dispatch => {
    dispatch(setLoadingState(true));
    dispatch(resetErrorState());
    try {
      await verificationService.generateCode(verificationCodeType);
    } catch (error) {
      dispatch(setErrorState(error));
    } finally {
      dispatch(setLoadingState(false));
    }
  },
);

export const getIsVerifying = state => state.verification.isVerifying;

export default handleActions(
  {
    [setVerifyingState]: (state, { payload: { isVerifying } }) => ({
      ...state,
      isVerifying,
    }),
  },
  {
    isVerifying: false,
  },
);
