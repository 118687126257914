import { IBaseComponent } from '@transaction/components/types';
import { PaymentPlanBase } from './PaymentPlanBase';
import { IPaymentPlanHeyLightOptions } from './types';

export const PaymentPlanSwissMobilezoneInstore = ({
  name,
  options = {},
}: IBaseComponent<IPaymentPlanHeyLightOptions, unknown>) => (
  <PaymentPlanBase
    name={name}
    options={{ ...options, variant: 'swissMobilezoneInstore' }}
    rowSpacing="large"
  />
);
