import { useSelector } from 'react-redux';
import { useStatusPolling } from '@hooks';
import { getIsLoading } from '@redux';
import { CopyBlock } from '../../../../components/CopyBlock';
import { checkIntrumIdentityStatus } from '../../../../services/IntrumStatusService';
import { HoldingPage } from '../HoldingPage';
import { IIntrumIdentityHoldingContainer } from './types';

export const IntrumIdentityHoldingContainer = ({
  options = {},
  submit,
  submitAttempted,
  value,
}: IIntrumIdentityHoldingContainer) => {
  const isSubmitting = useSelector(getIsLoading);
  const { overrideKey } = options;

  const baseTranslationKey = `fields.intrumHoldingPageContainer.${
    overrideKey ? `${overrideKey}.` : ''
  }`;
  const titleKey = `${baseTranslationKey}title`;
  const messageKey = `${baseTranslationKey}message`;

  useStatusPolling({
    isSubmitting,
    onComplete: submit,
    pollingFunction: checkIntrumIdentityStatus,
    retryIntervalMs: 5000,
    initialDelayMs: 10000,
    submitAttempted,
  });

  return (
    <HoldingPage>
      <CopyBlock i18nKey={titleKey} value={value} />
      <CopyBlock i18nKey={messageKey} />
    </HoldingPage>
  );
};
