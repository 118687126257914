import { call } from '@utils';
import { IPaymentPlan } from '../redux/types.d';

export async function loadPaymentPlan(
  monthlyPayments: number,
  paymentMethod: string,
): Promise<IPaymentPlan> {
  return call(
    `/api/checkout-client/schedule/?monthlyPayments=${monthlyPayments}&paymentMethod=${paymentMethod}`,
  );
}
