import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { FormControl, Grid } from '@mui/material';

import subFieldToComponentMapping from '../utils/subFieldToComponentMapping';

export default function AddressField({
  control,
  errors,
  fullAddress,
  gridSize,
  hidden = false,
  name,
  reset,
  submitAttempted,
  type,
  ...props
}) {
  const Component = subFieldToComponentMapping[type];

  const renderController = () => {
    return (
      <Controller
        control={control}
        name={name}
        render={({ onBlur, onChange, ref, value }, { isTouched }) => (
          <FormControl fullWidth={true}>
            <Component
              {...props} // eslint-disable-line  react/jsx-props-no-spreading
              error={
                isTouched || submitAttempted ? errors[name]?.message : undefined
              }
              fullAddress={fullAddress}
              hidden={hidden}
              inputRef={ref}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              reset={reset}
              value={value}
            />
          </FormControl>
        )}
      />
    );
  };

  if (hidden) {
    return renderController();
  }

  return (
    <Grid item={true} xs={gridSize}>
      {renderController()}
    </Grid>
  );
}
AddressField.propTypes = {
  name: PropTypes.string.isRequired,
  gridSize: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  control: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  submitAttempted: PropTypes.bool.isRequired,
  hidden: PropTypes.bool,
  reset: PropTypes.func.isRequired,
  fullAddress: PropTypes.shape().isRequired,
  autoComplete: PropTypes.string,
};

AddressField.defaultProps = {
  autoComplete: undefined,
  hidden: false,
};
