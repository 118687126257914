import { handleActions } from 'redux-actions';

import * as actions from './actions';
import initialState from './initialState';

export default handleActions(
  {
    [actions.updateTransactionStep]: (
      state,
      { payload: { transactionStep } },
    ) => ({
      ...state,
      currentAction: transactionStep.action.actionName,
      steps: {
        ...state.steps,
        [transactionStep.action.actionName]: {
          ...state.steps?.[transactionStep.action],
          fields: transactionStep.action.context.fields,
          meta: {
            ...state.steps?.[transactionStep.action.actionName]?.meta,
            currentStep: transactionStep.meta.progress.currentStep,
            totalSteps: transactionStep.meta.progress.totalSteps,
          },
        },
      },
    }),

    [actions.updateRoutes]: (state, { payload: { routes } }) => ({
      ...state,
      routes,
    }),

    [actions.updateTranslatedSteps]: (
      state,
      { payload: { translatedSteps } },
    ) => ({
      ...state,
      translatedSteps,
    }),
  },
  initialState,
);
