export const getOrderInformation = orderDetails => {
  if (!orderDetails) {
    return null;
  }
  const { customerDetails, products } = orderDetails;
  const listItems = [];
  if (customerDetails?.firstName || customerDetails?.lastName) {
    listItems.push(
      `${customerDetails?.firstName || '-'} ${
        customerDetails?.lastName || '-'
      }`,
    );
  }
  if (customerDetails?.firstName || customerDetails?.lastName) {
    listItems.push(
      `${orderDetails?.shippingAddressRaw?.address?.addressLine1 || '-'}, ${
        orderDetails?.shippingAddressRaw?.address?.city || '-'
      }`,
    );
  }

  listItems.push(customerDetails?.emailAddress || '-');

  if (products[0]) {
    listItems.push(products[0]?.name || '-');
  }

  return listItems;
};
