import * as yup from 'yup';

interface IFieldOptions {
  mandatoryAcceptanceKeys: string[];
}

export const complianceContainerValidation = (
  name: string,
  { mandatoryAcceptanceKeys }: IFieldOptions,
) =>
  yup.array().of(
    yup
      .object()
      .shape({
        key: yup.string(),
        value: yup
          .mixed()
          .oneOf(
            [true, false],
            'fields.consentScrollableContainer.consentBlock.required',
          ),
      })
      .test(
        'consents-with-mandatory-acceptance',
        'fields.consentScrollableContainer.consentBlock.requiresAcceptance',
        field =>
          !mandatoryAcceptanceKeys.find(key => key === field.key) ||
          field.value === true,
      ),
  );
