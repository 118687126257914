import { Collapse, FormControl } from '@mui/material';
import {
  IBaseInputComponent,
  IComponentBaseOptions,
} from '@transaction/components/types';
import { CopyBlock } from '../../../../components/CopyBlock';
import { SelectPicker } from '../SelectPicker';

interface IPaymentLinkDeliveryOption {
  code: string;
  subtitleKey?: string;
}

export interface IPaymentLinkDeliveryOptions extends IComponentBaseOptions {
  items: IPaymentLinkDeliveryOption[];
  sorted?: boolean;
}

export type IPaymentLinkDeliverySelector = IBaseInputComponent<
  IPaymentLinkDeliveryOptions,
  string
>;

export const PaymentLinkDeliverySelector = ({
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  options = { items: [] },
  setValue,
  value,
}: IPaymentLinkDeliverySelector) => {
  const items = options.items.map(x => x.code);
  const selectedItem = options.items.find(x => x.code === value);

  return (
    <FormControl
      component="fieldset"
      error={Boolean(error)}
      fullWidth={true}
      size="small"
      variant="outlined"
    >
      <SelectPicker
        complete={Boolean(value)}
        error={error}
        inputRef={inputRef}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        options={{
          ...options,
          items,
        }}
        setValue={setValue}
        value={value}
      />
      <Collapse in={Boolean(selectedItem?.subtitleKey)}>
        <CopyBlock
          i18nKey={`fields.textBlock.paymentLinkDeliverySelector.subtitle.${selectedItem?.subtitleKey}.content`}
        />
      </Collapse>
    </FormControl>
  );
};
