/* eslint-disable max-lines */
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as yup from 'yup';
import { InputFieldAdornment } from '@heidi-pay/heidi-component-library/components/InputFieldAdornment';
import {
  FormControl,
  Autocomplete,
  FormHelperText,
  TextField,
  AutocompleteRenderInputParams,
} from '@mui/material';
import { IStringMap } from '@appTypes';
import { useTranslation } from '@hooks';
import { parseLocale } from '@utils';
import { StackedLabel } from '../../StackedLabel';
import { IBaseInputComponent, IComponentBaseOptions } from '../../types';

interface ISelectItem {
  text: string;
  value: string;
}

interface IDynamicJsonImport {
  default: IStringMap;
}

export interface INationalityPickerOptions extends IComponentBaseOptions {
  countryCodes?: string[];
}

export const NationalityPicker = ({
  complete,
  error,
  name,
  onBlur,
  onChange,
  options,
  value,
}: IBaseInputComponent<INationalityPickerOptions, string, string>) => {
  const { countryCodes, disabled, tooltip, withStackedLabel } = options ?? {};
  const {
    i18n: { language: locale },
    t,
  } = useTranslation();
  const displayLabel = t('fields.nationalityPicker.label');

  const [autocompleteOptions, setAutocompleteOptions] = useState<ISelectItem[]>(
    [],
  );
  const selectedOption = autocompleteOptions.find(x => x.value === value);

  const getCountriesList = useCallback(
    (countriesJson: IStringMap) => {
      const mappedAutocompleteItems = Object.keys(countriesJson)
        .map(key => ({
          value: key,
          text: countriesJson[key],
        }))
        .sort((a, b) => (a.text > b.text ? 1 : -1));

      if (!countryCodes) {
        return mappedAutocompleteItems;
      }

      return mappedAutocompleteItems.filter(({ value: code }) =>
        countryCodes.includes(code),
      );
    },
    [countryCodes],
  );

  const getMemoisedCountries = useMemo(
    () => async () => {
      const { lang } = parseLocale(locale);
      const countriesModule: IDynamicJsonImport = await import(
        `../../../../../public/locales/${lang}/country.json`
      );

      const countriesJson = countriesModule.default;
      return getCountriesList(countriesJson);
    },
    [getCountriesList, locale],
  );

  useEffect(() => {
    getMemoisedCountries().then(items => setAutocompleteOptions(items));
  }, [getMemoisedCountries]);

  const handleOnChange = useCallback(
    (_: SyntheticEvent, newValue: ISelectItem | null) => {
      onChange(newValue?.value ?? '');
    },
    [onChange],
  );

  const handleRenderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        sx={{
          '> .MuiInputBase-root.MuiInputBase-adornedEnd': { pr: '15px' },
        }}
        {...params} // eslint-disable-line  react/jsx-props-no-spreading
        error={!!error}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <InputFieldAdornment
              isComplete={complete}
              isDisabled={disabled}
              isError={!!error}
            />
          ),
        }}
        label={withStackedLabel ? undefined : displayLabel}
      />
    ),
    [complete, disabled, displayLabel, error, withStackedLabel],
  );

  const getOptionLabel = useCallback((option: ISelectItem) => option.text, []);

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ mt: '0.625rem' }}
      variant="outlined"
    >
      {withStackedLabel ? (
        <StackedLabel label={displayLabel} name={name} tooltip={tooltip} />
      ) : null}
      <Autocomplete
        autoComplete={true}
        autoHighlight={true}
        clearIcon={null}
        forcePopupIcon={false}
        getOptionLabel={getOptionLabel}
        id={name}
        onBlur={onBlur}
        onChange={handleOnChange}
        options={autocompleteOptions}
        renderInput={handleRenderInput}
        value={selectedOption}
      />
      <FormHelperText>{t(error ?? '')}</FormHelperText>
    </FormControl>
  );
};

NationalityPicker.validation = () =>
  yup.string().required('fields.nationalityPicker.required');
