import {
  OriginatorUuidQa,
  OriginatorUuidSbx,
  OriginatorUuidProd,
} from '../enums';

export const Brand = Object.freeze({
  heidipay: 'heidipay',
  pagolight: 'mediobanca',
  pagolightPro: 'pagolight_pro',
});

export const pagolightOriginators = [
  OriginatorUuidQa.PAGOLIGHT.valueOf(),
  OriginatorUuidSbx.PAGOLIGHT.valueOf(),
  OriginatorUuidProd.PAGOLIGHT.valueOf(),
];

export const pagolightProOriginators = [
  OriginatorUuidQa.PAGOLIGHTPRO.valueOf(),
  OriginatorUuidSbx.PAGOLIGHTPRO.valueOf(),
  OriginatorUuidProd.PAGOLIGHTPRO.valueOf(),
];

export const heidipayOriginators = [
  OriginatorUuidQa.HEIDIPAY.valueOf(),
  OriginatorUuidSbx.HEIDIPAY.valueOf(),
  OriginatorUuidProd.HEIDIPAY.valueOf(),
];
