// Utility class for validating an italian codice fiscale codes
import { CHECK_CODE_CHARS, CHECK_CODE_EVEN, CHECK_CODE_ODD } from './constants';
import { extractConsonantsUppercase, extractVowelsUppercase } from './utils';

const engAlphabetCharacterCount = 26;
const lengthOfCodiceFiscale = 16;
const codiceFiscaleRegex =
  /^[A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1}$/;

/**
 * Takes an Italian codice fiscale as a parameter and returns the control character
 * The control character is used to verify the integrity of the codice fiscale
 * @param {string} codiceFiscale - A string representaing an Italian codice fiscale
 * @returns {string} - The control character used to validate the codice fiscale
 */
export const getCheckCode = codiceFiscale => {
  const stringExcludingCheckCode = codiceFiscale.substring(0, 15).toUpperCase();
  const checksum = [...stringExcludingCheckCode].reduce(
    (previous, current, index) => {
      const isIndexOdd = index % 2;
      const characterCheckCode = isIndexOdd
        ? CHECK_CODE_EVEN[current]
        : CHECK_CODE_ODD[current];

      return previous + characterCheckCode;
    },
    0,
  );

  const normalisedChecksum = checksum % engAlphabetCharacterCount;

  return CHECK_CODE_CHARS.charAt(normalisedChecksum);
};

/**
 * Takes an Italian codice fiscale as a parameter and returns a boolean representing its validity
 * @param {string} codiceFiscale - A string representaing an Italian codice fiscale
 * @returns {boolean} - A boolean representing the validity of the inputed string
 */
export const isCodiceFiscaleValid = codiceFiscale => {
  if (typeof codiceFiscale !== 'string') {
    return false;
  }

  if (codiceFiscale.length !== lengthOfCodiceFiscale) {
    return false;
  }

  const uppercaseCodiceFiscale = codiceFiscale.toUpperCase();
  if (!codiceFiscaleRegex.test(uppercaseCodiceFiscale)) {
    return false;
  }

  // The check code is alwys the last character of a codice fiscale
  const actualCheckCode = uppercaseCodiceFiscale.charAt(15);
  const expectedCheckCode = getCheckCode(uppercaseCodiceFiscale);

  return actualCheckCode.toUpperCase() === expectedCheckCode;
};

const getFirstNameCode = firstName => {
  const firstNameConsonants = extractConsonantsUppercase(firstName);
  if (firstNameConsonants.length >= 4) {
    return (
      firstNameConsonants.charAt(0) +
      firstNameConsonants.charAt(2) +
      firstNameConsonants.charAt(3)
    );
  }
  const firstNameVowels = `${extractVowelsUppercase(firstName)}XXX`;
  const firstNameCode = firstNameConsonants + firstNameVowels;

  return firstNameCode.slice(0, 3);
};

const getLastNameCode = lastName => {
  const codeLastName = `${extractConsonantsUppercase(
    lastName,
  )}${extractVowelsUppercase(lastName)}XXX`;

  return codeLastName.slice(0, 3);
};

export const isCodiceFiscaleMatchingName = (
  codiceFiscale,
  firstName,
  lastName,
) => {
  if (!codiceFiscale) {
    return false;
  }

  // we should only run the validation if we have the dependent values
  if (!firstName || !lastName) {
    return true;
  }

  const nameCode = getLastNameCode(lastName) + getFirstNameCode(firstName);
  const nameCodeFromCodiceFiscale = codiceFiscale.slice(0, nameCode.length);

  return nameCode === nameCodeFromCodiceFiscale.toUpperCase();
};

export const isCodiceFiscaleOwnerSeventeenOrOlder = codiceFiscale => {
  if (!codiceFiscale) {
    return false;
  }

  const lastTwoDigitsYearOfBirth = parseInt(codiceFiscale.slice(6, 8), 10);
  const lastTwoDigistsCurrentYear = new Date().getFullYear() % 100;

  const currentYearWithCenturyCompensation =
    lastTwoDigistsCurrentYear < lastTwoDigitsYearOfBirth
      ? lastTwoDigistsCurrentYear + 100
      : lastTwoDigistsCurrentYear;

  return currentYearWithCenturyCompensation - lastTwoDigitsYearOfBirth > 17;
};

const fieldKey = 'codice-fiscale';
export const registerCodiceFiscaleValidation = ({
  ageErrorTranslationKey,
  errorTranslationKey,
  nameErrorTranslationKey,
  schema,
}) =>
  schema
    .test(fieldKey, errorTranslationKey, value => isCodiceFiscaleValid(value))
    .test(fieldKey, nameErrorTranslationKey, (value, context) => {
      const {
        parent: { firstName, lastName },
      } = context;

      // The check is required in case the form doesn't include first name and last name
      if (firstName && lastName) {
        return isCodiceFiscaleMatchingName(value, firstName, lastName);
      }
      return true;
    })
    .test(fieldKey, ageErrorTranslationKey, value =>
      isCodiceFiscaleOwnerSeventeenOrOlder(value),
    );
