import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import HtmlContainer from '../HtmlContainer';
import { CollectPhoneNumberPagolightPro } from './docs/CollectPhoneNumberPagolightPro';

const Root = styled('div', {
  shouldForwardProp: prop => !['height', 'heightMobile'].includes(prop),
})`
  height: ${props => props.height ?? 250}px;
  background-color: #f7f7f7;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: ${props => props.theme.shape.borderRadius}px;
  overflow-y: scroll;
  padding: 0 0.5rem;

  @media (max-width: 600px) {
    height: ${props => props.heightMobile ?? 290}px;
  }
`;

const pagolightProDocumentKey = 'scrollableContinerPhone';

export const ScrollableContainer = ({
  content,
  name,
  onScrollToBottom,
  options,
  ...props
}) => {
  const { height, heightMobile } = options;

  const handleScroll = useCallback(
    event => {
      const element = event.target;
      const isBottom =
        element.scrollHeight - element.scrollTop === element.clientHeight;

      if (isBottom && onScrollToBottom) {
        onScrollToBottom();
      }
    },
    [onScrollToBottom],
  );

  if (name === pagolightProDocumentKey) {
    return (
      <Root height={height} heightMobile={heightMobile} onScroll={handleScroll}>
        <CollectPhoneNumberPagolightPro />
      </Root>
    );
  }

  return (
    <>
      <Root
        height={height}
        heightMobile={heightMobile}
        onScroll={handleScroll}
        ref={props.innerRef}
      >
        {content ? (
          <HtmlContainer content={content} options={options} />
        ) : (
          props.children
        )}
      </Root>
    </>
  );
};

ScrollableContainer.propTypes = {
  name: PropTypes.string.isRequired,
  content: PropTypes.string,
  onScrollToBottom: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.shape(),
  ]),
  options: PropTypes.shape({
    allowLinks: PropTypes.bool,
    height: PropTypes.number,
    heightMobile: PropTypes.number,
  }),
  innerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

ScrollableContainer.defaultProps = {
  content: null,
  onScrollToBottom: null,
  children: [],
  options: {
    allowLinks: false,
    height: 250,
    heightMobile: 290,
  },
  innerRef: null,
};
