import { useSelector } from 'react-redux';
import { useTranslation } from '@hooks';
import { getFallbackUrl } from '@redux';
import { useSoftError } from '../../utils/useSoftError';
import { MerchantRedirectBlock } from './heylight';

const ErrorPage = () => {
  const { t } = useTranslation();
  const { softErrorCode, softErrorTranslation } = useSoftError();
  const fallbackUrl = useSelector(getFallbackUrl);

  return (
    <div>
      <h1>404</h1>
      {softErrorCode ? (
        <>
          <p>{`${softErrorTranslation}.`}</p>
          {fallbackUrl ? (
            <MerchantRedirectBlock
              name="merchantRedirect"
              options={{ hideProgress: false }}
              value={fallbackUrl}
            />
          ) : null}
        </>
      ) : (
        <p>{`${t('fields.error404.message')}.`}</p>
      )}
    </div>
  );
};

export default ErrorPage;
