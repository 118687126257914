import { bool, func, node, string } from 'prop-types';
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  Button,
} from '@mui/material';
import { useTranslation } from '@hooks';

export const Dialog = ({
  children,
  height = 'auto',
  isOpen,
  onClose,
  title,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <MuiDialog
      fullScreen={isMobile}
      fullWidth={true}
      maxWidth="md"
      onClose={onClose}
      open={isOpen}
      PaperProps={{ sx: { height: isMobile ? '100%' : height } }}
    >
      <DialogActions sx={{ padding: '0 1rem', justifyContent: 'flex-start' }}>
        <Button color="primary" onClick={onClose}>
          {t('fields.externalDocumentViewer.dialog.closeButton')}
        </Button>
      </DialogActions>

      {title ? (
        <DialogTitle sx={{ padding: '0 1rem 1rem 1rem' }} textAlign="center">
          {title}
        </DialogTitle>
      ) : null}

      <DialogContent sx={{ paddingTop: 0 }}>{children}</DialogContent>
    </MuiDialog>
  );
};

Dialog.propTypes = {
  children: node.isRequired,
  height: string,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  title: string,
};

Dialog.defaultProps = {
  height: 'auto',
  title: null,
};
