export function persistFallbackUrl(fallbackUrl) {
  try {
    if (window.sessionStorage) {
      window.sessionStorage.setItem('fallbackUrl', fallbackUrl);
    }
  } catch {
    // Access was denied
  }
}

export function getPersistedFallbackurl() {
  try {
    if (window.sessionStorage) {
      return window.sessionStorage.getItem('fallbackUrl');
    }
    return undefined;
  } catch {
    // Access was denied
    return undefined;
  }
}
