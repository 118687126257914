import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import { TextInput } from '../../../../transaction/components/heylight';
import { getMerchantProduct } from '../../../services/InstoreService';

export const InstoreProductSearchTextInput = ({
  complete,
  controlName,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  onSearchResult,
  setValue,
  value,
}) => {
  const [productDetails, setProductDetails] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getProductDetailsDebounced = useCallback(
    debounce(async productSku => {
      try {
        const details = await getMerchantProduct(productSku);
        setProductDetails(details);
      } catch {
        setProductDetails(null);
      }
    }, 500),
    [setProductDetails],
  );

  const handleOnChange = newValue => {
    if (newValue) {
      getProductDetailsDebounced(newValue);
    }
    onChange(newValue);
  };

  useEffect(() => {
    onSearchResult(productDetails);
  }, [onSearchResult, productDetails]);

  return (
    <TextInput
      complete={complete}
      controlName={controlName}
      error={error}
      inputRef={inputRef}
      name={name}
      onBlur={onBlur}
      onChange={handleOnChange}
      setValue={setValue}
      value={value}
    />
  );
};

InstoreProductSearchTextInput.propTypes = {
  complete: PropTypes.bool,
  error: PropTypes.string,
  inputRef: PropTypes.shape(),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  options: PropTypes.shape({
    priceFieldName: PropTypes.string,
    productAdditionalDetailsField: PropTypes.string,
    productNameField: PropTypes.string,
  }),
  value: PropTypes.string.isRequired,
};

InstoreProductSearchTextInput.defaultProps = {
  complete: false,
  error: '',
  inputRef: undefined,
  options: {
    disabled: false,
  },
};
