import { useCallback, useState } from 'react';
import debounce from 'lodash.debounce';
import { Nullable } from '@appTypes';
import getAutocompleteResults from '../../../../services/SwissPostAddressService';
import { AddressField } from '../../AddressContainerItalianPost/utils/constants';
import { defaultChoices } from '../constants';
import {
  AddressFieldValue,
  Choices,
  IAddressField,
  IAddressFieldConfig,
  IRefreshChoices,
} from '../types';

const requestStreetNameOnlyData = async (
  requestData: IAddressField,
  setChoices: (choices: Choices) => void,
) => {
  const results = (await getAutocompleteResults({
    ...requestData,
    streetNumber: '',
  })) as Choices;

  setChoices({
    ...results,
    streetNumber: [String(requestData.streetNumber)],
  });
};

const getChoices = async (
  requestData: IAddressField,
  setChoices: (choices: Choices) => void,
  setFieldLoading: (fieldName: Nullable<AddressFieldValue>) => void,
  fieldName?: AddressFieldValue,
  setErroringField?: (field: Nullable<AddressFieldValue>) => void,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  if (requestData?.zipCode) {
    const results = (await getAutocompleteResults(requestData)) as Choices;
    const hasValidResponseWithStreetNumbers =
      !requestData.streetNumber || results.streetNumber.length;

    if (hasValidResponseWithStreetNumbers) {
      setChoices(results);
    } else {
      await requestStreetNameOnlyData(requestData, setChoices);
    }

    setFieldLoading(null);

    if (
      setErroringField &&
      fieldName &&
      fieldName !== AddressField.streetNumber
    ) {
      setErroringField(results[fieldName].length ? null : fieldName);
    }
  }

  setIsLoading?.(false);
};

const getChoicesDebounce = debounce(getChoices, 300);

export const useAutoComplete = () => {
  const [fieldLoading, setFieldLoading] =
    useState<Nullable<AddressFieldValue>>();
  const [choices, setChoices] = useState<Choices>(defaultChoices);

  const resetChoices = (configField: IAddressFieldConfig) => {
    if (configField.onResetClearChoices) {
      const resetEntries = configField.onResetClearChoices.map(choice => [
        choice,
        [],
      ]);

      setChoices(prevChoices => ({
        ...prevChoices,
        ...Object.fromEntries(resetEntries),
      }));
      setFieldLoading(null);
    }
  };

  const refreshChoices = useCallback(
    async ({
      debounced = true,
      fieldName,
      requestData,
      setErroringField,
      setIsLoading,
    }: IRefreshChoices) => {
      setFieldLoading(fieldName);
      const refreshChoicesFunction = debounced
        ? getChoicesDebounce
        : getChoices;
      await refreshChoicesFunction(
        requestData,
        setChoices,
        setFieldLoading,
        fieldName,
        setErroringField,
        setIsLoading,
      );
    },
    [],
  );

  return {
    choices,
    refreshChoices,
    resetChoices,
    fieldLoading,
  };
};
