import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { logOfflineEvent } from '@redux';

export const useNetworkConnectionTracking = () => {
  const isRegistered = useRef(false);
  const dispatch = useDispatch();

  const handleOfflineEvent = debounce(() => {
    dispatch(logOfflineEvent());
  }, 500);

  const registerNetworkConnectionTracking = useCallback(() => {
    window.addEventListener('offline', handleOfflineEvent);
    isRegistered.current = true;
  }, [handleOfflineEvent]);

  const unregisterNetworkConnectionTracking = useCallback(() => {
    window.removeEventListener('offline', handleOfflineEvent, false);
  }, [handleOfflineEvent]);

  useEffect(() => {
    registerNetworkConnectionTracking();

    return unregisterNetworkConnectionTracking;
  }, [registerNetworkConnectionTracking, unregisterNetworkConnectionTracking]);
};
