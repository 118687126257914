import { useSelector } from 'react-redux';
import { Box, List, ListItem, styled } from '@mui/material';
import { getCancelUrl } from '@redux';
import { CopyBlock } from '../../../../components/CopyBlock';
import { icons } from '../../../../icons';
import { IDisplayListOptions, IDisplayListValues } from './types';

const IconWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  height: 35,
  width: 35,
});

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
});

const StyledList = styled(List)({
  width: 'inherit',
});

const StyledListItem = styled(ListItem)({
  display: 'flex',
  alignItems: 'flex-start',
  marginTop: '20px',
});

export interface IDisplayList {
  options: IDisplayListOptions;
  value?: IDisplayListValues;
}

export const DisplayList = ({ options, value }: IDisplayList) => {
  const cancelUrl = useSelector(getCancelUrl);
  const { email, items, page } = options;
  const { amount, dueDate } = value ?? {};

  return (
    <StyledList>
      {items.map(({ icon, translationKey }) => {
        const IconComponent = icon ? icons[icon] : null;

        return (
          <StyledListItem key={translationKey}>
            <IconWrapper>{IconComponent}</IconWrapper>
            <ContentWrapper>
              <CopyBlock
                i18nKey={`fields.displayList.${page}.${translationKey}`}
                sx={{
                  marginLeft: '20px',
                }}
                value={{ amount, dueDate, email, cancelUrl }}
              />
            </ContentWrapper>
          </StyledListItem>
        );
      })}
    </StyledList>
  );
};
