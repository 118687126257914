import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortStringMapArray } from '@heidi-pay/heidi-common-fe/utils';
import { IStringMap } from '@appTypes';
import { IBaseInputComponent } from 'src/transaction/components/types';
import { SelectPickerSearchable } from '../../../transaction/components/heylight/SelectPicker';
import { setAvailableProducts } from '../../redux';
import { getAvailableProducts } from '../../redux/selectors';
import { getMerchantProducts } from '../../services/InstoreService';

export interface IInstoreProductSelectProps
  extends IBaseInputComponent<null, string, string> {
  onSearchResult: (product: unknown) => void;
  searchKey?: 'sku' | 'name';
  withTopMargin?: boolean;
}

export const InstoreProductSelect = ({
  complete = false,
  error,
  inputRef,
  name,
  onBlur,
  onSearchResult,
  searchKey = 'sku',
  value = '',
  withTopMargin = false,
}: IInstoreProductSelectProps) => {
  const dispatch = useDispatch();
  const [productOptions, setProductOptions] = useState<IStringMap[]>([]);
  const availableProducts = useSelector(getAvailableProducts);

  useEffect(() => {
    const getProducts = async () => {
      const products = await getMerchantProducts();
      dispatch(setAvailableProducts(products));
    };
    getProducts();
  }, [dispatch]);

  useEffect(() => {
    const productOpts = availableProducts.map(
      ({ sku, [searchKey]: searchText }) => ({
        value: sku,
        text: searchText,
      }),
    );

    setProductOptions(productOpts);
  }, [availableProducts, searchKey]);

  const handleProductSelectedChange = useCallback(
    selectedProduct => {
      const productSelected = availableProducts.find(
        product => product.sku === selectedProduct.target.value,
      );

      if (productSelected) {
        onSearchResult(productSelected);
      }
    },
    [availableProducts, onSearchResult],
  );

  const translationKey = `fields.categorySelect.${name}.label`;

  return (
    <SelectPickerSearchable
      complete={complete}
      displayRawValues={true}
      error={error}
      inputRef={inputRef}
      label={translationKey}
      name={name}
      onBlur={onBlur}
      onChange={handleProductSelectedChange}
      options={{ items: sortStringMapArray(productOptions) }}
      sx={{ ...(withTopMargin ? {} : { mt: 0 }) }}
      value={value}
    />
  );
};
