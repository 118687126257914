import {
  Alert,
  Box,
  Button,
  Collapse,
  FormControl,
  FormLabel,
  FormHelperText,
  styled,
} from '@mui/material';
import { useTranslation } from '@hooks';
import { sendHelpRequest } from '../../../services/HelpService';
import LoadingBackdrop from '../LoadingBackdrop';
import { maximumCharacterCount, useHelpWidget } from './useHelpWidget';

const TextFieldOutlined = styled('textarea')`
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  height: 100px;

  &: focus-visible {
    outline: ${props => props.theme.palette.primary.main} auto 1px;
  }
  &: hover {
    border: 1px solid;
    border-color: inherit;
  }

  > div > fieldset {
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
`;

const HelpForm = () => {
  const { t } = useTranslation();
  const { setIsError, setIsLoading, setIsSent, setMessage, state } =
    useHelpWidget();

  const { isError, isLoading, isSent, message, numberOfCharactersRemaining } =
    state;

  const clearError = () => setIsError(false);

  const handleOnChange = event => setMessage(event.target.value);

  const handleSend = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const actionName = window.location.pathname;
      await sendHelpRequest({ actionName, message });
      setIsSent(true);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (isSent) {
    return <span>{t('helpWidget.form.success')}</span>;
  }

  return (
    <Box role="form">
      {isLoading ? <LoadingBackdrop contained={true} /> : null}

      <FormControl
        component="fieldset"
        fullWidth={true}
        sx={{ marginBottom: '0.625rem' }}
      >
        <FormLabel htmlFor="helpWidgetInput">
          {t('helpWidget.form.label')}
        </FormLabel>
        <TextFieldOutlined
          id="helpWidgetInput"
          maxLength={maximumCharacterCount}
          multiline={true}
          onChange={handleOnChange}
          rows={4}
          value={message}
        />
        <FormHelperText sx={{ marginLeft: 0 }}>
          {`${numberOfCharactersRemaining} ${t(
            'helpWidget.form.remainingCharacters',
          )}`}
        </FormHelperText>
      </FormControl>

      <Collapse in={isError}>
        <Alert icon={false} onClose={clearError} severity="error">
          {t('helpWidget.form.error')}
        </Alert>
      </Collapse>

      <Button
        color="primary"
        fullWidth={true}
        onClick={handleSend}
        sx={{ marginTop: '0.625rem' }}
        variant="text"
      >
        {t('helpWidget.form.submit')}
      </Button>
    </Box>
  );
};

export default HelpForm;
