export enum ContentType {
  Json = 'json',
  Xml = 'xml',
}

export enum HttpMethod {
  Delete = 'DELETE',
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
}

export const LockedSessionStatusCode = 423;
