import { useState, useEffect, useCallback } from 'react';

export const useWindowDimensions = () => {
  const getWindowDimensions = useCallback(() => {
    const { innerHeight: height, innerWidth: width } = window;
    return {
      height,
      width,
    };
  }, []);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getWindowDimensions]);

  return windowDimensions;
};
