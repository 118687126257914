import { useTranslation } from 'react-i18next';
import { IStringMap } from '@appTypes';
import { ComponentTypes } from '../enums';
import { IComplianceElement } from '../types';
import { ComplianceTrans } from './ComplianceTrans';
import { ConsentBlock } from './ConsentBlock';
import { ConsentBlockContained } from './ConsentBlockContained';

interface IComplianceElementProps {
  element: IComplianceElement;
  elementValidationErrorKey?: string;
  elementValue?: string;
  onConsentBlockClick?: (consentId: string, value: boolean) => void;
  onDialogLinkClick?: (dialogId: string) => void;
  translationInterpolatedValues?: IStringMap;
}

export const ComplianceElementComponent = ({
  element: { componentType, id, translationKey },
  elementValidationErrorKey,
  elementValue,
  onConsentBlockClick,
  onDialogLinkClick,
  translationInterpolatedValues,
}: IComplianceElementProps) => {
  const { t } = useTranslation('compliance');

  if (componentType === ComponentTypes.ComplianceTrans) {
    return (
      <ComplianceTrans
        i18nKey={translationKey}
        openDialog={onDialogLinkClick}
        value={translationInterpolatedValues}
      />
    );
  }

  if (componentType === ComponentTypes.ConsentBlock) {
    const Label = (
      <ComplianceTrans
        i18nKey={translationKey}
        value={translationInterpolatedValues}
      />
    );
    return (
      <ConsentBlock
        label={Label}
        name={id ?? ''}
        onChange={onConsentBlockClick}
        validationErrorTranslationKey={elementValidationErrorKey}
        value={elementValue}
      />
    );
  }

  if (componentType === ComponentTypes.ConsentBlockWithoutLabel) {
    return (
      <ConsentBlock
        name={id ?? ''}
        onChange={onConsentBlockClick}
        validationErrorTranslationKey={elementValidationErrorKey}
        value={elementValue}
      />
    );
  }

  if (componentType === ComponentTypes.ConsentBlockContained) {
    return (
      <ConsentBlockContained
        name={id ?? ''}
        onChange={onConsentBlockClick}
        validationErrorTranslationKey={elementValidationErrorKey}
        value={elementValue}
      />
    );
  }

  if (componentType === ComponentTypes.RawText) {
    return <>{t(translationKey)}</>;
  }

  return null;
};
