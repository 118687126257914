export enum MitekComponentType {
  All = 'ALL',
  Barcode = 'BARCODE',
  Documents = 'DOCUMENTS',
  Selfie = 'SELFIE',
}

export enum DocumentType {
  CheckBack = 'CHECK_BACK',
  CheckFront = 'CHECK_FRONT',
  Document = 'DOCUMENT',
  DrivingLicenseFront = 'DL_FRONT',
  Passport = 'PASSPORT',
  Selfie = 'SELFIE',
}

export enum ScanningStep {
  AutoScanningInProgress = 'autoScanningInProgress',
  Loading = 'loading',
  ScanResult = 'scanResult',
  ScanSubmitted = 'scanSubmitted',
  SelectProcess = 'selectProcess',
}

export enum MitekErrorCode {
  MitekErrorFocus = 'MITEK_ERROR_FOCUS',
}
