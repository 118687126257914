import { useTranslation } from 'react-i18next';
import { Alert, Link, styled } from '@mui/material';

interface IExternalSignatureNoticeProps {
  caseUri: string;
}

const StyledAlert = styled(Alert)`
  border-radius: ${props => props.theme.shape.borderRadius}px;
  margin-bottom: 1em;
  width: 100%;
  a {
    color: ${props => props.theme.palette.info.dark};
    font-weight: bold;
    text-decoration-color: ${props => props.theme.palette.info.dark};
  }
`;

export const ExternalSignatureNotice = ({
  caseUri,
}: IExternalSignatureNoticeProps) => {
  const { t } = useTranslation();

  return (
    <StyledAlert severity="info" variant="outlined">
      {t('fields.intrumIframeContainer.externalSignatureNotice.paragraph')}
      <Link href={caseUri} target="_blank">
        {t('fields.intrumIframeContainer.externalSignatureNotice.link')}
      </Link>
    </StyledAlert>
  );
};
