import { Translate } from '@heidi-pay/heidi-common-fe/types';
import {
  IPaymentRowProps,
  RowType,
} from '@heidi-pay/heidi-component-library/components/PaymentPlanTable';
import { IUseFormatter } from '../../../../../utils/formatters/types';
import { IPaymentPlanHeyLightOptions } from '../types';
import { IScheduleData } from './types';

function conditionalRender(condition: boolean, object: IPaymentRowProps) {
  return condition ? [object] : [];
}

export const getSwissCreditPaymentPlanFields = (
  {
    creditAmount,
    currency,
    interest,
    monthlyPayments,
    numberOfInstallments,
    orderProfileRequired,
    payMonthly,
    payNow,
    refreshPrice,
    residualPayment,
    showCreditFields,
    total,
    totalPricePlusInterest,
  }: IScheduleData,
  formatters: IUseFormatter,
  translate: Translate,
  options?: IPaymentPlanHeyLightOptions,
) => {
  if (!currency || !residualPayment || orderProfileRequired) {
    return null;
  }

  const { isDepositOverrideEnabled } = options ?? {};

  const baseKey = 'fields.paymentPlanSwissCredit';
  const { finalPayment, residualPaymentEnabled } = residualPayment;
  const { apr, interestAmount } = interest;
  const { formatAmountWithCurrency, formatDecimalToPercentage } = formatters;

  const monthlyTerm = residualPaymentEnabled
    ? monthlyPayments - 1
    : monthlyPayments;

  const fields: IPaymentRowProps[] = [
    ...conditionalRender(parseFloat(payNow.amount) > 0, {
      displayLabelBold: true,
      displayValueBold: true,
      label: translate(`${baseKey}.payNow`),
      labelVariant: 'h6',
      value: formatAmountWithCurrency(payNow.amount, currency),
      valueVariant: 'h6',
    }),
    ...conditionalRender(showCreditFields, {
      displayLabelBold: true,
      displayValueBold: true,
      label:
        monthlyPayments > 1
          ? translate(`${baseKey}.monthlyInstalments`, {
              monthlyPaymentTerm: monthlyTerm,
            })
          : translate(`${baseKey}.monthlyInstalment`),
      labelVariant: 'h6',
      value: formatAmountWithCurrency(payMonthly, currency),
      valueVariant: 'h6',
    }),
    ...conditionalRender(showCreditFields, {
      displayLabelBold: true,
      displayValueBold: true,
      label: translate(`${baseKey}.creditProductTotal`),
      labelVariant: 'h6',
      value: formatAmountWithCurrency(creditAmount, currency),
      valueVariant: 'h6',
    }),
    ...conditionalRender(!showCreditFields, {
      displayLabelBold: true,
      displayValueBold: true,
      label: translate(`${baseKey}.payMonthly`),
      labelVariant: 'h6',
      value: `${numberOfInstallments} x ${formatAmountWithCurrency(
        payMonthly,
        currency,
      )}`,
      valueVariant: 'h6',
    }),
    ...conditionalRender(!showCreditFields, {
      displayLabelBold: true,
      displayValueBold: true,
      label: translate(`${baseKey}.total`),
      labelVariant: 'h6',
      value: formatAmountWithCurrency(total, currency),
      valueVariant: 'h6',
    }),
    ...conditionalRender(residualPaymentEnabled && monthlyPayments > 1, {
      displayLabelBold: true,
      displayValueBold: true,
      label: translate(`${baseKey}.refreshPrice`),
      labelVariant: 'h6',
      value: formatAmountWithCurrency(refreshPrice, currency),
      valueVariant: 'h6',
    }),
    ...conditionalRender(residualPaymentEnabled && monthlyPayments > 1, {
      displayLabelBold: true,
      displayValueBold: true,
      labelVariant: 'h6',
      label: translate(`${baseKey}.returnOptionMonthly`, {
        overallTerm: monthlyPayments,
      }),
      value: formatAmountWithCurrency(finalPayment, currency),
      valueVariant: 'h6',
    }),
    ...conditionalRender(showCreditFields, {
      label: 'divider-1-key',
      rowType: RowType.Divider,
    }),
    ...conditionalRender(showCreditFields, {
      label: translate(`${baseKey}.annualInterestRate`),
      value: formatDecimalToPercentage(apr),
    }),
    ...conditionalRender(showCreditFields, {
      label: translate(`${baseKey}.creditDuration`),
      value: monthlyPayments,
    }),
    ...conditionalRender(residualPaymentEnabled, {
      label: translate(`${baseKey}.bulletInstalment`, {
        overallTerm: monthlyPayments,
      }),
      value: formatAmountWithCurrency(finalPayment, currency),
    }),
    ...conditionalRender(showCreditFields, {
      label: translate(`${baseKey}.totalInterest`),
      value: formatAmountWithCurrency(interestAmount, currency),
    }),
    ...conditionalRender(showCreditFields, {
      label: translate(`${baseKey}.totalPricePlusInterest`),
      value: formatAmountWithCurrency(totalPricePlusInterest, currency),
    }),
    ...conditionalRender(residualPaymentEnabled, {
      isLabelOnly: true,
      label: translate(
        `${baseKey}.refreshPriceDefinition${
          isDepositOverrideEnabled === true ? 'WithDeposit' : ''
        }`,
      ),
      value: '-',
    }),
  ];

  return fields.filter(field => field?.value !== null);
};
