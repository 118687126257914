import { arrayOf, string, func, shape, bool } from 'prop-types';
import * as yup from 'yup';
import { Box, FormControl, Typography } from '@mui/material';
import { useTranslation } from '@hooks';
import {
  CustomerAgreementBlock,
  ElectronicSignatureBlock,
  SignatureList,
} from './components';

const CustomerAgreementPagolightPro = ({
  error,
  name,
  onChange,
  options,
  value,
}) => {
  const { t } = useTranslation();

  // The signatures array contains objects with key and value fields, see validation schema below.
  const { pagolightProAgreedToElectronicSignature, signatures } = value;

  // The singatureLegalTextBlocks will store an object with keys matching signature objects keys.
  const {
    customerAgreementUri,
    electronicSignatureUri = 'https://www.compass.it/share/pdfs/COM318.pdf',
    singatureLegalTextBlocks,
  } = options;

  const {
    pagolightProAgreedToElectronicSignature: electronicSignatureAgreedError,
    pagolightProOpenedCustomerAgreement: customerAgreementOpenedError,
    pagolightProOpenedElectronicSignature: electronicSignatureOpenedError,
    signatures: signatureErrors,
  } = error || {};

  const handleElectronicSignatureOpened = newValue => {
    onChange({ ...value, pagolightProOpenedElectronicSignature: newValue });
  };

  const handleElectronicSignatureChecked = newValue => {
    onChange({ ...value, pagolightProAgreedToElectronicSignature: newValue });
  };

  const handleSignatureChange = newValue => {
    onChange({ ...value, signatures: newValue });
  };

  const handleCustomerAgreementOpened = newValue => {
    onChange({ ...value, pagolightProOpenedCustomerAgreement: newValue });
  };

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      name={name}
      size="small"
      variant="outlined"
    >
      <Box textAlign="center">
        <Typography fontSize="1.25em" fontWeight="bold" mb={6}>
          {t('fields.customerAgreementPagolightPro.title')}
        </Typography>

        <ElectronicSignatureBlock
          electronicSignatureUri={electronicSignatureUri}
          error={
            electronicSignatureOpenedError || electronicSignatureAgreedError
          }
          onChecked={handleElectronicSignatureChecked}
          onOpened={handleElectronicSignatureOpened}
          value={pagolightProAgreedToElectronicSignature}
        />

        <CustomerAgreementBlock
          customerAgreementUri={customerAgreementUri}
          error={customerAgreementOpenedError}
          onOpened={handleCustomerAgreementOpened}
        />

        <SignatureList
          errors={signatureErrors}
          onChange={handleSignatureChange}
          signatures={signatures}
          signatureTextBlocks={singatureLegalTextBlocks}
        />
      </Box>
    </FormControl>
  );
};

CustomerAgreementPagolightPro.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  options: shape({
    customer_agreement_uri: string,
    singature_legal_text_blocks: shape(),
  }).isRequired,
  value: shape({
    pagolightProAgreedToElectronicSignature: bool,
    signatures: arrayOf(
      shape({
        key: string,
        value: bool,
      }),
    ),
  }).isRequired,
  error: shape(),
};

CustomerAgreementPagolightPro.defaultProps = {
  error: {
    pagolightProAgreedToElectronicSignature: {},
    signatures: [],
  },
};

CustomerAgreementPagolightPro.validation = () =>
  yup
    .object()
    .shape({
      pagolightProOpenedElectronicSignature: yup
        .boolean()
        .oneOf(
          [true],
          'fields.customerAgreementPagolightPro.electronicSignatureBlock.requiredAccessed',
        ),
      pagolightProAgreedToElectronicSignature: yup
        .boolean()
        .oneOf(
          [true],
          'fields.customerAgreementPagolightPro.electronicSignatureBlock.requiredAccessed',
        ),
      pagolightProOpenedCustomerAgreement: yup
        .boolean()
        .oneOf(
          [true],
          'fields.customerAgreementPagolightPro.customerAgreementBlock.required',
        ),
      signatures: yup.array().of(
        yup.object().shape({
          key: yup.string(),
          value: yup
            .boolean()
            .oneOf(
              [true],
              'fields.customerAgreementPagolightPro.electronicSignatureBlock.required',
            ),
        }),
      ),
    })
    .required();

export default CustomerAgreementPagolightPro;
