import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

const mobileCountryRegexes: Record<string, RegExp> = {
  IT: /^(\+39)?3(\d{2})(\d{6,7})$/,
  CH: /^(\+41|0)?(7[2-9]|68|69)(\d{7})$/,
};

export const isValidMobileNumber = (phoneNumber: string): boolean => {
  if (!isValidPhoneNumber(phoneNumber)) {
    return false;
  }

  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
  const mobileRegex = mobileCountryRegexes[parsedPhoneNumber?.country ?? ''];
  return mobileRegex ? mobileRegex.test(phoneNumber) : true;
};
