/* eslint-disable max-lines */
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { addStyles } from '@utils';
import * as mitekScienceSDK from '../../../../public/lib/mitek-science-sdk';
import {
  IFrameCaptureResult,
  IComponentPreloadConfig,
  IFrameProcessingFeedback,
} from '../../../../public/lib/types';
import env from '../../../env';
import { IBaseInputComponent } from '../types';
import { AutoScanningContainer } from './components/AutoScanningContainer';
import { LoadingContainer } from './components/LoadingContainer';
import { ScanningResultContainer } from './components/ScanningResultContainer';
import { ToggleButtons } from './components/ToggleButtons';
import {
  CameraDisplayContainerStyles,
  FirstMessageDuration,
  getAutoCaptureOptions,
  getComponentPrealoadConfig,
  getProcessFrameConfig,
  SdkPath,
} from './config';
import {
  DocumentType,
  MitekErrorCode,
  MitekComponentType,
  ScanningStep,
} from './enums';
import { IState } from './types';
import { getFileExtension } from './utils/getFileExtension';

interface IMitekDocumentOcrContainerValue {
  documentBase64?: string;
  fileType?: string;
}

export type IMitekDocumentOcrContainerProps = IBaseInputComponent<
  null,
  IMitekDocumentOcrContainerValue
>;

const defaultState: IState = {
  step: ScanningStep.SelectProcess,
  errorCode: undefined,
  imageSrc: undefined,
};

export const MitekDocumentOcrContainer = ({
  onChange,
  submit,
}: IMitekDocumentOcrContainerProps) => {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    // loads the SDK features required
    const componentPreloadConfig: IComponentPreloadConfig =
      getComponentPrealoadConfig(env.MISNAP_SDK_LICENSE, [
        MitekComponentType.Documents,
      ]);
    mitekScienceSDK.cmd('COMPONENT_PRELOAD', componentPreloadConfig);

    return () => {
      mitekScienceSDK.cmd('SDK_STOP');
      mitekScienceSDK.cmd('SDK_REMOVE_LISTENERS');
    };
  }, []);

  const setError = useCallback(
    (errorCode: string) =>
      setState(currentState => ({
        ...currentState,
        errorCode: errorCode as MitekErrorCode,
      })),
    [],
  );

  const setCurrentStep = useCallback(
    (step: ScanningStep) =>
      setState(currentState => ({ ...currentState, step })),
    [],
  );

  const handleOnCancelClick = useCallback(() => {
    mitekScienceSDK.cmd('SDK_REMOVE_LISTENERS');
    mitekScienceSDK.cmd('SDK_STOP');
    setCurrentStep(ScanningStep.SelectProcess);
  }, [setCurrentStep]);

  const handleOnAutocaptureComplete = useCallback(() => {
    mitekScienceSDK.cmd('SDK_STOP');
  }, []);

  const handleOnRetryProcessClick = useCallback(() => {
    setCurrentStep(ScanningStep.SelectProcess);
  }, [setCurrentStep]);

  const handleOnError = useCallback(
    sdkErrorEvent => setError(sdkErrorEvent.code),
    [setError],
  );

  const getImageSrcFromOcrResponse = useCallback(response => {
    const { failedImage, imageData } = response.response;
    return (imageData || failedImage) ?? '';
  }, []);

  const handleFrameCaptureResult = useCallback(
    (result: IFrameCaptureResult) => {
      const imageSrc = getImageSrcFromOcrResponse(result);
      setState(currentState => ({
        ...currentState,
        imageSrc,
        step: ScanningStep.ScanResult,
      }));

      const { status, warnings } = result.response;
      if (status === 'failure') {
        setError(warnings ? JSON.stringify(warnings) : 'Unknown failure');
      }
    },
    [getImageSrcFromOcrResponse, setError],
  );

  const handleShowHint = useCallback((message: string, durationMs: number) => {
    mitekScienceSDK.cmd('SHOW_HINT', {
      options: {
        hintText: message,
        hintDuration: durationMs,
      },
    });
  }, []);

  const handleFrameProcessingFeedback = useCallback(
    (result: IFrameProcessingFeedback) => {
      const { key } = result;
      handleShowHint(key, 2000);
    },
    [handleShowHint],
  );

  const handleCameraDisplayStarted = useCallback(() => {
    setCurrentStep(ScanningStep.AutoScanningInProgress);

    handleShowHint(
      'Center document on a dark background',
      FirstMessageDuration,
    );

    addStyles(CameraDisplayContainerStyles);
  }, [handleShowHint, setCurrentStep]);

  const registerEventListeners = useCallback(() => {
    mitekScienceSDK.on('SDK_ERROR', handleOnError);

    // camera started
    mitekScienceSDK.on('CAMERA_DISPLAY_STARTED', handleCameraDisplayStarted);

    // camera finished capturing
    mitekScienceSDK.on('FRAME_CAPTURE_RESULT', handleFrameCaptureResult);

    // Triggers once when frames from live preview that undergo analysis has started. Use as signal for UX.
    mitekScienceSDK.on('FRAME_PROCESSING_STARTED', () => {
      // TODO display a spinner or progress bar
    });

    // direct
    mitekScienceSDK.on(
      'FRAME_PROCESSING_FEEDBACK',
      handleFrameProcessingFeedback,
    );
  }, [
    handleCameraDisplayStarted,
    handleFrameCaptureResult,
    handleFrameProcessingFeedback,
    handleOnError,
  ]);

  const clearState = useCallback(() => {
    setError('');
  }, [setError]);

  const onAutoCaptureClick = useCallback(() => {
    clearState();
    registerEventListeners();

    mitekScienceSDK.cmd('CAPTURE_AND_PROCESS_FRAME', {
      mode: 'AUTO_CAPTURE',
      documentType: DocumentType.Document,
      mitekSDKPath: SdkPath,
      options: getAutoCaptureOptions(env.MISNAP_SDK_LICENSE),
    });

    setCurrentStep(ScanningStep.Loading);
  }, [clearState, registerEventListeners, setCurrentStep]);

  const onFileSelected = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      clearState();
      registerEventListeners();

      const file = event.target.files?.[0];
      mitekScienceSDK.cmd(
        'PROCESS_FRAME',
        getProcessFrameConfig(
          file,
          DocumentType.Document,
          env.MISNAP_SDK_LICENSE,
        ),
      );
    },
    [clearState, registerEventListeners],
  );

  useEffect(() => {
    const { imageSrc } = state;
    if (imageSrc) {
      const imageChunks = imageSrc.split(',');
      const fileExtension = getFileExtension(imageChunks[0]);
      const documentBase64 = imageChunks[1];
      onChange({
        documentBase64,
        fileType: fileExtension,
      });
    } else {
      onChange({
        documentBase64: undefined,
        fileType: undefined,
      });
    }
  }, [state, onChange]);

  return (
    <>
      {state.step === ScanningStep.SelectProcess ? (
        <ToggleButtons
          onCaptureButtonClick={onAutoCaptureClick}
          onUploadButtonClick={onFileSelected}
        />
      ) : null}

      {state.step === ScanningStep.Loading ? <LoadingContainer /> : null}

      {state.step === ScanningStep.AutoScanningInProgress ? (
        <AutoScanningContainer
          onAutocaptureComplete={handleOnAutocaptureComplete}
          onCloseClick={handleOnCancelClick}
        />
      ) : null}

      {state.step === ScanningStep.ScanResult && submit ? (
        <ScanningResultContainer
          onRetryProcessClick={handleOnRetryProcessClick}
          onSubmitClick={submit}
          state={state}
        />
      ) : null}
    </>
  );
};
