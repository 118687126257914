import { useSelector } from 'react-redux';
import { ThemeProvider as ThemeProviderV5 } from '@mui/material';
import { Children } from '@appTypes';
import { FeatureFlag, useFeatureFlags } from '@hooks';
import {
  getIsWhiteLabel,
  getMerchantDetails,
  getThemeType,
} from '@redux/selectors';
import { getHeyLightTheme } from './config';
import { createThemes } from './utils';

interface IThemeProvider {
  children: Children;
}

export const ThemeProvider = ({ children }: IThemeProvider) => {
  const {
    logo,
    name: merchantName,
    primaryColour: merchantColor,
  } = useSelector(getMerchantDetails);
  const themeType = useSelector(getThemeType);
  const isWhiteLabel = useSelector(getIsWhiteLabel);

  const { flagEnabled } = useFeatureFlags();
  const isHeylightOverwriteByMerchantBrandingEnabled = flagEnabled(
    FeatureFlag.IsHeylightOverwriteByMerchantBrandingEnabled,
  );

  const isMerchantOverrideBranding =
    isWhiteLabel || isHeylightOverwriteByMerchantBrandingEnabled;

  const theme = getHeyLightTheme({
    isHeylightOverwriteByMerchantBrandingEnabled: isMerchantOverrideBranding,
    merchantColor: merchantColor || '#000',
    merchantName,
    logo,
    themeType,
  });

  const { themeV5 } = createThemes(theme);

  return (
    <ThemeProviderV5 key={themeType} theme={themeV5}>
      {children}
    </ThemeProviderV5>
  );
};
