import {
  Declined,
  ThumbsUp,
} from '@heidi-pay/heidi-component-library/components';
import {
  InstoreMoneyInput,
  InstoreMultiProductFormContainer,
  InstorePricingStructureCodePicker,
  InstoreProductSelectStandalone,
  OrderProfilePicker,
  VariantsPicker,
} from '../instore/components/heylight';

import {
  AdditionalDocumentsContainer,
  AdditionalUnderwritingContainer,
  AddressContainer,
  AddressContainerItalianPost,
  AddressContainerItalianPostOcr,
  AddressContainerSwissPost,
  ButtonGroupPicker,
  ConfirmationCheckbox,
  ComplianceContainerReadonly,
  ComplianceContainerWithConsents,
  ContinueButton,
  CustomerAgreementHeyLight,
  DateOfBirthInput,
  PaymentLinkDeliverySelector,
  DisplayList,
  HelpTooltip,
  InstoreProductSearchTextInput,
  IntroScreenItalian,
  IntroScreenSwiss,
  IntrumIdentityHoldingContainer,
  MerchantRedirectBlock,
  MobileNumberInput,
  MobileVerificationCodeInput,
  MonthlyPaymentsToggle,
  NationalityPicker,
  NumberPicker,
  OnfidoFailureContainer,
  OnfidoHoldingPage,
  OnfidoWelcomeScreenContainer,
  PaymentMethodsDisplay,
  PaymentPlan,
  PaymentPlanItalyCredit,
  PaymentPlanSwissCredit,
  PaymentPlanSwissMobilezone,
  PaymentPlanSwissMobilezoneInstore,
  ProductSelect,
  SelectPicker,
  SelectPickerSearchable,
  SelectPickerItalianProvince,
  SignatureList,
  SignContract,
  TermsAndConditionsCheckbox,
  TextInput,
  TextInputDocumentNumber,
  TextInputMoney,
  TextInputNumeric,
  TextBlockValue,
  ToggleButtonsList,
  VisibilityController,
  Divider,
  AlertText,
} from './components/heylight';

export const heyLightFieldToComponentMapping = {
  AdditionalDocumentsContainer,
  AdditionalUnderwritingContainer,
  AddressContainer,
  AddressContainerItalianPost,
  AddressContainerItalianPostOcr,
  AddressContainerSwissPost,
  AlertText,
  ButtonGroupPicker,
  ComplianceContainerReadonly,
  ComplianceContainerWithConsents,
  ConfirmationCheckbox,
  ContinueButton,
  CustomerAgreementHeyLight,
  DateOfBirthInput,
  DateInput: DateOfBirthInput,
  PaymentLinkDeliverySelector,
  DisplayList,
  Divider,
  FailureIcon: Declined,
  HelpTooltip,
  MoneyInput: InstoreMoneyInput,
  InstoreMultiProductFormContainer,
  InstorePaymentSummaryMobilezone: PaymentPlanSwissMobilezoneInstore,
  InstorePricingStructureCodePicker,
  InstoreProductSearchTextInput,
  InstoreProductSelectStandalone,
  IntrumIdentityHoldingContainer,
  IntroScreenItalian,
  IntroScreenSwiss,
  MerchantRedirectBlock,
  MobileNumberInput,
  MobileVerificationCodeInput,
  MonthlyPaymentsToggle,
  NationalityPicker,
  NumberPicker,
  OnfidoFailureContainer,
  OnfidoHoldingPage,
  OnfidoHoldingPageV2: OnfidoHoldingPage,
  OnfidoWelcomeScreenContainerV2: OnfidoWelcomeScreenContainer,
  OrderProfilePicker,
  PaymentPlan,
  PaymentMethodsDisplay,
  PaymentPlanMobilezone: PaymentPlanSwissMobilezone,
  PaymentPlanPagolightPro: PaymentPlanItalyCredit,
  PaymentPlanSwissCredit,
  ProductSelect,
  SelectPicker,
  SelectPickerItalianProvince,
  SelectPickerSearchable,
  SignatureList,
  SignContract,
  SuccessIcon: ThumbsUp,
  TermsAndConditionsCheckbox,
  TextBlockValue,
  TextInput,
  TextInputDocumentNumber,
  TextInputMoney,
  TextInputNumeric,
  ToggleButtonsList,
  VisibilityController,
  VariantsPicker,
};
