import { func, string } from 'prop-types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, Button, styled } from '@mui/material';

const ContainerWrapper = styled(Box)`
  padding: 0.25rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  margin-right: 34px;
`;

export const PdfTile = ({ documentUri, label, onOpened }) => {
  const openDocumentInNewTab = () => {
    onOpened(true);
    window.open(documentUri, '_blank');
  };

  return (
    <ContainerWrapper
      alignItems="center"
      display="inline-flex"
      textAlign="center"
    >
      <PictureAsPdfIcon fontSize="large" />
      <Button
        color="black"
        endIcon={<ArrowForwardIcon color="primary" />}
        onClick={openDocumentInNewTab}
        sx={{ textTransform: 'none' }}
        variant="text"
      >
        {label}
      </Button>
    </ContainerWrapper>
  );
};

PdfTile.propTypes = {
  onOpened: func.isRequired,
  label: string.isRequired,
  documentUri: string.isRequired,
};
