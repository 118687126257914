import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Children, Nullable } from '@appTypes';

interface IContinueBtbPortal {
  children: Children;
}

export const ContinueButtonPortal = ({ children }: IContinueBtbPortal) => {
  const [portalRoot, setPortalRoot] = useState<Nullable<HTMLElement>>(null);

  useEffect(() => {
    // we need to wait for the document to finish mounting before checking for the portal element
    const portalElement = document.getElementById('portal-continue-btn');
    setPortalRoot(portalElement);
  }, []);

  return portalRoot ? (
    ReactDOM.createPortal(children, portalRoot)
  ) : (
    <>{children}</>
  );
};
