import env from '../../env';
import { sortStrings } from '../../utils';
import { getFromXmlEndpoint } from '../../utils/http';

// Adds leading zeros to post codes
const normalisePostCode = code => code.toString().padStart(5, 0);

const isStringStartingWith = (str, searchKey) =>
  str.trim().toLowerCase().indexOf(searchKey.trim().toLowerCase()) === 0;

export const getBaseUri = isProxyEnabled =>
  isProxyEnabled
    ? `${env.ORIGINATION_SERVICE_URL}/api/checkout-client/proxy/italian-post`
    : 'https://normcloud.postel.it/wssearch/rest';

export async function getProvinces(isProxyEnabled = false) {
  try {
    const BASE_URI = getBaseUri(isProxyEnabled);
    const response = await getFromXmlEndpoint(`${BASE_URI}/province`);
    const { item: provinces } = response ?? { item: [] };
    return provinces
      .map(({ id: key, nome: displayValue, sigla: value }) => ({
        key,
        value,
        displayValue,
      }))
      .sort((a, b) => sortStrings(a.displayValue, b.displayValue));
  } catch {
    return [];
  }
}

export async function getCities(
  isProxyEnabled,
  municipalitySection,
  provinceId,
) {
  try {
    const BASE_URI = getBaseUri(isProxyEnabled);
    const response = await getFromXmlEndpoint(
      `${BASE_URI}/comuni?in=${municipalitySection}&prov=${provinceId}`,
    );
    const { items } = response ?? {};
    if (!items) {
      return [];
    }
    // Municipalities have key value fields by default
    const municipalities = Array.isArray(items) ? items : [items];
    return municipalities
      .filter(x => isStringStartingWith(x.value, municipalitySection))
      .map(({ key, value }) => ({ key, value, displayValue: value }));
  } catch {
    return [];
  }
}

// Postel will return cities ordered based on closest match to the search key for multiple results
export async function searchCityByName(isProxyEnabled, cityName) {
  try {
    const BASE_URI = getBaseUri(isProxyEnabled);
    const response = await getFromXmlEndpoint(
      `${BASE_URI}/search?in=${cityName}`,
    );
    const { item } = response ?? {};

    return Array.isArray(item) ? item[0] : item;
  } catch {
    return {};
  }
}

export async function getCityById(isProxyEnabled, cityId) {
  try {
    const BASE_URI = getBaseUri(isProxyEnabled);
    const response = await getFromXmlEndpoint(
      `${BASE_URI}/ufficiale/comune/${cityId}`,
    );

    return response?.items;
  } catch {
    return [];
  }
}

export async function getPostCodes(isProxyEnabled, cityId) {
  try {
    const { cap: postCodesPayload } =
      (await getCityById(isProxyEnabled, cityId)) ?? {};
    if (!postCodesPayload) {
      return [];
    }
    const postCodes = Array.isArray(postCodesPayload)
      ? postCodesPayload
      : [postCodesPayload];
    // The xml parser will cast the post codes to integer so leading zeros are removed
    return postCodes.map(item => ({
      value: normalisePostCode(item),
      displayValue: normalisePostCode(item),
    }));
  } catch {
    return [];
  }
}

// The locationId parameter can be either a municipality or a fraction id
export async function getStreetDetails(
  isProxyEnabled,
  streetSection,
  municipalityId,
) {
  try {
    const BASE_URI = getBaseUri(isProxyEnabled);
    const response = await getFromXmlEndpoint(
      `${BASE_URI}/strade?in=${streetSection}&loc=${municipalityId}`,
    );
    const { strada: streetPayload } = response ?? {};
    if (!streetPayload) {
      return [];
    }
    const streets = Array.isArray(streetPayload)
      ? streetPayload
      : [streetPayload];
    return streets.map(({ key, value }) => ({
      key,
      value,
      displayValue: value,
    }));
  } catch {
    return [];
  }
}
