import { Button, Grid } from '@mui/material';
import { CopyBlock } from '../../../../../components';
import { ContinueButton } from '../../ContinueButton';

import { IActionButtonsStackedProps } from './types';

export const ActionButtonsStacked = ({
  primaryButtonAction,
  primaryButtonOptions,
  secondaryButtonAction,
  secondaryButtonLabel,
}: IActionButtonsStackedProps) => (
  <Grid container={true}>
    <Grid item={true} textAlign="center" xs={12}>
      <Button
        color="primary"
        onClick={secondaryButtonAction}
        sx={{ fontWeight: 'bold', textTransform: 'none' }}
        variant="text"
      >
        <CopyBlock i18nKey={secondaryButtonLabel} />
      </Button>
    </Grid>
    <Grid item={true} textAlign="center" xs={12}>
      <ContinueButton
        options={primaryButtonOptions}
        submit={primaryButtonAction}
      />
    </Grid>
  </Grid>
);
