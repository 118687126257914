export default function actionCreator(name, func) {
  return (...args) => {
    const thunk = func(...args);

    if (process.env.NODE_ENV === 'development') {
      thunk.type = `${name}()`;
      thunk.toString = () => JSON.stringify(args, null, 2);
    }

    return thunk;
  };
}
