import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    // https://www.i18next.com/principles/fallback#fallback-language
    fallbackLng: code => {
      if (!code) {
        return ['en'];
      }

      // fake language for integration tests
      // simply let it fall back to the labels
      if (code.startsWith('xx')) {
        return code;
      }

      const [lang] = code.split(/[-_]/);
      return [
        code, // first, try the full locale
        lang, // then, fallback to just the language
        'en', // if we don't have a hit for the language, then just fall back to english
      ];
    },
    defaultNS: 'translation',
    ns: ['translation', 'compliance'],
    load: 'currentOnly',
    debug: process.env.NODE_ENV === 'development',
    returnObjects: true,
    interpolation: {
      escapeValue: false, // react already protects from xss
    },
    lowerCaseLng: true,
  });

export default i18n;
