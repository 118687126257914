/* eslint-disable no-param-reassign */
import {
  createSlice,
  PayloadAction,
  AnyAction,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { resetErrorState, setLoadingState, setErrorState } from '@redux';
import { AppDispatch, IRootState } from 'src/store';
import * as paymentService from '../services/PaymentService';
import { IPaymentPlan, IPaymentState } from './types';

export const initialState: IPaymentState = {
  paymentPlan: {
    summary: {
      currency: '',
      creditAmount: 0,
      payNow: {
        percentage: '',
        amount: '',
      },
      payMonthly: '',
      total: '',
      heldOnCard: {
        percentage: '',
        amount: '',
      },
      residualPayment: {
        residualPaymentEnabled: false,
        finalPayment: '',
      },
      interest: {
        interestAmount: '',
        apr: '',
        nominalInterestRate: '',
      },
      initialBalance: 0,
      initialPayment: '',
      refreshPrice: 0,
      totalPricePlusInterest: 0,
    },
    schedule: [],
  },
  monthlyPayments: 0,
  paymentMethod: '',
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    updatePaymentPlan: (state, action: PayloadAction<IPaymentPlan>) => {
      state.paymentPlan = action.payload;
    },
    updateMonthlyPayments: (state, action: PayloadAction<number>) => {
      state.monthlyPayments = action.payload;
    },
    updatePaymentMethod: (state, action: PayloadAction<string>) => {
      state.paymentMethod = action.payload;
    },
  },
});

export const { updateMonthlyPayments, updatePaymentMethod, updatePaymentPlan } =
  paymentSlice.actions;

export const getPaymentPlan = (state: { payment: IPaymentState }) =>
  state.payment.paymentPlan;

export const getMonthlyPayments = (state: { payment: IPaymentState }) =>
  state.payment.monthlyPayments;

export const getPaymentMethod = (state: { payment: IPaymentState }) =>
  state.payment.paymentMethod;

export const loadPaymentPlan =
  () =>
  async (
    dispatch: ThunkDispatch<IRootState, void, AnyAction>,
    getState: () => IRootState,
  ) => {
    try {
      const state = getState();
      const monthlyPayments = getMonthlyPayments(state) || 3;
      const paymentMethod = getPaymentMethod(state);
      dispatch(setLoadingState(true));
      dispatch(resetErrorState());
      const paymentPlan = await paymentService.loadPaymentPlan(
        monthlyPayments,
        paymentMethod,
      );
      dispatch(updatePaymentPlan(paymentPlan));
    } catch (error) {
      dispatch(setErrorState(error));
    } finally {
      dispatch(setLoadingState(false));
    }
  };

export const setPaymentMethod =
  (paymentMethod: string): ThunkAction<void, IRootState, void, AnyAction> =>
  (dispatch: ThunkDispatch<IRootState, void, AnyAction>) => {
    dispatch(updatePaymentMethod(paymentMethod));
    dispatch(loadPaymentPlan());
  };

export const setMonthlyPayments =
  (monthlyPayments: number) => (dispatch: AppDispatch) => {
    dispatch(updateMonthlyPayments(monthlyPayments));
  };

export const resetPaymentPlan = () => (dispatch: AppDispatch) => {
  dispatch(updatePaymentPlan(initialState.paymentPlan));
};

export default paymentSlice.reducer;
