import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import {
  getAnimationLenghtForPhrase,
  getDisplayPhrases,
} from './phraseDisplayHelpers';

const maximumPhraseLength = 45;
const maximumAnimationLength = 2.5;
const rightBorderStyle = '.15em solid #0179a2';

const TypeWriter = styled('div')`
  min-height: 1.5rem;
  display: block;
  font-weight: bold;
  p {
    width: 0;
    overflow: hidden;
    margin: 0;
    border-right: ${rightBorderStyle};
    font-family: arial;
    font-size: 0.85rem;
    line-height: 1.5rem;
    white-space: nowrap;
  }

  p.first {
    animation: typing 2.5s steps(40, end) forwards, blink 0.7s infinite;
  }

  p.second {
    animation: typing2 ${props => props.secondAnimationLength}s steps(40, end)
        forwards,
      blink 0.7s infinite;
    animation-delay: 2.5s;
    -webkit-animation-delay: 2.5s;
    opacity: 0;
  }

  @media (max-width: 600px) {
    font-size: 0.75rem;
  }

  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
    99.9% {
      border-right: ${rightBorderStyle};
    }
    100% {
      border: none;
    }
  }

  @keyframes typing2 {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: ${rightBorderStyle};
    }
    100% {
      opacity: 1;
      border: none;
    }
  }

  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  @-webkit-keyframes blink {
    50% {
      border-color: tranparent;
    }
  }
`;

const TypingTextContainer = ({ phrase }) => {
  const { phraseOne, phraseTwo } = useMemo(
    () => getDisplayPhrases(phrase, maximumPhraseLength),
    [phrase],
  );

  const secondPhraseAnimationLength = useMemo(
    () =>
      getAnimationLenghtForPhrase(
        phraseTwo,
        maximumAnimationLength,
        maximumPhraseLength,
      ),
    [phraseTwo],
  );

  if (!phrase) {
    return <span>...</span>;
  }

  return (
    <>
      <TypeWriter>
        <p className="first">{phraseOne}</p>
      </TypeWriter>
      <TypeWriter secondAnimationLength={secondPhraseAnimationLength}>
        <p className="second">{phraseTwo}</p>
      </TypeWriter>
    </>
  );
};

TypingTextContainer.propTypes = {
  phrase: PropTypes.string,
};

TypingTextContainer.defaultProps = {
  phrase: null,
};

export default TypingTextContainer;
