import { useSelector } from 'react-redux';
import { useTranslation } from '@hooks';
import { getSoftErrorCode } from '@redux';
import { SoftErrorKeys } from './enums';

export const useSoftError = () => {
  const { t } = useTranslation();

  const softErrorCode = useSelector(getSoftErrorCode);
  const isSoftError = Object.values(SoftErrorKeys).includes(softErrorCode);
  const softErrorTranslation = t(
    `softError.${isSoftError ? softErrorCode : 'default'}`,
  );

  return { isSoftError, softErrorCode, softErrorTranslation };
};

export default useSoftError;
