import { ComponentTypes } from '../enums';
import { IComplianceContainerContent } from '../types';
import { ConsentTypeItalyCredit, CreditDialogId } from './enums';

export const personalDetailsComplianceContent: IComplianceContainerContent = {
  dialogContent: [
    {
      complianceElements: [
        {
          componentType: ComponentTypes.ComplianceTrans,
          translationKey:
            'personalDetailsItalyCredit.dialog1.contentBeforeConsent',
        },
        {
          componentType: ComponentTypes.ConsentBlockContained,
          id: ConsentTypeItalyCredit.MarketResearchCommercialAndPromotional,
          translationKey: '',
        },
        {
          componentType: ComponentTypes.ComplianceTrans,
          translationKey:
            'personalDetailsItalyCredit.dialog1.contentAfterConsent',
        },
      ],
      id: CreditDialogId.PersonalDetailsDialog1,
      title: {
        componentType: ComponentTypes.RawText,
        translationKey: 'personalDetailsItalyCredit.dialog1.title',
      },
    },
    {
      complianceElements: [
        {
          componentType: ComponentTypes.ComplianceTrans,
          translationKey:
            'personalDetailsItalyCredit.dialog2.contentBeforeConsent',
        },
        {
          componentType: ComponentTypes.ConsentBlockContained,
          id: ConsentTypeItalyCredit.ProfilingActivitiesForPromotionalCommunications,
          translationKey: '',
        },
        {
          componentType: ComponentTypes.ComplianceTrans,
          translationKey:
            'personalDetailsItalyCredit.dialog2.contentAfterConsent',
        },
      ],
      id: CreditDialogId.PersonalDetailsDialog2,
      title: {
        componentType: ComponentTypes.RawText,
        translationKey: 'personalDetailsItalyCredit.dialog2.title',
      },
    },
    {
      complianceElements: [
        {
          componentType: ComponentTypes.ComplianceTrans,
          translationKey:
            'personalDetailsItalyCredit.dialog3.contentBeforeConsent',
        },
        {
          componentType: ComponentTypes.ConsentBlockContained,
          id: ConsentTypeItalyCredit.ThirdPartyCommercialAndPromotionalActivities,
          translationKey: '',
        },
        {
          componentType: ComponentTypes.ComplianceTrans,
          translationKey:
            'personalDetailsItalyCredit.dialog3.contentAfterConsent',
        },
      ],
      id: CreditDialogId.PersonalDetailsDialog3,
      title: {
        componentType: ComponentTypes.RawText,
        translationKey: 'personalDetailsItalyCredit.dialog3.title',
      },
    },
    {
      complianceElements: [
        {
          componentType: ComponentTypes.ComplianceTrans,
          translationKey:
            'personalDetailsItalyCredit.dialog4.contentBeforeConsent',
        },
        {
          componentType: ComponentTypes.ConsentBlockContained,
          id: ConsentTypeItalyCredit.DataProcessingInternalAndExternal,
          translationKey: '',
        },
        {
          componentType: ComponentTypes.ComplianceTrans,
          translationKey:
            'personalDetailsItalyCredit.dialog4.contentAfterConsent',
        },
      ],
      id: CreditDialogId.PersonalDetailsDialog4,
      title: {
        componentType: ComponentTypes.RawText,
        translationKey: 'personalDetailsItalyCredit.dialog4.title',
      },
    },
  ],
  formContent: [
    {
      componentType: ComponentTypes.ComplianceTrans,
      translationKey: 'personalDetailsItalyCredit.form.block0',
    },
    {
      componentType: ComponentTypes.ComplianceTrans,
      dialogId: CreditDialogId.PersonalDetailsDialog1,
      translationKey: 'personalDetailsItalyCredit.form.block1',
    },
    {
      componentType: ComponentTypes.ConsentBlockWithoutLabel,
      id: ConsentTypeItalyCredit.MarketResearchCommercialAndPromotional,
      translationKey: '',
    },
    {
      componentType: ComponentTypes.ComplianceTrans,
      dialogId: CreditDialogId.PersonalDetailsDialog2,
      translationKey: 'personalDetailsItalyCredit.form.block2',
    },
    {
      componentType: ComponentTypes.ConsentBlockWithoutLabel,
      id: ConsentTypeItalyCredit.ProfilingActivitiesForPromotionalCommunications,
      translationKey: '',
    },
    {
      componentType: ComponentTypes.ComplianceTrans,
      dialogId: CreditDialogId.PersonalDetailsDialog3,
      translationKey: 'personalDetailsItalyCredit.form.block3',
    },
    {
      componentType: ComponentTypes.ConsentBlockWithoutLabel,
      id: ConsentTypeItalyCredit.ThirdPartyCommercialAndPromotionalActivities,
      translationKey: '',
    },
    {
      componentType: ComponentTypes.ComplianceTrans,
      dialogId: CreditDialogId.PersonalDetailsDialog4,
      translationKey: 'personalDetailsItalyCredit.form.block4',
    },
    {
      componentType: ComponentTypes.ConsentBlockWithoutLabel,
      id: ConsentTypeItalyCredit.DataProcessingInternalAndExternal,
      translationKey: '',
    },
  ],
};
