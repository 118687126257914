import { createContext, useContext, ReactNode } from 'react';
import { IStringMap, Nullable } from '@appTypes';

const TranslateOverrideContext = createContext<Nullable<IStringMap>>(null);

export const useTranslationOverrides = () =>
  useContext(TranslateOverrideContext);

interface ITranslateOverrideProviderProps {
  children: ReactNode;
  overrides: IStringMap;
}

export const TranslateOverrideProvider = ({
  children,
  overrides,
}: ITranslateOverrideProviderProps) => (
  <TranslateOverrideContext.Provider value={overrides}>
    {children}
  </TranslateOverrideContext.Provider>
);
