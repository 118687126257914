import { useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { SentrySeverity } from '@utils';
import env from '../../../../env';
import { ISalesforceConfig } from '../types';

export const useGetSalesforceConfig = (): ISalesforceConfig | undefined => {
  const [config, setConfig] = useState<ISalesforceConfig>();

  const getConfig = useCallback(async () => {
    try {
      if (env.ENV) {
        const configFile = await import(
          `../configs/salesforceConfig.${env.ENV}.ts`
        );
        setConfig(configFile.config);
      }
    } catch (ex) {
      Sentry.captureException(ex, {
        level: SentrySeverity.error,
      });
    }
  }, []);

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  return config;
};
