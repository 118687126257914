import { useMemo } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Grid, Typography, Box } from '@mui/material';
import { useTranslation } from '@hooks';
import { SignatureBlock } from './SignatureBlock';

export const SignatureList = ({
  errors,
  onChange,
  signatures,
  signatureTextBlocks,
}) => {
  const { t } = useTranslation();

  const getSignatureList = useMemo(
    () =>
      signatures.map(({ key, value }) => ({
        key,
        value,
        textContent: signatureTextBlocks[key],
      })),
    [signatures, signatureTextBlocks],
  );

  const handleSignatureBlockChange = (item, newValue, index) => {
    const newArray = [
      ...signatures.slice(0, index),
      { key: item.signatureKey, value: newValue },
      ...signatures.slice(index + 1),
    ];
    onChange(newArray);
  };

  const signatureBlocks = getSignatureList.map(
    ({ key, textContent, value }, index) => ({
      label: t(
        'fields.customerAgreementPagolightPro.signatureList.signatureLabel',
        { index: index + 1 },
      ),
      signatureKey: key,
      textContent,
      value,
    }),
  );

  return (
    <Box textAlign="center">
      <Typography fontWeight="bold" mt={6}>
        {t('fields.customerAgreementPagolightPro.signatureList.listTitle')}
      </Typography>

      <Grid container={true}>
        {signatureBlocks.map((block, index) => (
          <SignatureBlock
            error={errors[index]?.value.message}
            key={block.signatureKey}
            label={block.label}
            onChange={value => handleSignatureBlockChange(block, value, index)}
            signatureKey={block.signatureKey}
            textContent={block.textContent}
            value={block.value}
          />
        ))}
      </Grid>
    </Box>
  );
};

SignatureList.propTypes = {
  signatures: arrayOf(
    shape({
      key: string,
      value: bool,
    }),
  ).isRequired,
  signatureTextBlocks: shape().isRequired,
  onChange: func.isRequired,
  errors: arrayOf(shape()),
};

SignatureList.defaultProps = {
  errors: {},
};
