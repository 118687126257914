import { useEffect, useMemo, useState } from 'react';

export const useExecuteWithCountdown = ({
  callback,
  prematureExecutionTimeMs = 0,
  timeoutMs,
}) => {
  const [countdown, setCountdown] = useState(0);

  const progressIncrement = useMemo(() => (100 * 100) / timeoutMs, [timeoutMs]);

  const progressToExecuteCallbackAt = useMemo(
    () => 100 - (progressIncrement * prematureExecutionTimeMs) / 100,
    [progressIncrement, prematureExecutionTimeMs],
  );

  useEffect(() => {
    const timer = setInterval(() => {
      if (Math.round(countdown) === Math.round(progressToExecuteCallbackAt)) {
        callback();
        clearInterval(timer);
      }
      setCountdown(prevCountdown =>
        prevCountdown >= 100 ? 100 : prevCountdown + progressIncrement,
      );
    }, 100);

    return () => clearInterval(timer);
  }, [
    callback,
    countdown,
    progressIncrement,
    progressToExecuteCallbackAt,
    setCountdown,
  ]);

  return { countdown };
};
