import { call } from '../../utils/http';

export const VerificationStatus = Object.freeze({
  verified: 'verified',
  invalid: 'invalid',
});

export const VerificationCodeTypes = Object.freeze({
  signContract: 'sign_contract',
  phoneVerification: 'phone_verification',
});

export async function verifyCode(code) {
  const { status } =
    (await call('/api/checkout-client/text/verify/', {
      method: 'POST',
      body: JSON.stringify({
        verification_code: code,
      }),
    })) || {};

  return { isValid: status === VerificationStatus.verified };
}

export async function generateCode(
  verificationCodeType = VerificationCodeTypes.phoneVerification,
) {
  return call('/api/checkout-client/text/resend/', {
    method: 'POST',
    body: JSON.stringify({
      verification_code_type: verificationCodeType,
    }),
  });
}
