import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { SelectInput } from '@heidi-pay/heidi-component-library/components';
import { FormControl, FormHelperText } from '@mui/material';
import { useTranslation } from '@hooks';
import {
  setSelectedShippingCountry,
  setSelectedBillingCountry,
} from '@instore/redux';
import { getOrderProfileCountries } from '@instore/redux/selectors';

export default function CountryLabel({
  addressContainerType,
  error,
  inputRef,
  name,
  onBlur,
  onChange,
  value,
}) {
  const { t } = useTranslation();
  const [country, setCountry] = useState(value);
  const dispatch = useDispatch();

  const handleChange = useCallback(
    event => {
      onChange(event.target.value);
      setCountry(event.target.value);
    },
    [onChange],
  );

  useEffect(() => {
    // when a country in the dropdown is selected, we make note of it
    if (addressContainerType === 'billingAddress') {
      dispatch(setSelectedBillingCountry(country));
    } else {
      dispatch(setSelectedShippingCountry(country));
    }
  }, [addressContainerType, dispatch, country]);

  // This is set when we load the instore form
  const orderProfileCountries = useSelector(getOrderProfileCountries) ?? [];

  // This is only populated after a use submits the instore form
  const inStoreCountries =
    useSelector(state => state.instore.countriesFromInstore) || [];

  const inStoreOrCheckout =
    orderProfileCountries.length > 0 ? orderProfileCountries : inStoreCountries;

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      size="small"
      variant="outlined"
    >
      <SelectInput
        id={name}
        inputRef={inputRef}
        key={value}
        label={t('fields.CountryLabel.label')}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        value={value}
      >
        {inStoreOrCheckout.map(element => (
          <option key={value}>{t(element)}</option>
        ))}
      </SelectInput>
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
}

CountryLabel.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  inputRef: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.string,
  addressContainerType: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
};

CountryLabel.defaultProps = {
  value: '',
  inputRef: undefined,
  error: '',
  autoComplete: undefined,
};
