import { Box, LinearProgress, SxProps, Theme } from '@mui/material';
import { BorderRadius } from '../../../theme';

interface IProgressBarProps {
  ariaLabel?: string;
  backgroundColor?: string;
  countdown?: number;
  height?: string;
  isRounded?: boolean;
  sx?: SxProps<Theme>;
  variant?: 'determinate' | 'indeterminate' | 'buffer' | 'query';
}

export const ProgressBar = ({
  ariaLabel = 'progress bar',
  backgroundColor,
  countdown,
  height,
  isRounded = false,
  sx = {},
  variant = 'determinate',
}: IProgressBarProps) => (
  <Box my={4} sx={{ width: '100%', height: '10px' }}>
    <LinearProgress
      aria-label={ariaLabel}
      aria-valuemax={100}
      aria-valuemin={0}
      aria-valuenow={countdown}
      sx={{
        ...sx,
        ...(backgroundColor ? { backgroundColor } : {}),
        borderRadius: isRounded ? BorderRadius.Small : '0',
        height: height ?? '4px',
      }}
      value={countdown}
      variant={variant}
    />
  </Box>
);
