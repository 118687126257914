export const updateProductValue =
  (index, setValue) =>
  (name, value, shouldTouchAndValidate = true) => {
    setValue(
      `products.${index}.${name}`,
      value ?? '',
      shouldTouchAndValidate
        ? {
            shouldTouch: true,
            shouldValidate: true,
          }
        : null,
    );
  };
