const italianIbanRegex = /^IT\d{2}[A-Z]\d{10}[0-9A-Z]{12}$/i;

const firstTwoDigitsAreLettersRegex = /^[A-Z]{2}\d/i;

export const isNonItalianIban = iban => !iban.toLowerCase().includes('it');

export const isItalianIbanValid = iban => italianIbanRegex.test(iban);

export const areFirstTwoDigistsLetters = iban =>
  firstTwoDigitsAreLettersRegex.test(iban);

export const registerIbanValidation = ({ errorTranslationKey, schema }) =>
  schema.test(
    'iban-number',
    errorTranslationKey,
    value =>
      areFirstTwoDigistsLetters(value) &&
      (isNonItalianIban(value) || isItalianIbanValid(value)),
  );
