import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { userNavigation } from '../transaction/redux';

export default function LocationWatcher() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userNavigation(location.pathname));
  }, [dispatch, location]);

  return null;
}
