import * as Sentry from '@sentry/react';
import { ValueOf } from '@appTypes';
import env from '../env';

const defaultTagValue = 'unknown';

export const axiosNetworkErrorCode = 'ERR_NETWORK'; // Network-related issue.

export const OriginatedOnBackendTagKey = 'originated_on_backend';

export const SentrySeverity = Object.freeze({
  debug: 'debug',
  error: 'error',
  fatal: 'fatal',
  info: 'info',
  log: 'log',
  warning: 'warning',
});

export const SentryEnvironments = Object.freeze({
  prod: 'prod',
  qa: 'qa',
  sandbox: 'sandbox',
});

type SentryEnv = ValueOf<typeof SentryEnvironments>;

const replayDisabledEnvs: SentryEnv[] = [
  SentryEnvironments.sandbox,
  SentryEnvironments.prod,
];

interface IFormatSentryEventTags {
  checkoutType: string;
  merchantName: string;
  originationChannel: string;
  sessionUuid: string;
}

export const formatSentryEventTags = ({
  checkoutType,
  merchantName,
  originationChannel,
  sessionUuid,
}: IFormatSentryEventTags) => ({
  checkout_type: checkoutType || defaultTagValue,
  merchant_name: merchantName || defaultTagValue,
  origination_channel: originationChannel || defaultTagValue,
  origination_session_uuid: sessionUuid || defaultTagValue,
  [OriginatedOnBackendTagKey]: false,
});

export const setSentryGlobalScope = (tags: Record<string, string | number>) => {
  Sentry.getCurrentScope().setTags(tags);
};

let replay: ReturnType<typeof Sentry.replayIntegration>;

export const initReplay = () => {
  replay = Sentry.replayIntegration({
    maskAllText: true,
    blockAllMedia: true,
  });
};

export const getSentryIntegrations = (sentryEnv: SentryEnv) => {
  const shouldEnableReplays = !replayDisabledEnvs.includes(sentryEnv);

  if (shouldEnableReplays) {
    initReplay();
    return [Sentry.browserTracingIntegration(), replay];
  }

  return [Sentry.browserTracingIntegration()];
};

export const toggleReplay = (enableReplay: boolean) => {
  const client = Sentry.getClient();
  const areReplaysAllowed = !replayDisabledEnvs.includes(env.ENV);

  if (!areReplaysAllowed || !client || !replay) {
    return;
  }

  if (enableReplay) {
    replay.start();
  } else {
    replay.stop();
  }
};

// When debugging we might want to limit replays to a reduced number of orders
export const disableSentryReplay = () => toggleReplay(false);
export const enableSentryReplay = () => toggleReplay(true);
