import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { useTranslation } from '@hooks';

import {
  getSelectedShipppingCountry,
  getSelectedBillingCountry,
} from '@instore/redux/selectors';
import { parseLocale } from '@utils';
import { convertAddress, getIsConverting } from '../../../../../address/redux';
import { getParsedCityName } from '../utils/getParsedCityName';
import GooglePlacesAdapter from './GooglePlacesAdapter';

const emptyAddressPayload = {
  addressLine1: '',
  addressLine2: '',
  countryAlpha2: '',
  zipCode: '',
  city: '',
  placeId: '',
};

export default function AddressLookup({
  addressContainerType,
  error,
  fullAddress,
  name,
  onBlur,
  onChange,
  reset,
  value,
}) {
  const { i18n, t } = useTranslation();
  const defaultLocale = parseLocale(i18n.language);

  const selector =
    addressContainerType === 'billingAddress'
      ? getSelectedBillingCountry
      : getSelectedShipppingCountry;

  const selectedOrExistingAddressCountry =
    useSelector(selector) || fullAddress?.countryAlpha2;

  const country = selectedOrExistingAddressCountry || defaultLocale.country;

  const [addressBeforeEdit, setAddressBeforeEdit] = useState(fullAddress);

  const hasCountryChanged =
    addressBeforeEdit?.countryAlpha2?.toLowerCase() !== country.toLowerCase();

  const dispatch = useDispatch();

  const isConverting = useSelector(getIsConverting);

  useEffect(() => {
    if (hasCountryChanged) {
      const newAddressData = {
        ...emptyAddressPayload,
        countryAlpha2: country,
      };
      setAddressBeforeEdit(newAddressData);
      reset(newAddressData);
    }
  }, [country, hasCountryChanged, reset]);

  const handleChange = async result => {
    onChange(result?.label);
    if (result.details) {
      const address = await dispatch(convertAddress(result.details));
      address.city = getParsedCityName(address.city);
      setAddressBeforeEdit(address);
      reset(address);
    } else {
      const newAddressData = {
        ...addressBeforeEdit,
        addressLine1: result?.label,
        countryAlpha2: country,
        placeId: '',
      };
      setAddressBeforeEdit(newAddressData);
      reset(newAddressData);
    }
  };
  return (
    <TextField
      error={!!error}
      fullWidth={true}
      id={name}
      InputProps={{
        inputComponent: GooglePlacesAdapter,
        inputProps: {
          country,
          isConverting,
        },
      }}
      label={t(`fields.addressContainer.subFields.${name}.label`)}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      size="small"
      value={value}
      variant="outlined"
    />
  );
}

AddressLookup.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.string,
  reset: PropTypes.func.isRequired,
  addressContainerType: PropTypes.string.isRequired,
};

AddressLookup.defaultProps = {
  value: '',
  error: '',
};
