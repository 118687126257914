/* eslint-disable react/jsx-no-bind */
import { useCallback, useMemo, useState } from 'react';
import {
  ISelectInputItem,
  SelectInput,
} from '@heidi-pay/heidi-component-library/components';
import { InputFieldAdornment } from '@heidi-pay/heidi-component-library/components/InputFieldAdornment';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material';
import { useTranslation } from '@hooks';
import { IOption, IOrderProfileTermsPicker } from '../types';
import { getTermsAutocompleteOptions } from '../utils/mappingFunctions';

export const OrderProfileTermsPicker = ({
  availableTerms,
  error = '',
  isSingleInstallmentTermSelection = false,
  onBlur,
  onChange,
  selectedTerms,
}: IOrderProfileTermsPicker) => {
  const { t } = useTranslation();
  const [singleSelectedTerm, setSingleSelectedTerm] = useState('');

  const availableTermOptions = getTermsAutocompleteOptions({
    terms: availableTerms,
    translate: t,
  });

  const selectedTermOptions = getTermsAutocompleteOptions({
    terms: selectedTerms,
    translate: t,
  });

  const handleOnMultiChange = useCallback(
    (_, options: IOption[]) => onChange(options.map(({ value }) => value)),
    [onChange],
  );

  const handleOnSingleChange = useCallback(
    e => {
      setSingleSelectedTerm(e.target.value);
      onChange([e.target.value]);
    },
    [onChange, setSingleSelectedTerm],
  );

  const selectItems: ISelectInputItem[] = useMemo(
    () =>
      availableTermOptions.map(({ label, value }) => ({
        value: value.toString(),
        text: label,
      })),
    [availableTermOptions],
  );

  const singleTermPickerId = 'order-single-term-picker-select';

  return (
    <FormControl
      component="fieldset"
      error={!!error}
      fullWidth={true}
      size="small"
      sx={{ margin: '0.5rem; 0' }}
      variant="outlined"
    >
      {isSingleInstallmentTermSelection ? (
        <>
          <SelectInput
            id={singleTermPickerId}
            label={t('fields.orderProfilePicker.orderProfileTerms.label')}
            name={singleTermPickerId}
            onBlur={onBlur}
            onChange={handleOnSingleChange}
            selectItems={selectItems}
            value={singleSelectedTerm}
          />
        </>
      ) : (
        <Autocomplete
          className="HeyLight-multi-select"
          disableCloseOnSelect={true}
          filterSelectedOptions={true}
          forcePopupIcon={false}
          getOptionLabel={option => option.label}
          isOptionEqualToValue={(option, selected) =>
            option.value === selected.value
          }
          multiple={true}
          noOptionsText={t(
            'fields.orderProfilePicker.orderProfileTerms.noOptions',
          )}
          onChange={handleOnMultiChange}
          options={availableTermOptions}
          renderInput={params => (
            <TextField
              error={!!error}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputFieldAdornment
                    isComplete={selectedTermOptions?.length > 0}
                    isError={!!error}
                  />
                ),
              }}
              label={t('fields.orderProfilePicker.orderProfileTerms.label')}
              rows={2}
              sx={{
                '> .MuiInputBase-root.MuiInputBase-adornedEnd': { pr: '15px' },
              }}
            />
          )}
          value={selectedTermOptions}
        />
      )}
      <FormHelperText>{t(error)}</FormHelperText>
    </FormControl>
  );
};
