import { CSSProperties, ReactNode, lazy, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@heidi-pay/heidi-component-library/components';
import { Link as MuiLink, Box, FormHelperText, styled } from '@mui/material';
import { useTranslation } from '@hooks';
import { getTranslationConstants } from '../../constants';
import {
  components,
  emojiComponents,
  layoutComponents,
  typographyComponents,
  typographyComponentsHeyLight,
} from './components';
import * as typography from './components/Typography';

// Lazy importing this component because it's importing PdfJs, which is one of the largest node_modules package
const TermsDialog = lazy(() => import('./components/Dialog'));

interface IErrorProps {
  sizeLarge: boolean;
}

const StyledFormHelperText = styled(FormHelperText)<IErrorProps>(
  ({ sizeLarge }) => ({
    fontSize: sizeLarge ? '1rem' : '0.75rem',
  }),
);

interface ICopyBlock {
  children?: ReactNode;
  color?: CSSProperties['color'];
  component?: React.ElementType;
  i18nKey: string;
  m?: number;
  p?: number;
  props?: {
    [key: string]: unknown;
    onClick?: () => void;
    termsAndConditionsFile?: string;
  };
  sizeLarge?: boolean;
  sx?: React.ComponentProps<typeof Box>['sx'];
  value?: {
    [key: string]: unknown;
    loginUrl?: string;
  };
}

interface IBaseCopyBlock extends ICopyBlock {
  extraComponents?:
    | readonly React.ReactElement[]
    | { readonly [tagName: string]: React.ReactElement };
}

export const BaseCopyBlock = ({
  children = null,
  color = 'inherit',
  component = 'section',
  extraComponents,
  i18nKey,
  m = 0,
  p = 0,
  props = {},
  sizeLarge = false,
  sx,
  value = {},
}: IBaseCopyBlock) => {
  // Needed to trigger re-render on locale change
  const { t } = useTranslation();
  const { onClick, termsAndConditionsFile } = props;
  const defaultFontSize = sizeLarge ? '1rem' : '0.875rem';

  useEffect(() => {}, [t]);

  return (
    <Box
      color={color}
      component={component}
      data-translationKey={i18nKey}
      fontSize={defaultFontSize}
      m={m}
      p={p}
      sx={sx}
      width="100%"
    >
      <Trans
        components={{
          bold: <b />,
          br: typography.Br,
          dialog: (
            <TermsDialog termsAndConditionsFile={termsAndConditionsFile} />
          ),
          error: <StyledFormHelperText error={true} sizeLarge={sizeLarge} />,
          ...(value?.loginUrl
            ? {
                login: (
                  <MuiLink
                    component={RouterLink}
                    display="block"
                    to={value.loginUrl}
                  />
                ),
              }
            : {}),
          heyLightLink: <Link onClick={onClick} />,
          ...extraComponents,
        }}
        i18nKey={i18nKey}
        t={t}
        values={value}
      />
      {children}
    </Box>
  );
};

export const CopyBlock = (props: ICopyBlock) => {
  const translationConstants = getTranslationConstants();
  const propsWithTranslationConstants = {
    ...props,
    value: {
      ...(props.value ?? {}),
      ...translationConstants,
    },
  };

  return (
    <BaseCopyBlock
      {...propsWithTranslationConstants}
      extraComponents={{
        ...components,
        ...emojiComponents,
        ...layoutComponents,
        ...typographyComponents,
        ...typographyComponentsHeyLight,
      }}
    />
  );
};
