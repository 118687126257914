import { IComplianceContainerContent } from '../types';
import {
  personalDetailsComplianceContent as italyBnplPersonalDetails,
  selectScheduleComplianceContent,
  securityCodeVerificationItalyBnplCardComplianceContent,
  securityCodeVerificationItalyBnplDirectDebitComplianceContent,
} from './getItalyBnplDetails';
import { personalDetailsComplianceContent as italyCreditPersonalDetails } from './getItalyCreditDetails';

const elementMap: Record<string, IComplianceContainerContent> = {
  selectScheduleElements: selectScheduleComplianceContent,
  collectPersonalDetailsElements: italyBnplPersonalDetails,
  collectPersonalDetailsElementsItCredit: italyCreditPersonalDetails,
  securityCodeVerificationItalyBnplCard:
    securityCodeVerificationItalyBnplCardComplianceContent,
  securityCodeVerificationItalyBnplDirectDebit:
    securityCodeVerificationItalyBnplDirectDebitComplianceContent,
};

export const getComplianceDetails = (type: string) => elementMap[type];
