import { useCallback, useEffect, useState, useRef } from 'react';
import { IPollingResponse, PollingStatus } from '../transaction/services/types';

export interface IStatusPollingProps {
  enablePolling?: boolean;
  initialDelayMs?: number;
  isSubmitting?: boolean;
  onComplete?: () => void;
  pollingFunction: () => Promise<IPollingResponse>;
  retryIntervalMs?: number;
  submitAttempted?: boolean;
}

export interface IStatusPollingResponse {
  cancelPolling: () => void;
  isComplete: boolean;
}

export const useStatusPolling = ({
  enablePolling = true,
  initialDelayMs = 0,
  isSubmitting = false,
  onComplete,
  pollingFunction,
  retryIntervalMs = 5000,
  submitAttempted = false,
}: IStatusPollingProps): IStatusPollingResponse => {
  const [isComplete, setIsComplete] = useState(false);
  const intervalIdRef = useRef<number | null>(null);
  const isChecking = useRef(false);

  // isSubmitting will be set to false once the form is submitted which will cause the effect to execute
  // and set a new timeout. We want to have a debounce period before the timeout will submit the form again
  const submitInterval = submitAttempted ? 10000 : 0;

  // Clears the polling interval.
  const clearPollingInterval = () => {
    if (intervalIdRef.current) {
      window.clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const checkStatus = useCallback(async () => {
    if (isChecking.current) {
      return;
    }

    isChecking.current = true;
    try {
      const { status } = await pollingFunction();
      if (status === PollingStatus.Complete) {
        setIsComplete(true);
        clearPollingInterval();
      }
    } catch {
      // polling will be retried, do nothing
    } finally {
      isChecking.current = false;
    }
  }, [pollingFunction]);

  // Initiates polling after an initial delay if present.
  useEffect(() => {
    // Don't start the interval polling if there's an ongoing request
    if (!enablePolling || isSubmitting) {
      return;
    }
    const startPolling = () => {
      checkStatus();
      intervalIdRef.current = window.setInterval(() => {
        checkStatus();
      }, retryIntervalMs);
    };

    const timeoutId = window.setTimeout(startPolling, initialDelayMs);

    // eslint-disable-next-line consistent-return
    return () => {
      window.clearTimeout(timeoutId);
      clearPollingInterval();
    };
  }, [
    checkStatus,
    initialDelayMs,
    enablePolling,
    isSubmitting,
    retryIntervalMs,
  ]);

  // Handles the action after polling is complete.
  useEffect(() => {
    if (isComplete && !isSubmitting && onComplete) {
      const timeout = window.setTimeout(onComplete, submitInterval);
      return () => {
        window.clearTimeout(timeout);
      };
    }
    return () => undefined;
  }, [isComplete, onComplete, isSubmitting, retryIntervalMs, submitInterval]);

  return {
    cancelPolling: clearPollingInterval,
    isComplete,
  };
};
