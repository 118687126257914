import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ComponentTypes,
  registerSubFormValidation,
  toCamelCased,
} from '../../../../utils';
import { ControlledField } from './components/ControlledField';
import {
  additionalUnderwritingFieldNames,
  employmentStatusDisplayRules,
  notApplicableFieldValue,
} from './utils/constants';

import { buildSchema } from './utils/inputFieldSchema';

export const AdditionalUnderwritingContainer = ({
  onChange,
  options,
  submitAttempted,
  value,
}) => {
  const { formFields } = options;

  // The python BE service only parses property names to camel cased, not values
  const parsedFormFields = formFields.map(field => ({
    ...field,
    name: toCamelCased(field.name),
  }));

  const fieldNamesToValidate = Object.keys(value)
    .filter(key => {
      const formField = formFields.find(
        field => toCamelCased(field.name) === key,
      );
      return formField && formField.type !== ComponentTypes.TextBlock;
    })
    .map(key => ({ name: key }));

  const validationSchema = yup
    .object()
    .shape(buildSchema(fieldNamesToValidate, parsedFormFields));

  const { control, errors, getValues, setValue, trigger, watch } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: value,
  });

  useEffect(() => {
    // Return the value as form functions - for use in the validator
    onChange({
      getValues,
      hasErrors: () => Object.values(errors).length !== 0,
      isSubmitAttempted: () => submitAttempted,
      trigger,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues, trigger, errors]);

  const toggleDefaultValueForField = useCallback(
    (fieldName, employmentStatusValue) => {
      const fieldRules = employmentStatusDisplayRules[fieldName];

      if (fieldRules.includes(employmentStatusValue)) {
        setValue(fieldName, notApplicableFieldValue);
      } else {
        setValue(fieldName, '');
      }
    },
    [setValue],
  );

  const employmentStatusValue = watch(
    additionalUnderwritingFieldNames.employmentStatus,
  );
  useEffect(() => {
    toggleDefaultValueForField(
      additionalUnderwritingFieldNames.tenureCurrentEmployment,
      employmentStatusValue,
    );
    toggleDefaultValueForField(
      additionalUnderwritingFieldNames.netMonthlyIncome,
      employmentStatusValue,
    );
  }, [employmentStatusValue, toggleDefaultValueForField]);
  return (
    <>
      {parsedFormFields.map(field => (
        <ControlledField
          control={control}
          errors={errors}
          id={field.name}
          key={field.name}
          name={field.name}
          options={field.options}
          submitAttempted={submitAttempted}
          type={field.type}
        />
      ))}
    </>
  );
};

AdditionalUnderwritingContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    displayRules: PropTypes.shape(),
    formFields: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  submitAttempted: PropTypes.bool.isRequired,
  value: PropTypes.shape().isRequired,
};

AdditionalUnderwritingContainer.validation = registerSubFormValidation(
  'additionalUnderwritingContainer',
);
