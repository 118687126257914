/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';

const useWaitingBot = (introPhrases, repeatingPhrases) => {
  const loopIntervalSeconds = 60;

  const [displayPhrase, setDisplayPhrase] = useState(introPhrases[0]);
  const [phraseCounter, setPhraseCounter] = useState(0);
  const [progressBarCountdown, setProgressBarCountdown] = useState(0);
  const [isIntroCompleted, setIsIntroCompleted] = useState(false);

  const progressBarCountdownIncrement = useMemo(
    () => (100 * 100) / (loopIntervalSeconds * 1000),
    [],
  );

  const getPhrasesArray = () =>
    isIntroCompleted ? repeatingPhrases : introPhrases;

  const getSecondsFromCountdown = () =>
    progressBarCountdown / progressBarCountdownIncrement / 10;

  const checkIsIntroCompleted = () => {
    if (!isIntroCompleted && phraseCounter > introPhrases.length) {
      setIsIntroCompleted(true);
    }
  };

  // we need to reset the width of the typewriter container for the animation
  // to restart so we set the phrase to emtpy string and then react on the change
  useEffect(() => {
    if (!displayPhrase) {
      const phrasesArray = getPhrasesArray();
      if (phraseCounter < phrasesArray.length) {
        setDisplayPhrase(phrasesArray[phraseCounter - 1]);
      }
    }
  }, [displayPhrase, phraseCounter]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgressBarCountdown(prevCountdown => {
        const newCountdown = prevCountdown + progressBarCountdownIncrement;
        if (newCountdown >= 100) {
          setPhraseCounter(0);
          return 0;
        }

        return newCountdown;
      });

      if (getSecondsFromCountdown() >= phraseCounter * 6) {
        setPhraseCounter(prev => prev + 1);
        checkIsIntroCompleted();
        setDisplayPhrase('');
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [
    setPhraseCounter,
    setProgressBarCountdown,
    phraseCounter,
    introPhrases,
    progressBarCountdown,
  ]);

  return { displayPhrase, progressBarCountdown };
};

export default useWaitingBot;
