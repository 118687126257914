import { RowType } from '../../PaymentPlanTable/utils';

export const getPaymentPlanFields = ({
  formatters,
  monthlyPayments,
  paymentPlan = {},
  paymentSummary,
  translate,
}) => {
  const { initialPayment, schedule, total } = paymentSummary ?? {};
  const { summary = {} } = paymentPlan;
  const { initialBalance, interest = {} } = summary;

  if (!total) {
    return null;
  }

  const { currency } = total;
  const monthlyPayment = schedule[0].amount;
  const scheduleLength = schedule.length;
  const residualValue = schedule[scheduleLength - 1];
  const amountWithoutResidualPayment =
    parseFloat(total.amount) - parseFloat(residualValue.amount);

  const { apr, interestAmount } = interest;
  const { formatAmountWithCurrency, formatDecimalToPercentage } = formatters;

  const baseKey = 'fields.paymentPlanMobilezoneInstore';

  const payFields = [
    {
      label: translate(`${baseKey}.payNow`),
      value: formatAmountWithCurrency(initialPayment, currency),
    },
    {
      label: translate(`${baseKey}.payMonthly`),
      value: formatAmountWithCurrency(monthlyPayment, currency),
    },
  ];

  const otherFields =
    apr && parseFloat(apr) !== 0
      ? [
          {
            label: translate(`${baseKey}.creditDuration`),
            value:
              monthlyPayments > 1
                ? translate(`${baseKey}.creditDurationMonths`, {
                    creditDuration: monthlyPayments,
                  })
                : translate(`${baseKey}.creditDurationMonth`, {
                    creditDuration: monthlyPayments,
                  }),
          },
          {
            label: translate(`${baseKey}.annualInterestRate`),
            value: formatDecimalToPercentage(apr),
          },
          {
            label: translate(`${baseKey}.totalInterest`),
            value: formatAmountWithCurrency(interestAmount, currency),
          },
          {
            label: translate(`${baseKey}.totalPricePlusInterest`),
            value: formatAmountWithCurrency(initialBalance, currency),
          },
        ]
      : [
          {
            label: 'payment-plan-divider-1',
            rowType: RowType.divider,
          },
          {
            displayLabelBold: true,
            displayValueBold: true,
            label: translate(`${baseKey}.totalAmountWithoutResidualPayment`),
            value: formatAmountWithCurrency(
              amountWithoutResidualPayment,
              currency,
            ),
          },
          {
            label: 'payment-plan-divider-2',
            rowType: RowType.divider,
          },
          {
            label: translate(`${baseKey}.residualAmount`, {
              term: scheduleLength + 1,
            }),
            value: formatAmountWithCurrency(residualValue.amount, currency),
          },
          {
            label: 'payment-plan-divider-3',
            rowType: RowType.divider,
          },
          {
            displayLabelBold: true,
            displayValueBold: true,
            label: translate(`${baseKey}.totalAmount`),
            value: formatAmountWithCurrency(total.amount, currency),
          },
          {
            label: 'payment-plan-divider-4',
            rowType: RowType.divider,
          },
        ];

  return [...payFields, ...otherFields];
};
