import { ReactNode } from 'react';
import { Translate } from '@heidi-pay/heidi-common-fe/types';
import {
  Carousel,
  ContentHeightContainer,
  FillHeightContainer,
  getTitleComponent,
} from '@heidi-pay/heidi-component-library/components';
import { Box, useTheme } from '@mui/material';
import { IValidationError } from '@transaction/components/types';
import { IComplianceDialogContent, IComplianceElement } from '../types';
import { ComplianceElementComponent } from './ComplianceElementComponent';

interface ICarouselItem {
  complianceElements: IComplianceElement[];
  signatureElement?: IComplianceElement;
}

export interface ICarouselItemProps extends ICarouselItem {
  onSignatureChange: (consentId: string, value: boolean) => void;
  title?: string | ReactNode;
  validationErrorKey?: string;
  value?: string;
}

export const CarouselItem = ({
  complianceElements,
  onSignatureChange,
  signatureElement,
  title,
  validationErrorKey,
  value,
}: ICarouselItemProps) => {
  const theme = useTheme();
  const titleElement = getTitleComponent(title, theme);

  return (
    <>
      {titleElement ? (
        <Box margin={theme.dialog.title.margin}>{titleElement}</Box>
      ) : null}
      <FillHeightContainer>
        {complianceElements.map(element => (
          <ComplianceElementComponent
            element={element}
            elementValue={value}
            key={element.id ?? element.translationKey}
            onConsentBlockClick={onSignatureChange}
          />
        ))}
      </FillHeightContainer>
      {signatureElement ? (
        <ContentHeightContainer pt="20px">
          <ComplianceElementComponent
            element={signatureElement}
            elementValidationErrorKey={validationErrorKey}
            elementValue={value}
            key={signatureElement.translationKey}
            onConsentBlockClick={onSignatureChange}
          />
        </ContentHeightContainer>
      ) : null}
    </>
  );
};

export interface IComplianceContentCarouselProps {
  carouselItems: IComplianceDialogContent[];
  currentIndex: number;
  enableSwipeInteraction?: boolean;
  onNavigationChange: (index: number) => void;
  onSignatureChange: (consentId: string, value: boolean) => void;
  t: Translate;
  validationErrors?: IValidationError[];
  values?: (string | undefined)[];
}

export const ComplianceContentCarousel = ({
  carouselItems,
  currentIndex,
  enableSwipeInteraction = false,
  onNavigationChange,
  onSignatureChange,
  t,
  validationErrors,
  values,
}: IComplianceContentCarouselProps) => {
  const carouselItemComponents = carouselItems.map((item, index) => (
    <CarouselItem
      complianceElements={item.complianceElements}
      key={`${item.complianceElements[0].translationKey}`}
      onSignatureChange={onSignatureChange}
      signatureElement={item.signatureElement}
      title={item.title ? t(item.title.translationKey) : null}
      validationErrorKey={validationErrors?.[index]?.message}
      value={values?.[index]}
    />
  ));

  return (
    <Carousel
      controlledIndex={currentIndex}
      enableSwipeInteraction={enableSwipeInteraction}
      items={carouselItemComponents}
      onChange={onNavigationChange}
    />
  );
};
