import { Divider, TableRow } from '@mui/material';
import { Align, Nullable, ValueOf } from '@appTypes';
import { useTranslation } from '@hooks';
import { Cell } from '../../Styled';
import { RowType } from '../utils';
import { PaymentRowLabel } from './PaymentRowLabel';
import { PaymentRowValue } from './PaymentRowValue';

interface IPaymentRowProps {
  alignAmount?: Align;
  currency?: Nullable<string>;
  displayLabelBold?: boolean;
  displayValueBold?: boolean;
  header?: boolean;
  hidden?: boolean;
  isExpanded?: boolean;
  isLabelOnly?: boolean;
  isSmallFont?: boolean;
  label: string;
  rowType?: ValueOf<typeof RowType>;
  toggleExpanded?: () => void;
  tooltip?: string;
  value?: Nullable<string>;
}

export const PaymentRow = ({
  alignAmount = 'right',
  currency,
  displayLabelBold = false,
  displayValueBold = false,
  header = false,
  hidden = false,
  isExpanded = false,
  isLabelOnly = false,
  isSmallFont = false,
  label,
  rowType = RowType.amount,
  toggleExpanded,
  tooltip,
  value,
}: IPaymentRowProps) => {
  const { t } = useTranslation();
  const component = header ? 'th' : 'td';
  const displayLabelOnly = !value || isLabelOnly;
  const isScheduleToggle = rowType === RowType.scheduleToggle;

  if (hidden) {
    return null;
  }

  if (rowType === RowType.divider) {
    return (
      <TableRow>
        <Cell colSpan={3}>
          <Divider />
        </Cell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <PaymentRowLabel
        component={component}
        displayLabelBold={displayLabelBold}
        displayLabelOnly={displayLabelOnly}
        isExpanded={isExpanded}
        isScheduleToggle={isScheduleToggle}
        isSmallFont={isSmallFont}
        label={label}
        t={t}
        toggleExpanded={toggleExpanded}
        tooltip={tooltip}
      />
      {displayLabelOnly ? null : (
        <PaymentRowValue
          alignAmount={alignAmount}
          component={component}
          currency={currency}
          displayValueBold={displayValueBold}
          isSmallFont={isSmallFont}
          value={value}
        />
      )}
    </TableRow>
  );
};
