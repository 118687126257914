import PropTypes from 'prop-types';
import { CopyBlock } from '../../components';

// Default translation interpolation values
const defaultValue = {
  cardPaymentProviders: 'Mastercard, Visa, American Express',
};

export default function TextBlock({ name, options, value }) {
  const { largeText, overrideKey, textAlign } = options;

  const overrideSection = overrideKey ? `.${overrideKey}` : '';
  const sx = {
    ...(textAlign ? { textAlign } : {}),
  };

  const valueOrDefault =
    value?.constructor === Object && Object.keys(value).length === 0
      ? defaultValue
      : value;

  return (
    <CopyBlock
      i18nKey={`fields.textBlock${overrideSection}.${name}.content`}
      sizeLarge={largeText}
      sx={sx}
      value={valueOrDefault}
    />
  );
}

TextBlock.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.shape(),
  options: PropTypes.shape(),
};

TextBlock.defaultProps = {
  value: {},
  options: {},
};

TextBlock.formatting = () => undefined;
