import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setMonthlyPayments,
  updatePaymentPlan,
  getMonthlyPayments,
} from '../../payment/redux';

const HiddenMonthlyPaymentsField = ({ options, value }) => {
  const {
    summary: { paymentPlans },
  } = options;
  const monthlyPayments = useSelector(getMonthlyPayments);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setMonthlyPayments(value));
  }, [dispatch, value]);

  const getPaymentPlanForMonthlyPayments = useCallback(
    monthlyPaymentsValue => paymentPlans[monthlyPaymentsValue],
    [paymentPlans],
  );

  const setPaymentPlanForMonthlyPayments = useCallback(
    monthlyPaymentsValue => {
      const paymentPlan =
        getPaymentPlanForMonthlyPayments(monthlyPaymentsValue);
      dispatch(updatePaymentPlan(paymentPlan));
    },
    [dispatch, getPaymentPlanForMonthlyPayments],
  );

  useEffect(() => {
    if (monthlyPayments > 0) {
      setPaymentPlanForMonthlyPayments(monthlyPayments);
    }
  }, [setPaymentPlanForMonthlyPayments, monthlyPayments]);

  return <></>;
};

HiddenMonthlyPaymentsField.propTypes = {
  options: PropTypes.shape({
    summary: PropTypes.shape({
      currency: PropTypes.string,
      paymentPlans: PropTypes.shape(),
    }),
  }).isRequired,
  value: PropTypes.number.isRequired,
};

export default HiddenMonthlyPaymentsField;
