import PropTypes from 'prop-types';
import sanitize from 'sanitize-html';
import { styled, useTheme } from '@mui/material/styles';

const Root = styled('div')(({ color }) => ({
  a: {
    color,
  },
}));

const HtmlContainer = ({ allowedHtmlTags, content, options }) => {
  // the list of allowed tags is limited to typography and layout tags to
  // reduce the impact of a XSS attack
  const theme = useTheme();
  const allowedTags = allowedHtmlTags || [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'ul',
    'ol',
    'li',
    'dd',
    'dl',
    'dt',
    'b',
    'i',
    'br',
    'span',
  ];
  const allowedAttributes = { '*': ['data-testid', 'style'], a: [] };
  const allowedStyles = false;
  const allowedSchemes = ['https'];

  if (options.allowLinks) {
    allowedTags.push('a');
    allowedAttributes.a = ['href', 'target'];
  }

  // sanitize will parse the input html string and strip out any
  // tags which are not part of the allowd tags array reducing
  const sanitizedHtml = sanitize(content, {
    allowedTags,
    allowedAttributes,
    allowedSchemes,
    allowedStyles,
  });
  return (
    <Root
      color={theme.palette.primary.main}
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
    />
  );
};

HtmlContainer.propTypes = {
  content: PropTypes.string,
  allowedHtmlTags: PropTypes.shape(),
  options: PropTypes.shape({
    allowLinks: PropTypes.bool,
  }),
};

HtmlContainer.defaultProps = {
  content: 'content',
  allowedHtmlTags: null,
  options: {
    allowLinks: false,
  },
};

export default HtmlContainer;
