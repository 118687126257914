export const notApplicableFieldValue = 'NOT_APPLICABLE';

export const additionalUnderwritingFieldNames = Object.freeze({
  employmentStatus: 'employmentStatusUnderwriting',
  netMonthlyIncome: 'netMonthlyIncome',
  tenureCurrentEmployment: 'tenureCurrentEmployment',
});

export const employmentStatusTypes = Object.freeze({
  seasonalWorker: 'SEASONAL_WORKER',
  retired: 'RETIRED',
  student: 'STUDENT',
});

export const employmentStatusDisplayRules = Object.freeze({
  netMonthlyIncome: [employmentStatusTypes.student],
  tenureCurrentEmployment: [
    employmentStatusTypes.seasonalWorker,
    employmentStatusTypes.student,
    employmentStatusTypes.retired,
  ],
});
